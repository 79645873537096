.product-params {
  display: flex;
  flex-direction: column;
  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.product-params__col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-params__col:first-child {
  margin-right: 0;
  @include respond-min(tablet) {
    margin-right: 2rem;
  }
}
.product-params__col:last-child {
  margin-left: 0;
  @include respond-min(tablet) {
    margin-left: 2rem;
  }
}

.product-params__item {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px #000 solid;
  padding: 1.2rem 0;
  @include respond-min(tablet) {
    padding: .4rem 0;
  }
}

.product-params__name {
  display: flex;
  align-items: center;
  @include respond-min(tablet) {
    .btn__circle:after {
      border-width: 1px;
    }
  }
}

.product-params__text,
.product-params__value {
  font-size: 1.7rem;
  line-height: 1.4;
  letter-spacing: 0.12em;
  @include respond-min(tablet) {
    font-size: 1rem;
  }
}
.product-params__text {
  margin-right: 2.8rem;
  width: min-content;
  @include respond-min(tablet) {
    margin-right: 1rem;
    width: auto;
  }
}

.product-params__value {
  text-align: right;
}

.product-params__tooltip {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
}