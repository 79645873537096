.card-person {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 3rem;
  width: 25rem;
  @include respond-min(tablet) {
    width: auto;
    padding: 3.125rem;
    flex-direction: row;
    align-items: center;
  }

  .card-person__image {
    overflow: hidden;
    position: relative;
    width: 19rem;
    height: 19rem;
    margin-right: 0;
    margin-bottom: 3rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18rem;
      height: 18rem;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0 0 0 50vw $white;
      transition: $trs;
      pointer-events: none;
    }

    &:hover {
      &::before {
        width: 56rem;
        height: 56rem;
      }
    }

    @include respond-min(tablet) {
      width: 100%;
      height: 100%;
      margin-right: 3.125rem;
      max-width: 12.5rem;
      margin-bottom: 0;
      &::before {
        width: 12.5rem;
        height: 12.5rem;
      }
      &:hover {
        &::before {
          width: 20rem;
          height: 20rem;
        }
      }
    }

    img {
      object-fit: contain;
    }

    @include respond-min(desktop-xlg) {
      &:hover {
        &::before {
          width: 33vw;
          height: 33vw;
        }
      }

      &:hover {
        &::before {
          width: 20rem;
          height: 20rem;
        }
      }
    }
  }

  .card-person__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 20rem;
    @include respond-min(tablet) {
      min-height: 12.5rem;
    }
  }

  .card-person__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    border-top: 2px solid $black;
    border-bottom: 1px solid $black;

    @include respond-min(tablet) {
      padding-bottom: 1.875rem;
    }

  }

  .card-person__title {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 2rem;
    @include respond-min(tablet) {
      font-size: .95625rem;
      line-height: 1.19;
      letter-spacing: .017em;
      margin-bottom: 0.85rem;
      min-height: 2.975rem;
    }
  }

  .card-person__text {
    font-size: 1.625rem;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include text('t-small');
    @include respond-min(tablet) {
      font-size: 0.875rem;
      line-height: 1.4;
    }
  }
}