.green-menu {
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 5%;

  li, a {
    height: 100%;
  }
}

.green-menu .anchor-nav__dropdown {
  max-width: 100%;
}

@media(max-width: 1440px) {
  .green-menu li a {
    padding: 0.5em 1rem;
  }
  .anchor-nav__dropdown .anchor-nav__dropdown-item a {
    display: block;
    padding: 0.5rem;
    font-size: .6em;
  }
}

@media(max-width: 1025px) {
  .green-menu li a {
    padding: 0.5rem;
  }
}