.u-flex {
  display: flex;
}

.u-flex-col {
  display: flex;
  flex-direction: column;
}

.u-ais {
  align-items: flex-start;
}

.u-aie {
  align-items: flex-end;
}

.u-aic {
  align-items: center;
}

.u-jcsb {
  justify-content: space-between;
}

.u-jce {
  justify-content: flex-end;
}

.u-fxw {
  flex-wrap: wrap;
}

.u-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 1024px) {
  .u-center {
    justify-content: space-between; } }
