.principles {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 7.5rem;

  @include respond-min(tablet) {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}

.principles__title {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-left: 18rem;
    margin-bottom: 2.5rem;
  }
}

.principles__content {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
    margin-left: 18rem;
  }
}

.principles__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.625rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-shrink: 0;
    width: 50%;
    margin-bottom: 0;
  }
}

.principles__item {
  position: relative;
  margin-bottom: 2.25rem;
  padding-left: 3.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0.625rem;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    background-color: $red;
    border-radius: 50%;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &::before {
      top: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}
