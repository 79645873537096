@mixin text($type: 'h4') {
  @if $type == 'h1' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4.5rem;
    line-height: 1.17;
    letter-spacing: 0;

    @include respond-min(tablet) {
      font-size: 2.62937rem;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 'h2' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 3rem;
    line-height: 1.4;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    @include respond-min(tablet) {
      font-size: 2.1862rem;
      line-height: 1.19;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 'h3' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.75rem;
    line-height: 1.2;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 1.434375rem;
      line-height: 1.19;
      letter-spacing: 0.034em;
    }
  }

  @if $type == 'h4' {
    @include font('m');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.125rem;
    line-height: 1.2;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 0.95625rem;
      line-height: 1.19;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 't-small' {
    font-size: 1.25rem;
    line-height: 1.465;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 0.669375rem;
      line-height: 1.24525;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 't-little' {
    font-size: 1.5rem;
    line-height: 1.4;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 0.57375rem;
      line-height: 1.19;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 't-normal' {
    font-size: 1.625rem;
    line-height: 1.4;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 0.860625rem;
      line-height: 1.19;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 't-middle' {
    font-size: 1.75rem;
    line-height: 1.4;

    @include respond-min(tablet) {
      font-size: 0.85rem;
      line-height: 1.19;
    }
  }

  @if $type == 't-large' {
    font-size: 1.075rem;
    line-height: 1.4;
    letter-spacing: 0.18rem;

    @include respond-min(tablet) {
      font-size: 0.860625rem;
      line-height: 1.19;
      letter-spacing: 0.08rem;
    }
  }

  @if $type == 't-big' {
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: 0.02em;

    @include respond-min(tablet) {
      font-size: 1.051875rem;
      line-height: 1.36;
      letter-spacing: 0.017em;
    }
  }

  @if $type == 't-l-title' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-min(tablet) {
      font-size: 0.6215625rem;
      line-height: 1.02;
      letter-spacing: 0.085em;
    }
  }

  @if $type == 't-m-title' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.175rem;
    line-height: 1.17;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-min(tablet) {
      font-size: 0.87375rem;
      letter-spacing: 0.068em;
      line-height: 0.9945;
    }
  }

  @if $type == 't-l-caption' {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.375rem;
    line-height: 1.17;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-min(tablet) {
      font-size: 0.57375rem;
      letter-spacing: 0.068em;
      line-height: 0.9945;
    }
  }
}
