.input {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1px;

  .error-template {
    display: none;
  }

  &::after {
    content: attr(data-invalid);
    position: absolute;
    top: calc(100% + 0.625rem);
    left: 2.5rem;
    font-size: 1.4rem;
    line-height: 1.465;
    color: $red;
    opacity: 0;
    transition: $trs;
    pointer-events: none;
  }

  &--bl {
    border-bottom: 1px solid $d-grey;
    box-shadow: none!important;
    border-radius: 0!important;
    display: flex;
    flex-direction: row;
    .media-obj {
      width: 3.6rem;
      height: 3.6rem;
      margin: auto 0rem auto 1rem;
    }
  }

  &:not(.input--checkbox) {
    border-radius: 2.75rem;
    box-shadow: 0 0 0 1px $grey;

  }



  &.invalid {

    &:not(.input--checkbox) {
      box-shadow: inset 0 0 0 1px $red;
    }

    &::after {
      opacity: 1;
      pointer-events: all;
    }
  }



  @include respond-min(tablet) {
    &:not(.input--checkbox) {
      border-radius: 1.875rem;

    }
    &::after {
      top: calc(100% + 0.3125rem);
      font-size: 0.85rem;
    }
  }
  .suggestions {

    &-hint {
      order: 1;

      span {
        color: $red;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    &-suggestion {
      display: flex;
      flex-direction: column;
    }
  }
}

input[name="n-message"] {
  display: block;
  width: 5px;
  height: 3px;
  margin-bottom: -3px;
  opacity: 0.01;
}

.input__input {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 0.875rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: 0;
  z-index: 0;
  background-color: transparent;

  &:not([type='checkbox']) {
    border-radius: 2.75rem;
  }

  //&.error[value=""]:not(:focus) {
  //  color: white;
  //}

  &--not-label {
    padding: 1.75rem 2.4rem;
    font-size: 1.1rem;
  }

  .input &--not-label {
    box-shadow: 0 0 0 1px $d-grey;
  }

  .input--bl & {
    padding: 1.75rem 2.4rem;
    font-size: 1.8rem;
  }

  &--textarea {
    min-height: 72px;
    padding-bottom: 2.5rem;
    resize: none;
    overflow: hidden!important;
    overflow-wrap: break-word;
  }

  &:invalid {
    outline: none;
    box-shadow: none;
  }

  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: currentColor !important; /* stylelint-disable-line declaration-no-important */
    color: currentColor !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: inset 0 0 0 4rem #ffffff !important; /* stylelint-disable-line declaration-no-important */
  }

  @include respond-min(tablet) {
    padding-top: 1.75rem;
    padding-bottom: 0.5rem;

    &:not([type='checkbox']) {
      border-radius: 1.875rem;
    }

    &--not-label {
      padding: .6rem 1.5rem;
      height: 3.1rem;
      font-size: 0.9rem;
      min-width: 16rem;
    }

    .input &--not-label {
      box-shadow: 0 0 0 1px $d-grey;
    }



    &--textarea {
      min-height: 72px;
    }

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 2.75rem #ffffff !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.input__label {
  user-select: none;

  .input:not(.input--checkbox) & {
    position: absolute;
    top: 1.75rem;
    left: 2.5rem;
    //right: 2.5rem;
    background-color: #ffffff;
    transition: 0.1s linear;
    cursor: text;
  }



  .input--checkbox & {
    position: relative;
    display: block;
    padding-left: 4.375rem;
    cursor: pointer;
    line-height: 1.85;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: $trs;
    }

    &::before {
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid $grey;
    }

    &::after {
      left: 0.75rem;
      width: 1rem;
      height: 1rem;
      background-color: $red;
      opacity: 0;
    }
  }

  .no-before.input--checkbox & {
    padding-left: 0;
    &::before {
      display: none;
    }
  }

  .input__input--checkbox:checked + & {
    &::after {
      opacity: 1;
    }
  }

  .input.active:not(.input--checkbox) &, .input.invalid:not(.input--checkbox) & {
    top: 1px;
    padding-top: 0.625rem;
    font-size: 1.25rem;
  }
  &--always-top  {
    top: 1px !important;
    padding-top: 0.625rem;
    font-size: 1.25rem;
  }

  @include respond-min(tablet) {
    .input:not(.input--checkbox) & {
      top: 1.25rem;
    }
    .input--checkbox & {
      padding-left: 2.5rem;

      &::before,
      &::after {
        top: 0;
        transform: translateY(0);
      }

      &::before {
        width: 1.25rem;
        height: 1.25rem;
      }

      &::after {
        top: 0.375rem;
        left: 0.375rem;
        width: 0.5rem;
        height: 0.5rem;
      }
    }

    .input.active:not(.input--checkbox) &, .input.invalid:not(.input--checkbox) & {
      top: 1px;
      font-size: 0.75rem;
      background: transparent;
    }
    &--always-top {
      font-size: 0.75rem;
      background: transparent;
    }
  }
}


.input-b {
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(.input-b--checkbox) {
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    &::after {
      content: attr(data-invalid);
      position: absolute;
      top: calc(100% + 0.625rem);
      left: 0;
      font-size: 1.5rem;
      line-height: 1.465;
      color: $red;
      opacity: 0;
      transition: $trs;
      pointer-events: none;
    }
  }

  &.invalid {
    border-color: $red;
    box-shadow: none;

    &:not(.input--checkbox) {
      box-shadow: none;

    }

    &::after {
      opacity: 1;
      pointer-events: all;
    }
  }

  @include respond-min(tablet) {
    &:not(.input-b--checkbox) {
      box-shadow: none;
      border-radius: 0;

      &::after {
        top: calc(100% + 0.3125rem);
        font-size: 0.75rem;
      }
    }
  }
}

.input-b__input {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0;
  padding-right: 2.5rem;
  border: 0;
  background-color: transparent;

  &:not([type='checkbox']) {
  }

  &--textarea {
    min-height: 19rem;
    padding-bottom: 2.5rem;
    resize: none;
  }

  &:invalid {
    outline: none;
    box-shadow: none;
  }

  &::-ms-clear {
    display: none;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: currentColor !important; /* stylelint-disable-line declaration-no-important */
    color: currentColor !important; /* stylelint-disable-line declaration-no-important */
    box-shadow: inset 0 0 0 4rem #ffffff !important; /* stylelint-disable-line declaration-no-important */
  }

  @include respond-min(tablet) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    &:not([type='checkbox']) {
    }

    &--textarea {
      min-height: 11.375rem;
    }

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 2.75rem #ffffff !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.input-b__label {
  user-select: none;

  .input-b:not(.input--checkbox) & {
    position: absolute;
    top: 1.75rem;
    left: 0;
    right: 2.5rem;

    font-family: 'Roboto Regular', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.75rem;
    line-height: 100%;
    color: #000000;
    opacity: 0;

    background-color: #ffffff;
    transition: 0.1s linear;
    cursor: text;
  }

  .input-b--checkbox & {
    position: relative;
    display: block;
    padding-left: 4.375rem;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      transition: $trs;
    }

    &::before {
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid $grey;
    }

    &::after {
      left: 0.75rem;
      width: 1rem;
      height: 1rem;
      background-color: $red;
      opacity: 0;
    }
  }

  .input-b__input--checkbox:checked + & {
    &::after {
      opacity: 1;
    }
  }

  .input-b.active & {
    top: 1px;
    font-size: 1.25rem;
  }

  @include respond-min(tablet) {
    .input-b:not(.input--checkbox) & {
      top: 1.25rem;
      font-size: 1rem;
    }

    .input-b--checkbox & {
      padding-left: 2.5rem;

      &::before,
      &::after {
        top: 0;
        transform: translateY(0);
      }

      &::before {
        width: 1.25rem;
        height: 1.25rem;
      }

      &::after {
        top: 0.375rem;
        left: 0.375rem;
        width: 0.5rem;
        height: 0.5rem;
      }
    }

    .input-b.active & {
      top: -3px;
      font-size: 0.75rem;
    }
  }
}
