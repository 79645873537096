.infoblock-c {
  display: flex;
  align-items: center;
  padding: 2.2rem 2.4rem;

  @include font('r');
  line-height: 150%;
  font-size: 2rem;
  letter-spacing: 0.57px;
  color: #000000;

  @include respond-min(tablet-sm) {
    height: 100px;
  }
  @include respond-min(tablet) {
    height: 100px;
    padding: 1rem 2.2rem;

    font-size: 1.38rem;
    line-height: 119.5%;
  }
}

.infoblock-c__svg {
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  margin-right: 2.5rem;

  @include respond-min(tablet) {
    margin-right: 1.6rem;
    width: 39px;
    height: 39px;
  }
}
