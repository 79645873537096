.investments {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  @include respond-min(tablet) {
    padding-bottom: 6.375rem;
  }
}

.investments__header {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 4.25rem;
    margin-left: 18rem;
  }
}

.investments__title {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
  }
}

.investments__text {
  @include respond-min(tablet) {
    margin-right: 20rem;
  }
}

.investments__slider {
  overflow: visible;

  @include respond-min(tablet) {
    .page--main & {
      align-self: center;
      width: auto;
    }
  }
}

.investments__slider-wrapper {
  @include respond-min(tablet) {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 5px;
  }
}

.investments__slider-wrapper--2col {
  @include respond-min(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.investments__slide {
  margin-right: $offset-mobile;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  text-decoration: none;
  color: #000;

  //&:last-of-type {
  //  margin-right: 0;
  //}
  &:hover {
    transform: translateY(-5px);
  }
  @include respond-min(tablet) {
    width: auto;
    // max-width: 25rem;
    margin-right: 0;
    padding-top: 3.4rem;
    padding-bottom: 1.7rem;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }
  &--add-rows {
    flex-direction: row-reverse !important;
    flex-wrap: wrap;

    .investments__image {
      flex: 0 0 60px;
      max-width: 60px;
      align-self: flex-start;
      margin-bottom: 3rem;
    }
    .investments__slide-title {
      flex: 0 0 calc(100% - 60px);
      margin: 0;
      min-height: auto;
    }
    .investments__slide-text {
      min-height: 60%;
    }
  }
  .isin-copy {
    text-decoration: underline dotted;
    font-weight: 600;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
      cursor: copy;
    }
  }
}

.investments__image {
  align-self: center;
  width: auto;
  height: auto;
  margin-bottom: 5.625rem;

  svg {
    width: 18.75rem;
    height: 9.75rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 3.4rem;

    svg {
      width: 13.125rem;
      height: 6.875rem;
    }
  }
}

.investments__slide-title {
  @include font('m');
  min-height: 10.5rem;
  margin-bottom: 4rem;
  font-size: 2.25rem;
  line-height: 1.2;
  letter-spacing: 0.02em;

  @include respond-min(tablet) {
    min-height: 4.78125rem;
    margin-bottom: 0.95625rem;
    font-size: 1.0511875rem;
    line-height: 1.19;
  }
}

.investments__slide-text {
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  line-height: 1.465;
  letter-spacing: 0.02em;

  @include respond-min(tablet) {
    margin-bottom: 2rem;
    font-size: 0.875rem;
    letter-spacing: 0.02em;
  }
}

.investments__btn {
  margin-top: auto;
}
