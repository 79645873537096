.contacts {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    //margin-bottom: 5rem;
    margin-bottom: 0;
  }
}

.contacts__map {
  @include bg-responsive('contacts-map-desktop', 'contacts-map-mobile', 1024px);
  width: 100%;
  height: 40rem;
  margin-bottom: 7rem;
  background-size: cover;

  @include respond-min(tablet) {
    height: 31.25rem;
    margin-bottom: 5rem;
  }
}

.contacts__block {
  display: flex;
  flex-direction: column;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  border-top: 1px solid $black;

  @include respond-min(tablet) {
    flex-direction: row;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

.contacts__header {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    width: 18rem;
    margin-bottom: 0;
  }
}

.contacts__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.contacts__address {
  margin-bottom: 1.25rem;

  &--2 {
    margin-bottom: 1.875rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 0.625rem;

    &--2 {
      margin-bottom: 1.25rem;
    }
  }
}

.contacts__map-links {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.contacts__link {
  margin-bottom: 1.75rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--free {
    position: relative;

    &::after {
      content: 'Бесплатно по России';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(100% + 1.25rem);
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      line-height: 1.465;
      letter-spacing: 0.04em;
      color: $text-color;
      white-space: nowrap;
      pointer-events: none;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1rem;

    &--free {
      &::after {
        font-size: 0.875rem;
      }
    }

    .contacts__map-links & {
      margin-bottom: 0;
      margin-right: 1.25rem;
    }
  }
}

.contacts__inner-blocks {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;

  @include respond-min(tablet) {
    flex-direction: row;
    margin-top: 3rem;
  }
}

.contacts__inner-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 0;

    .contacts__inner-blocks & {
      width: 20rem;
    }
  }
}

.contacts__caption {
  margin-bottom: 2.5rem;

  @include respond-min(tablet) {
    margin-bottom: 1.25rem;
  }
}
