.analytic-add {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  @include respond-min(tablet) {
    padding-bottom: 6.375rem;
  }
}

.analytic-add__slider-wrapper {
  display: grid;
  grid-template-columns: 1.52fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;

  .card__content {
    transition: all .5s;
  }

  .card__content:hover {
    transform: scale(1.02, 1.02);
  }

  @include respond-min(tablet) {
    grid-template-columns: 1.52fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
  }
}

.analytic-add__image {
  margin: 1.7rem 3.7rem 0;

  svg {
    width: 14rem;
    height: 6.75rem;
  }

  &--small-width {
    width: 3.9rem;
  }

  @include respond-min(tablet) {
    align-items: flex-end;
    justify-content: flex-end;
    min-width: 17rem;
    margin: auto;
    svg {
      width: 14rem;
      height: 6rem;
      margin: auto 2.5rem;
    }
    &--small-width {
      width: .8rem;
    }
    &--small-width svg {
      width: 4.8rem;
    }
  }
}

.analytic-add__slide-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @include font('m');
  min-height: 5.5rem;
  margin-bottom: 0.6rem;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0.02em;

  @include respond-min(tablet) {
    min-height: 2.8rem;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    line-height: 1.19;
  }
}

.analytic-add__btn {
  padding-top: 1.9rem;
  padding-left: 0.5rem;
}
