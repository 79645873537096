.history-modal {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;

  overflow-y: scroll;
  overflow-x: hidden;
  padding: 32px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  pointer-events: none;
  opacity: 0;

  @include respond-min(tablet) {
    overflow-y: unset;
    overflow-x: unset;
    justify-content: center;
    padding: 0;
  }

  &.is-active {
    pointer-events: all;
    opacity: 1;

    .history-modal__overlay {
      opacity: 1;
    }
  }
}

.history-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-duration: .5s;
  background: rgba(10, 29, 51, 0.6);
  backdrop-filter: blur(60px);
}

.history-modal__inner {
  position: absolute;
  top: 0;
  left: 0;

  overflow-y: auto;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 16px;

  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.77, 0, .175, 1);

  @media (min-width: $tablet) {
    bottom: 0;
    right: 0;

    width: 100%;
    max-width: 760px;
    height: max-content;
    max-height: 85vh;
    padding: 62px 67px;
  }
}

.history-modal__close {
  position: absolute;
  z-index: 2;
  top: 32px;
  right: 0px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  border-radius: 50%;
  border: 1.5px solid #FFFFFF;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 1.5px;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @include respond-min(tablet) {
    top: 64px;

    width: 32px;
    height: 32px;
  }
}

.history-modal__index-stat {
  width: 100%;
  margin-bottom: 22px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.history-modal__header {
  position: absolute;
  top: 0;

  width: 100%;
  margin: auto;
}

.history-modal__footer {
  flex-shrink: 0;
  margin: 25px 0 0;
  padding: 0 4rem;
  max-width: 100%;

  @include respond-min(mobile-md) {
    margin: 48px 0 0 0;
    padding: 0 7rem;
  }

  @include respond-min(tablet) {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  @include respond-min(desktop) {
    padding: 0 calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.history-modal__footer-wrapper {
  display: flex;

  @include respond-min(tablet) {
    padding-bottom: 10px;
  }
}

.history-modal__content {
  position: relative;
  width: 350px;

  @include respond-min(tablet) {
    margin-top: unset;
    height: calc(100% - 144px);
    width: unset;
  }
}

.history-modal__wrapper {
  display: flex;
  width: 220px;
  height: 100%;
  align-items: center;

  @include respond-min(mobile-md) {
    width: 270px;
  }

  @include respond-min(tablet) {
    width: 380px;
  }
}

.history-modal__slide-container {
  position: relative;
  width: 100%;
  
  @media (max-width: $desktop) {
    margin-top: 10px;
    width: 50vh;
  }
  @media (max-width: $tablet) {
    margin: 0;
    width: 75%;
  }
  @media (max-width: $tablet-sm) {
    width: 100%;
  }
}

.history-modal__slide-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: unset !important;
  pointer-events: none;
}

.history-modal__slide-arrows {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 90%;
}

.history-modal__slide-arrow {
  flex: 1;
  width: 100%;
  height: 100%;
}

.history-modal__slide-container > .history-modal__dots {
  position: absolute;
  z-index: 2;
  top: 32px;
  bottom: unset;
  left: 0;
  right: 0;

  width: calc(100% - 13.5px * 2);
  height: 2px;
  display: flex;
  margin: auto;
  padding: 0 20px;
}

.history-modal__dots-dot {
  position: relative;

  flex-grow: 1;
  height: 100%;
  margin: 0 2.5px;

  background: rgba(#FFFFFF, 0.4);
  border-radius: 8px;
  cursor: pointer;
}

.history-modal__dots-dot.active::before {
  animation: history 6s forwards;
}

@keyframes history {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.history-modal__dots-dot.active ~ .history-modal__dots-dot::before {
  width: 0%;
}

.history-modal__dots-dot::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: #FFFFFF;
  border-radius: 8px;
}

.history-modal__slide-container .insta-screen {
  position: absolute;
  opacity: 0;

  @include respond-min(tablet) {
    padding: 25px;
  }
}

.history-modal__slide-container .insta-screen.swiper-slide-active {
  position: relative;
  opacity: 1;
}


.history-modal__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  flex-shrink: 0;
  opacity: 0;
  transition: .1s opacity;

  height: 100%;
  margin-top: 20px;
  padding: 0 40px;
  
  @include respond-min(tablet) {
    padding: 0;
  }
}

.history-modal__slide:not(.swiper-slide-active) {
  pointer-events: none;
}

.history-modal__slide.swiper-slide-active {
  opacity: 1;
}

.history-modal__arrows {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;
  width: 100%;
  height: 54px;
  margin: auto;

  @include respond-max(tablet) {
    z-index: 1;
    margin: 65px 0 0;
    height: calc(100% - 65px);
  }
}

.history-modal__arrow.btn--big .btn__circle {
  width: 54px;
  height: 54px;
}

.history-modal__arrow.btn--big .btn__circle::after {
  opacity: 1;
  background-color: currentColor;
}

.btn.disabled .btn__circle:after {
  background-color: currentColor;
  opacity: .6;

  @include respond-min(tablet) {
    background-color: currentColor;
  }
}

.history-modal__arrow.btn--fill.btn--big .btn__arrow {
  color: #FFFFFF;

  @include respond-min(tablet) {
    color: #FFFFFF;
  }
}

.history-modal__arrow {
  position: absolute;
  z-index: 2;

  &--prev {
    left: calc(-56px - 54px);
    transform: rotate(180deg);

    
    @include respond-max(tablet) {
      left: 40px;
      width: calc(50% - 40px);
      background-color: transparent;
      padding: 0;
      height: 100%;
    }
  }

  &--next {
    right: calc(-56px - 54px);

        
    @include respond-max(tablet) {
      right: 40px;
      width: calc(50% - 40px);
      background-color: transparent;
      padding: 0;
      height: 100%;
    }
  }
}

.history-modal__fond {
  flex-shrink: 0;

  width: 85px;
  height: 85px;
  padding: 8px;

  @include respond-min(mobile-md) {
    width: 96px;
    height: 96px;
  }
}

.history-modal__fond.fond:hover::after {
  width: calc(100% + 7px);
  height: calc(100% + 7px);

  @include respond-max(tablet) {
    display: none;
  }
}

.history-modal__fond .fond__image::before {
  content: '';

  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.history-modal__fond::before {
  content: '';

  position: absolute;
  top: unset;
  bottom: -9px;

  width: 100%;
  height: 2px;

  background: transparent;
  transition: .2s all;
}

.history-modal__fond.swiper-slide-thumb-active::before {
  content: '';

  position: absolute;
  top: unset;
  bottom: -9px;

  width: 100%;
  height: 2px;

  background: #FFFFFF;
}

.history-modal__fond .fond__text {
  @include font('r');
  font-size: 11px;
  line-height: 110%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.history-modal__image {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}