.products__stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  min-height: 23px;

  @include respond-min(tablet) {
    margin-bottom: 1.275rem;

    .products--buy & {
      justify-content: flex-start;
    }
  }

  .products__cards & {
    margin-bottom: 0;
    @include respond-min(tablet) {
      margin-bottom: 1.275rem;
    }
  }

}

.products__stats-value {
  display: flex;
  flex-direction: column;
  width: 50%;

  &--risk {
    color: $d-grey;
  }

  &--profit {
    color: $green;
  }

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: normal;
    width: auto;
    margin-right: 0.5rem;

    .products--buy & {
      margin-right: 2.5rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
  .table__btn {
    display: none;
  }
  .active-product {
    display: initial;
  }
}

.products__stats-name {
  margin-bottom: 0.5rem;

  @include respond-min(tablet) {
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-size: 8px;
    line-height: 1;
  }

  @include respond-min(desktop) {
    font-size: 9px;
    line-height: 1;
  }
}

.products__stats-scale {
  position: relative;
  display: flex;
  width: 8.75rem;
  height: 1rem;

  @include respond-min(tablet) {
    width: 4.375rem;
    //height: 0.625rem;
    height: auto;
  }
}

.products__stats-cell {
  width: 20%;
  border: 0.125rem solid currentColor;
  border-right: 0;
  background-color: transparent;

  &:first-of-type {
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
  }

  &:last-of-type {
    border-right: 0.125rem solid currentColor;
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }

  // 5 - количество ячеек в шкале
  @for $i from 1 through 5 {
    .products__stats-value--#{$i} & {
      &:nth-of-type(-n + #{$i}) {
        background-color: currentColor;
        border-left-color: $white;
      }

      &:first-of-type {
        border-left-color: currentColor;
      }
    }
  }

  @include respond-min(tablet) {
    border-width: 0.0625rem;

    &:first-of-type {
      border-top-left-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
    }

    &:last-of-type {
      border-right: 0.0625rem solid currentColor;
      border-top-right-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
  }
}
