.promo-banner {
    width: 100%;

    &__new {
        justify-content: space-between;
        display: none;
        background-image: url('../../../src/assets/img/analytic/analytic-banner-background-01-mobile.png');
        background-size: cover;
        padding: 48px 32px 24px;
        flex-direction: column;
        position: relative;
        
        @include respond-min(tablet) {
            background-image: url('../../../src/assets/img/analytic/analytic-banner-background-01.png');
            flex-direction: row;
            padding: 0 80px 0 48px;
        }

        &--text {
            font-family: Roboto Regular,sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 22.4px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 0;
            color: $white;

            @include respond-min(tablet) {
                padding: 34px 0;
                font-size: 18px;
                font-weight: 400;
                line-height: 25.2px;
                width: 620px;
            }

            .title {
                font-size: 28px;
                font-weight: 600;
                line-height: 33.6px;
                letter-spacing: -0.01em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
        
                @include respond-min(tablet) {
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 39.6px;
                }
            }
            
            .red-button {
                margin-top: 12px;
                text-decoration: none;
                cursor: pointer;
                color: $white;
                background: $red;
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                width: fit-content;
                grid-gap: 8px;
                min-width: 120px;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 700;
                line-height: 20.8px;
                letter-spacing: 0.08em;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
        
                &:hover {
                    background: $red2;
                }
        
                @include respond-min(tablet) {
                    margin-top: 14px;
                }
            }

            .small {
                margin-top: 232px;
                color: #FFFFFFE5;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
        
                @include respond-min(tablet) {
                    color: #FFFFFF99;
                    margin-top: 14px;
                }
            }

        }

        &--picture {
            position: absolute;
            display: flex;
            align-items: end;
            bottom: 0;
            right: 0;
            
            @include respond-min(tablet) {
                position: initial;
            }
        }

        &.active {
            display: flex;
        }
    }
    
    &__banner {
        margin-bottom: 5rem;
        position: relative;
        display: none;
        flex-direction: column;
        overflow-x: hidden;

        &.active {
            display: flex;
        }
        @include respond-min(mobile-mx) {
            margin-bottom: 3.75rem;
            flex-direction: row;
        }
        img {
            width: 160%;
            margin-left: -30%;
            height: 100%;
            object-fit: fill;
            min-height: 230px;

            @include respond-min(mobile-md) {
                width: 100%;
                margin-left: 0;   
                min-height: 240px;             
            }
        }
        &--text {
            position: absolute;
            height: 100%;
            width: 100%;
            padding: 20px 18px;
            font-family: Roboto Regular,sans-serif;
            font-style: normal;
            display: flex;
            flex-direction: column;

            @include respond-min(mobile-mx) {
                text-align: end;
                align-items: flex-end;
                padding: 20px 30px;
                width: 60%;
                right: 0;
            }
            @include respond-min(desktop-sm) {
                padding: 30px;
            }
            .title {
                color: $white;
                font-size: 24px;
                font-weight: 600;
                line-height: 110%;
                letter-spacing: 0.56px;

                @include respond-min(tablet) {
                    font-size: 34px;
                    line-height: 120.6%;
                    letter-spacing: 1.8px;
                }
            }
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0.28px;
                margin: 5px 0;
                
                @include respond-min(tablet) {
                    font-size: 20px;
                    letter-spacing: 0.56px;
                    margin: 10px 0;
                }
                @include respond-min(desktop-sm) {
                    margin: 15px 0;
                }
            }
            a {
                display: block;
                margin-top: auto;
                color: $white;
                font-size: 8px;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 1.56px;
                text-transform: uppercase;
                text-decoration: none;
                text-align: center;
                padding: 12px 24px;
                background: $red;
                border-radius: 40px;
                max-width: 300px;
                align-self: flex-start;
                
                @include respond-min(mobile-mx) {
                    align-self: flex-end;
                }
                @include respond-min(tablet) {
                    font-size: 12px;
                    padding: 15px 40px;
                }
                &:hover {
                    cursor: pointer;
                    opacity: 0.9;
                }
            }
            .btn-white {
                background: $white;
                color: $red;
            }
            .color {
                &-black {
                    color: #21212F;
                }
                &-gray {
                    color: #879979;
                }
                &-green {
                    color: #1FBF72;
                }
                &-pink {
                    color: #EB6D7F;
                }
                &-red {
                    color: $red;
                }
            }
        }
    }
    .mobile-banner {
        display: block;
        
        @include respond-min(mobile-mx) {
            display: none;
        }

    }
    .desktop-banner {
        display: none;
        
        @include respond-min(mobile-mx) {
            display: block;
        }
    }
}

.promo-block-individuals {
    .promo-banner {
        
        &__banner {
            margin-top: -5rem;

            @include respond-min(tablet) {
                margin-top: -6.25rem;
            }
        }
    }
}