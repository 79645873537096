.site-nav {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .header.opened & {
    margin-bottom: 5rem;
    transition-delay: 0.5s;
  }

  @include respond-min(tablet) {
    order: 1;
    display: flex;
    flex-direction: row;
    width: 60%;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
}

.site-nav__list {
  display: flex;
  flex-direction: column;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet) {
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
  }
}

.site-nav__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 0;
    margin-right: 2.25rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.site-nav__link {
  @include font('b');

  font-size: 1.5rem;
  text-decoration: none;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  @include respond-min(tablet) {
    font-size: 0.75rem;
  }
}
