.u-pt-0 {
  padding-top: 0;
}

.u-pt-1 {
  padding-top: 1rem;
  @include respond-min(tablet) {
    padding-top: 1rem;
  }
}

.u-pt-2 {
  padding-top: 3rem;
  @include respond-min(tablet) {
    padding-top: 3rem;
  }
}

.u-pt-3 {
  padding-top: 5rem;
  @include respond-min(tablet) {
    padding-top: 5rem;
  }
}

.u-pt-4 {
  padding-top: 8rem;
  @include respond-min(tablet) {
    padding-top: 8rem;
  }
}

.u-pt-5 {
  padding-top: 10rem;
  @include respond-min(tablet) {
    padding-top: 10rem;
  }
}
