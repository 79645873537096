.s-areas-of-use {
  padding: 54px 0 70px;

  @include respond-min(tablet) {
    padding: 85px 0 103px;
  }
}

.s-areas-of-use__title {
  margin: 0 0 4.5rem;

  text-transform: unset;

  @include respond-min(tablet) {
    width: 60%;
    margin: 0 0 3rem;
  }
}

.s-areas-of-use__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2.1rem;

  @include respond-min(tablet-sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px 26px;
  }
  @include respond-min(desktop-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}
