.stock {
  position: relative;

  min-height: 79px;

  text-decoration: unset;

  transition: .1s all;

  .s-stocks[data-mode="list"] & {
    @include respond-min(tablet) {
      &:hover {
        opacity: .4;
      }
      display: flex;
      margin: 0 0 1.5rem;

      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}

.stock--new .stock__new {
  display: flex;

  .s-stocks[data-mode="list"] & {
    @include respond-min(tablet) {
      left: -26px;

      height: 100%;
      width: 26px;

      &::before {
        transform: rotate(-90deg);
      }
    }
  }
}

.stock__new {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 26px;

  @include font(m);
  text-align: center;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.571429px;

  color: #FFFFFF;
  background: #AD033B;

  &::before {
    content: 'NEW';
  }
}

.stock__header {
  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  min-height: 177px;
  padding: 50px 2rem 27px 2rem;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(0deg, rgba(20, 30, 48, 0.6), rgba(20, 30, 48, 0.6));
  }

  @include respond-min(tablet) {
    padding: 50px 1.5rem 27px 1.5rem;

    .s-stocks[data-mode="list"] & {
      flex-shrink: 0;
      flex-direction: row;
      align-items: center;
      width: 250px;
      min-height: unset;
      padding: 1rem .6rem;

      p {
        margin: 0 .4rem 0 0;
      }
    }
  }
}

.stock__image, .stock__image img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.stock__index, .stock__header p {
  z-index: 1;
}

.stock__header p {
  margin: 0 0 1rem;

  @include font('m');
  font-size: 2.1rem;
  line-height: 130%;
  letter-spacing: 0.571429px;
  color: #FFFFFF;

  @include respond-min(tablet) {
    margin: 0 0 .5rem;

    font-size: 1.13rem;
  }

  .s-stocks[data-mode="list"] & {
    @include respond-min(tablet) {
      font-size: .75rem;
    }
  }
}

.stock__index {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 23px;
  margin-top: 0;
  padding: 0 12px;

  @include font('m');
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.571429px;
  color: #FFFFFF;

  background: $red;
  border-radius: 50px;
}

.stock__info {
  display: flex;
  flex-direction: column;
  margin: 0 0 1.8rem 0;

  @include font('m');
  font-size: 1.7rem;
  color: #000000;

  @include respond-min(tablet) {
    margin: 0 0 1.1rem 0;

    font-size: 1rem;

    .s-stocks[data-mode="list"] & {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% / 8);
      margin: 0;
      padding: 0 .8rem;

      @include font('r');
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.571429px;
      color: #000000;

      span:not(.media-obj) {
        display: none;
      }
    }
  }
}

.stock__info span {
  margin: 0 0 .5rem 0;

  @include font('r');
  font-size: 1.5rem;
  line-height: 110%;
  letter-spacing: 0.571429px;
  color: #828D8F;

  @include respond-min(tablet) {
    margin: 0 0 .25rem 0;

    font-size: .75rem;
  }

}

.stock__content {
  padding: 2rem;

  @include respond-min(tablet) {
    padding: 1.25rem 1.5rem;

    .s-stocks[data-mode="list"] & {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 1.5rem;
    }
  }
}

.stock__text {
  margin: 0 0 2rem 0;

  @include font('r');
  font-size: 1.74rem;
  line-height: 140%;
  letter-spacing: 0.571429px;
  color: #000000;

  @include respond-min(tablet) {
    margin: 0 0 1.5rem 0;

    font-size: .88rem;
  }

  .s-stocks[data-mode="list"] & {
    display: none;
  }
}

.stock__button {
  cursor: pointer;

  .s-stocks[data-mode="list"] & {
    display: none;
  }

  &--info {
    @include font('b');
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    @include respond-min(tablet) {
      font-size: 0.8125rem;
      line-height: 1.02;
      letter-spacing: 0.085em;
    }
  }
}

.stock .btn--plain .btn__circle {
  color: #000000;

  @include respond-min(tablet) {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.stock .btn--plain .btn__text {
  @include respond-min(tablet) {
    font-size: .75rem;

    margin: 0 0 0 .8rem;
  }
}

.stock .btn__line {
  left: 2px;

  @include respond-min(tablet) {
    height: .1rem;
  }
}

.stock .btn__triangle {
  @include respond-min(tablet) {
    border-width: .2rem 0 .2rem .4rem;
  }
}

.stock .btn__arrow {
  @include respond-min(tablet) {
    width: 1rem;
  }
}

.stock .btn__circle:after {
  @include respond-min(tablet) {
    box-shadow: inset 0 0 0 .1rem currentColor;
  }
}

.stock hr {
  margin: 3rem 0 2rem 0;
  height: 1px;

  background: #000000;
  border: unset;

  @include respond-min(tablet) {
    margin: 0 0 1.19rem 0;
  }
}

span.media-obj.stock__svg, span.stock__svg, .stock__svg {
  width: 37px;
  height: 37px;
  margin-bottom: 0;
}
