.banners {
  padding-bottom: 5rem;

  @include respond-min(tablet) {
    padding-left: $offset-desktop;
  }

  @include respond-min(desktop) {
    // padding-left: calc(50vw - #{$desktop} / 2 + 7rem);
  }
}

.banners__footer {
  position: relative;
  z-index: 1;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 25px;
  margin-top: calc(-5rem - 80px);
  margin-bottom: 50px;

  @include respond-min(tablet) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: calc(-5rem - 60px);
    margin-bottom: 67px;
  }
}

.banners__banner {
  display: flex;
  align-items: center;
  padding: 25px 30px;

  @include font('m');
  font-size: 1.7rem;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #000000;

  background: #FFFFFF;

  @include respond-min(tablet) {
    min-height: 151px;
    padding: 16px 30px;

    font-size: 1.1rem;
  }
}

.banners__banner-image {
  width: auto;
  height: auto;
  margin: 0 28px 0 0;
}

.banners__banner svg {
  width: 50px;
  height: 50px;

  @include respond-min(tablet) {
    width: 59px;
    height: 59px;
  }
}
