.bonds-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    pointer-events: none;
    &.is-active{
        pointer-events: all;
        .bonds-modal__overlay{
            opacity: 1;
        }
        .bonds-modal__inner{
            transform: translateX(0%);
        }
    }
    .highcharts-container{
        height: 350px;
    }
    .charts__chart{
        margin-bottom: 0!important
    }
    .highcharts-range-selector-buttons{
        width: 100%;
        display: flex;
        & > text{
            display: none;
        }
    }
    .highcharts-button{
        background: none;
        transition-duration: .4s;
        cursor: pointer;
        rect{
            fill: #ffffff;
            transition-duration: inherit;
        }
        text{
            fill: rgba(0,0,0,.4)!important;
            font-size: 14px;
            font-weight: 400!important;
            text-transform: uppercase;
            transition-duration: inherit;
        }
        &:hover,  &.highcharts-button-pressed{
            text{
                fill: $red!important;
            }
            rect{
                fill: #F7E6EC;
            }
        }
    }
}

.bonds-modal__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-duration: .5s;
    background-color: rgba(0,0,0,.5);
}

.bonds-modal__inner{
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 100%;
    overflow-y: auto;
    width: 650px;
    transform: translateX(120%);
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(.77,0,.175,1);
    padding: 60px 30px;
    @media (max-width: $tablet) {
        width: 100%;
        padding: 16px 16px 100px 16px;
        overflow-x: hidden;
    }
}

.bonds-modal__close{
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:before, &:after{
        content: '';
        position: absolute;
        width: 80%;
        height: 1px;
        background-color: black;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
    @include respond-max(tablet){
        position: relative;
        top: initial;
        right: 0;
        width: 24px;
        //left: calc(100% - 19px);
        height: 24px;
        cursor: pointer;
        margin-bottom: 20px;
    }
}

.bonds-modal__title{
    font-size: 55px;
    font-weight: 700;
    margin-bottom: 40px;
    @media (max-width: $desktop) {
        font-size: 40px;
    }
    @media (max-width: $tablet) {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    span{
      font-size: 0.5em;
      color: $text-color;
      font-weight: 400;
    }
}


.bonds-quality_report__container{
    width: 100% !important;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 150%;
    .horizontal_container {
        width: 100% !important;
        letter-spacing: 0;
        display: flex;
        @media (max-width: $tablet) {
            flex-direction: column-reverse;
            &:first-child {
                flex-direction: column;
            }
        }
        .report_info_container {
            display: flex;
            justify-content: flex-start;
            width: 50%;
            flex-direction: column;
            margin-top: 1rem;
            @media (max-width: $tablet) {
                width: 100%;
                margin-top: 2rem;
            }
            .report_info_row {
                display: flex;
                .bonds-quality_item {
                    width: 30%;
                    padding: .2rem .3rem;
                    text-align: center;
                    &:first-child {
                        text-align: inherit;
                        width: 55%;
                    }
                    &:last-child {
                        width: 15%;
                    }
                    &__info{
                        text-align: center;
                    }
                    &-bold {
                        font-weight: 600;
                    }
                    &__text-md-grey {
                        color: $md-grey;
                    }
                    &__text-grey {
                        color: $text-color;
                    }
                    &__text-red {
                        color: $red;
                    }
                    &__name {
                        font-weight: 600;
                    }
                    .colored_info {
                        text-align: center;
                        padding: 0.2rem 0;
                        background-color: $d-grey;
                        &.is-green {
                            background-color: $green;
                        }
                        &.is-b-green {
                            background-color: $b-green;
                        }
                        &.is-l-green {
                            background-color: $l-green;
                        }
                        &.is-blue {
                            background-color: $l-blue;
                        }
                        &.is-l-red {
                            background-color: $l-red;
                        }
                        &.is-red {
                            background-color: $red;
                        }
                        &.is-grey {
                            background-color: $grey-2;
                        }
                        &.is-black {
                            background-color: $black;
                        }
                    }
                    &.bonds__column-tooltip {
                        margin: 0;
                        height: inherit;
                        display: inherit;
                        .bonds__column-tooltip_content {
                            bottom: 35px;
                        }
                    }
                }
            }
            &.penalty {
                width: 100%;
                .report_info_row {
                    .bonds-quality_item {
                        width: 65%;
                        &:last-child {
                            width: 35%;
                        }
                        .white_text {
                            color: white;
                            font-weight: 600;
                        }
                    }
                    &.annotation {
                        .bonds-quality_item {
                            width: 100%;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.bonds-modal__part-title{
    font-size: 32px;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (max-width: $desktop) {
        font-size: 24px;
    }
    @media (max-width: $tablet) {
        font-size: 16px;
        margin-top: 25px;
    }
    span {
        font-size: 0.7em;
        color: $d-grey;
        &.is-color-green {
            color: $green;
        }
        &.is-color-b-green {
            color: $b-green;
        }
        &.is-color-l-green {
            color: $l-green;
        }
        &.is-color-blue {
            color: $l-blue;
        }
        &.is-color-l-red {
            color: $l-red;
        }
        &.is-color-red {
            color: $red;
        }
        &.is-color-grey {
            color: $grey-2;
        }
        &.is-color-black {
            color: $black;
        }
    }
    span.creditRatingText__wrap {
        text-shadow: 0 0 black;
        font-size: 1em;
    }
    span.quality__wrap {
        text-shadow: 0 0 black;
    }
}
.bonds-modal__part-comb {
  @media (max-width: $tablet) {
    margin-top: 1rem;
    white-space: nowrap;

  }
}
.bonds-modal__part{
    p{
        font-size: 14px;
        line-height: 150%;
    }
    .replacement-main-table {
        @media (max-width: $tablet) {
            margin-bottom: 50px;
        }
    }
    //&:last-of-type{
    //    margin-bottom: 60px;
    //    @include respond-max(tablet){
    //        margin-bottom: 40px;
    //    }
    //}
}
.bonds-modal__emitent{
  margin-bottom: $offset-mobile;
}
.bonds__information-part{
    padding: 10px 0;
    &:not(:last-child){
        border-bottom: 1px solid rgba(0,0,0,1);
    }
    &:first-child{
        padding-top: 0;
    }
    &:last-child{
        padding-bottom: 0;
    }
}

.bonds__information-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    font-size: 14px;
    padding: 12px 0;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child){
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
}

.bonds__information-title{
    color: rgba(0,0,0,.7);
  .bonds__column-tooltip {
    padding: 0;
  }
}

.bonds__information-value{
    color: rgba(0,0,0,1);
}

.bonds-modal__more{
    @media (min-width: $tablet) {
        display: none;
    }
    font-size: 14px;
    color: $red;
    font-weight: 600;
    margin-top: 10px;
}

.bonds-modal__text{
    &-grey{
      color: $text-color;
    }
    &.is-active{
        p{
            -webkit-line-clamp: initial;
        }
        .bonds-modal__more{
            display: none;
        }
    }
    p{
        @include respond-max(tablet){
            //display: -webkit-box;
            //-webkit-box-orient: vertical;
            //-webkit-line-clamp: 2;
            //overflow: hidden;
        }
    }
}

.bonds-modal__chart-empty{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 30px;
    border-top: 1px dashed rgba(0,0,0,.1);
    padding: 40px 0;
    border-bottom: 1px dashed  rgba(0,0,0,.1);
}

.bonds-modal__chart-empty_icon{
    width: 100px;
    height: 80px;
}

.bonds-modal__chart-empty_text{
    font-size: 16px;
    font-weight: 700;
    color: $grey-3;
}
//.charts__stock-max, .charts__stock-min {
//  position: relative;
//  color: $text-color;
//  text-align: right;
//  margin-bottom: 12px;
//  font-family: monospace;
//  &::after  {
//    content: '';
//    display: block;
//    border-bottom: 1px dashed;
//    margin-top: -9px;
//    margin-right: 17%;
//  }
//}
