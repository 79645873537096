.content-header {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  &--not-offset {
    padding-top: 0;
    padding-bottom: 4.3rem;
    padding-left: $offset-mobile;
    padding-right: $offset-mobile;
  }

  &.no--line::before {
    height: 0;
  }

  &:not(.content-header--breadcrumbs)::before {
    content: '';
    position: absolute;
    top: 7.5rem;
    left: $offset-mobile;
    right: $offset-mobile;
    height: 2px;
    background-color: currentColor;
  }

  &--bg {
    z-index: 0;
    min-height: 27.5rem;
    margin-bottom: 3.75rem;
    padding-top: 10.25rem;
  }

  .page--404 & {
    margin-bottom: 10rem;
  }

  &.content-header--not-offset::before {
    left: $offset-mobile;
    top: 11.5rem;
    height: 1px;
  }

  &.blog_header {
    min-height: auto;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
    & .content-header__breadcrumbs {
      margin-bottom: 0;
      margin-left: 0;
      border-bottom: 0;
    }
  }

  @include respond-min(tablet) {
    padding-top: 6.5rem;
    padding-bottom: 3.125rem;
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;

    &__aside {
      padding-left: 0;
      padding-right: 0;
    }

    &:not(.content-header--breadcrumbs)::before {
      top: 5.875rem;
      left: #{$offset-desktop + 18rem};
      right: $offset-desktop;
    }

    &.content-header--not-offset {
      padding-top: 0;
      padding-bottom: 5rem;
    }

    &.content-header--not-offset::before {
      left: $offset-desktop;
      top: 7.07rem;
    }

    &--bg {
      min-height: 18.75rem;
      margin-bottom: 5rem;
      margin-right: $offset-desktop;
      padding-top: 8.5rem;
      padding-bottom: 5.875rem;
      padding-right: 0;

      &::before {
        right: 0;
      }
    }

    &--breadcrumbs {
      padding-top: 4rem;

      &.content-header--big {
        padding-top: 6.5rem;
      }
    }

    &--big {
      &::before {
        top: 8.5rem;
      }
    }

    .page--404 & {
      margin-bottom: 8.375rem;
    }

    .page--product & {
      padding-bottom: 1rem;
    }

  }



  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    padding-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});

    &__aside {
      padding-left: 0;
      padding-right: 0;

      .content-header__breadcrumbs {
        margin-left: 0;
      }
    }

    &:not(.content-header--breadcrumbs)::before {
      left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop + 18rem});
      right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    }

    &.content-header--not-offset::before {
      left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    }

    &--bg {
      padding-right: 0;
    }
  }
}



.content-header__breadcrumbs {
  margin-bottom: 1rem;
  border-bottom: 2px solid currentColor;
  padding-bottom: 0.7em;

  .slider-top--type-b &{
    position: absolute;
    z-index: 1;
    top: 3.2rem;
    left: 2.5rem;
    border-bottom: none;
    margin-left: 0;

    .breadcrumbs__link{
      color: #ffffff;
    }
    .breadcrumbs__link:hover{
      color: #ffffff;
    }
    .breadcrumbs__delimiter {
      fill: #ffffff;
    }

    @include respond-min(tablet) {
      position: absolute;
      z-index: 1;
      top: 3.2rem;
      left: 7rem;

      font-size: .8rem;

      .breadcrumbs__delimiter{
        width: .43rem;
        height: .43rem;
        fill: $text-color;
      }
      .breadcrumbs__link:hover{
        color: $text-color;
      }
    }
    @include respond-min(desktop) {
      left: calc(50vw - #{$desktop} / 2 + 7rem);
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1.375rem;
    margin-left: 18rem;

    .page--single-bond & {
      margin-left: 0;
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .content-header--big & {
      margin-bottom: 2.625rem;
    }
  }
}

.content-header__info {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;

  @include respond-min(tablet) {
    justify-content: space-between;
    margin-top: 1.875rem;
    margin-left: 18rem;
  }
}

.content-header__date {
  @include respond-min(tablet) {
    margin-right: 1.875rem;
  }
}

.content-header__title {
  position: relative;
  margin-top: 5.25rem;
  font-size: 36px;

  .page--article-page & {

    @include respond-max(mobile-ml) {
      font-size: 22px;
    }
  }
  .content-header--bg & {
    margin-top: 0;
  }

  .content-header--breadcrumbs & {
    margin-top: 2.5rem;
  }

  @include respond-min(tablet) {
    margin-top: 1.875rem;
    margin-left: 18rem;
    font-size: 55px;

    &--aside {
      margin-left: 0;
    }

    &--bdb {
      padding-bottom: 5rem;

      &::after {
        content: '';
        position: absolute;
        bottom: 1.875rem;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $black;
      }
    }

    .content-header--breadcrumbs & {
      margin-top: 0.625rem;
    }

    .content-header--big & {
      margin-top: 5.75rem;

      &::before {
        top: -3.75rem;
      }
    }

    .content-header--not-offset & {
      margin-left: 0;
      margin-top: 2.9rem;
    }

    .content-header--breadcrumbs.content-header--big & {
      margin-top: 1.875rem;
    }

  }

  @include respond-min(desktop) {
    .content-header--bg & {
      padding-right: calc(50vw - #{$desktop} / 2);
    }
  }
  .content-header__index {
    top: 0.313rem;
    vertical-align: top;
    letter-spacing: .08em;
    line-height: 0.938rem;
    text-transform: uppercase;
    color: #ad033b;
    font-weight: 500;
    font-size: 1.6rem;

    @include respond-min(tablet) {
      top: 0;
      font-size: 0.813rem;
    }
  }
}

.content-header__caption {
  margin-top: 2.5rem;

  @include respond-min(tablet) {
    margin-top: 1.25rem;
    margin-left: 18rem;

    &--aside {
      margin-left: 0;
    }
  }

  &--reverse {
    @include respond-min(tablet) {
      margin-left: 0;
      margin-right: 18rem;
    }
  }

  .content-header--not-offset & {
    margin-left: 0;
    margin-top: 2.9rem;
  }
}

.content-header__btn {
  min-width: 20rem;
  margin-top: 2.5rem;

  @include respond-min(tablet) {
    min-width: 13.75rem;
    margin-top: 1.25rem;
    margin-left: 18rem;
  }
}

.content-header__image {
  width: calc(100% + #{$offset-mobile});
  margin-top: 5rem;
  margin-bottom: -5rem;
  margin-left: #{-$offset-mobile};

  .content-header--img-small & {
    width: calc(100% + 5rem);
    margin-top: 5rem;
    margin-bottom: -5rem;
    margin-left: -2.5rem;
    @include respond-min(tablet) {
      margin-left: 18rem;
      margin-bottom: 0;
      width: auto;
    }
  }

  img {
    height: 60vw;
    min-height: 27.5rem;
    max-height: 60vh;
    object-fit: cover;
  }

  .content-header--bg & {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    background-color: $d-grey;

    img {
      height: 100%;
      min-height: auto;
      max-height: 100%;
    }
  }

  @include respond-min(tablet) {
    width: calc(100% + #{$offset-desktop});
    margin-top: 3.125rem;
    margin-bottom: -3.125rem;
    margin-left: #{-$offset-desktop};

    img {
      height: 25rem;
      min-height: 25rem;
    }
  }

  @include respond-min(desktop) {
    width: calc(100vw - #{$offset-desktop});
    margin-left: calc((50vw - #{$desktop} / 2 + #{$offset-desktop}) * -1);
  }
}
