.files {
  display: flex;
  flex-direction: column;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  &--inline {
    padding: 0;
    @include respond-max(mobile-ml) {
      display: none;
    }
  }

  &.charts__files {
    padding:  1.4em 0 0;
    .files__link {
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
    .files__xlsx-link {
      height: 100%;
    }
    .files__file-icon {
      margin: auto 0;
      width: 5rem;
      height: 5rem;
      @include respond-min(tablet) {
        width: 2rem;
        height: 2.75rem;
      }
    }
    .files__pie-input {
      background: $white;
      border: 1px $grey solid;
      //padding-right: 5rem;
      //@include respond-min(tablet) {
      //  padding-right: 2.5rem;
      //}
    }
    .files__pie-icon {
      position: absolute;
      top: 50%;
      right: 3.25rem;
      width: 2rem;
      height: 2rem;
      transform: translateY(-50%);
      pointer-events: none;

      @include respond-min(tablet) {
        right: 1.5rem;
        width: 1rem;
        height: 1rem;
      }
    }
    .files__file-title {
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      padding: 0 0.6em 0 0.3em;
    }
    //.charts__range-label {
    //  width: auto;
    //  min-width: 10.5em;
    //  max-width: 11.5em;
    //}
  }

  .page--reports & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.js-tabs-block-years:not(.active) {
  display: none!important;
}

.files__block {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 7.5rem;
  }

  .js-tabs-container-items & + &,
  .js-tabs-container-years & + & {
    margin-top: 0;
  }

  @include respond-min(tablet) {
    margin-left: 18rem;

    .page--reports & {
      margin-left: 0;
    }

    .files--inline & {
      margin-left: 0;
    }
  }
}

.files__title {
  margin-top: 2rem;
  margin-bottom: 5rem;

  @include respond-min(tablet) {
    margin-top: 0;
  }
}

.files__list {
  display: flex;
  flex-direction: column;
  &--inline {
    flex-direction: row;
    align-items: baseline;
  }
}

.files__item {
  position: relative;
  display: flex;
  margin-bottom: 3.75rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .files--inline &:not(:last-child) {
    margin-bottom: 1rem;
  }
  .files--inline &:last-child {
    margin-bottom: 0;
  }


  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
  }

  .page--reports & {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    &:not(:last-child) {
      border-bottom: 1px solid rgba($black, 0.5);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  .files--inline & {
    margin-bottom: 1rem;
  }
}

.files__file-icon {
  flex-shrink: 0;
  width: 3rem;
  height: 4rem;
  pointer-events: none;

  @include respond-min(tablet) {
    width: 2rem;
    height: 2.75rem;
  }

  .files__item--circle & {
    width: 3.5rem;
    height: 3.5rem;

    @include respond-min(tablet) {
      width: 2.25rem;
      height: 2.25rem;
    }
  }

  .page--reports & {
    @include respond-max(tablet) {
      width: 4.5rem;
      height: 5rem;
    }
  }
}

.page--product .files__inner.files-custom {
  display: flex;
  flex-flow: column wrap;
  margin: 4rem 0;

  @include respond-min(tablet) {
    max-width: 20rem;
    margin: 1rem 0;
  }
}

.files__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;

  @include respond-min(tablet) {
    min-height: 2.75rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .page--product .files__inner & {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem 1rem;

    & .files__file-title {
      margin-left: 1rem;
    }
  }

  .files--inline & {
    padding: 0 1rem;
  }
}

.files__name {
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  .files--inline & {
    margin-bottom: 0;
    font-size: 1.3rem;
    @include respond-min(tablet) {
      margin-bottom: 0;
      font-size: .95625rem;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 0.625rem;
    font-size: .95625rem;
  }
}

.files__download-btn {
  &[download] {
    transform: rotate(90deg);
  }
}
