    .block-materials {
        
        &__cases {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-bottom: 160px;
            margin-top: 20px;
            scroll-margin-top: 150px;
                
            @include respond-min(tablet) {
                scroll-margin-top: 300px;
                grid-gap: 20px;
            }
            &--card {
                display: flex;
                flex-direction: column;
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                letter-spacing: 0.02em;
                color: $black-color1;
                flex: 0 0 100%;
                text-decoration: none;
                background: $white;
                margin-bottom: 20px;
                
                @include respond-min(tablet) {
                    flex: 0 0 calc(25% - 20px);
                    flex-grow: 1;
                    margin: 0;
                    max-width: 33%;
                }
                img {
                    align-items: center;
                    width: 100%;
                    height: 170px;
                    object-fit: scale-down;
                        
                    @include respond-min(tablet) {
                        max-height: 160px;
                        height: auto;
                    }
                }
                .img-mobile-fill {
                    object-fit: fill;
                        
                    @include respond-min(tablet) {
                        object-fit: scale-down;
                    }
                }
                .topic {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 130%;
                    color: $red;
                    margin-top: 25px;
                    padding: 0 18px;
                }
                .title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 130%; 
                    margin-top: 15px;
                    padding: 0 18px;
                    //временно
                    margin-bottom: 30px;
                }
                .social {
                    display: flex;
                    margin-top: auto;
                    padding: 0 25px 15px 25px;
                    grid-gap: 30px;
                    //временно
                    display: none;
                        
                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 10px;
                    }
                    div {
                        display: flex;
                        align-items: center;
                            
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }