.bonds__table {
  width: 100% !important;
  letter-spacing: 0;
}
td.shortname.name {
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-items: center;
  @include respond-min(tablet) {
    height: 100%;
  }

  &.wrap {
    white-space: normal;
  }
  .bonds_special {
    margin-left: 2px;
    min-width: 4px;
    height: 15px;
    border-radius: 25%;
    &:first-child {
      margin-left: 5px;
    }
    &.is-red1 {
      background: $red1;
    }
    &.is-l-scarlet {
      background: $scarlet;
    }
    &.is-orange {
      background: $orange;
    }
    &.is-l-orange {
      background: $l-orange;
    }
    &.is-vd-grey1 {
      background: $vd-grey1;
    }
    &.is-l-green1 {
      background: $l-green1;
    }
    @include respond-max(tablet) {
        display:none;
    }
  }
}
td.link_to_bond {
  //max-width: 30px;
}
.bonds__head{
    padding-top: 20px;
    padding-bottom: 20px;
    @include respond-max(tablet) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.bonds__head-title{
    font-size: 42px;
    font-weight: 700;
    margin: 0 0 16px;
    @include respond-max(tablet) {
        font-size: 32px;
        font-weight: 600;
        margin: 0 0 10px;
    }
    @include respond-max(mobile-md) {
        font-size: 28px;
    }
}

.bonds__head-content{
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid black;
    align-items: flex-start;
    @include respond-max(tablet) {
        display: block;
    }
}

.bonds__head-link{
    display: flex;
    white-space: nowrap;
    text-transform: uppercase;
    color: $red;
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;
    svg{
        margin-right: 10px;
        width: 30px;
        height: 16px;
    }
    .media-obj{
        width: initial;
    }
}

.bonds__head-text{
    max-width: 700px;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    padding-right: 30px;
    @include respond-max(tablet) {
        margin-bottom: 30px;
    }
    a{
        color: $blue;
    }
    &.is-active{
        p{
            -webkit-line-clamp: unset;
        }
        .bonds__head-more{
            display: none;
        }
    }
    p{
        @include respond-max(tablet){
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}

.bonds__head-more{
    color: $red;
    background: none;
    border: none;
    display: none;
    margin-top: 5px;
    padding: 0;
    font-weight: 600;
    @include respond-max(tablet) {
        display: block;
    }
}

.bonds__filters{
    padding-top: 20px;
    position: relative;
    background-color: $l-green;
    @include respond-max(tablet) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .wrapper-p{
        position: relative;
    }
}

.bonds__filters-title{
    font-weight: 700;
    font-size: 32px;
    margin: 0 0 20px;
    @include respond-max(tablet) {
        font-weight: 600;
        font-size: 18px;
    }
}

.bonds__filters-subtitle{
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: bold;
    @include respond-max(tablet) {
        display: none;
    }
}

.bonds__filters-list{
    display: flex;
    margin: -10px 0 15px;
    flex-wrap: wrap;
    max-width: 1080px;
    @include respond-max(tablet) {
        display: none;
    }
    &.no-wrap {
      flex-wrap: nowrap;
    }
    li{
        margin-top: 8px;
        position: relative;
        &:not(:last-child){
            margin-right: 8px;
        }
        &.is-active{
            .bonds__filters-tab{
                border: 1px solid black;
                &:after{
                    transform: rotate(-135deg) translate(-3px, -2px);
                }
            }
            .bonds__filters-dropdown{
                opacity: 1;
                transform: translateY(0px);
                pointer-events: all;
            }
        }
    }
}
.bonds__filters-li {
  background: white;
  border-radius: 35px;
}
.bonds__filters-tab{
    background: white;
    text-decoration: none;
    color: #222;
    margin-top: 10px;
    border-radius: 35px;
    padding: 10px 15px;
    display: flex;
    transition-duration: .4s;
    white-space: nowrap;
    font-size: 14px;
    border: 1px solid transparent;
    &:not(.bonds__filters-tab--more){
        &:after{
            transition-duration: inherit;
            content: '';
            width: 8px;
            height: 8px;
            transform-origin: center;
            margin-top: 2px;
            margin-left: 15px;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            transform: rotate(45deg);
        }
    }
    &_currency {
      background-color: $l-red;
    }
    &_quick-select, &_ibond {
        background-color: $scarlet;
        color: $white;
    }
    &_basic {
      background-color: $l-yellow;
    }
    &_strategy {
      background-color: $l-blue;
    }
    &_replacement {
      background-color: $d-green;
    }
    &_marketmap {
      background-color: $green;
    }
    &_ladder {
      background-color: $l-orange;
      &:after{
        display: none;
      }
    }
}
.separate-tab {
  height: 100%;
  margin-top: 0;
  align-items: center;
  &:after{
    margin-top: -4px !important;
  }
}
.bonds__filters-clear{
    border-radius: 35px;
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    background-color: $grey;
    font-weight: bold;
    color: black;
    margin-top: 10px;
    display: none;
    &.is-active{
        display: block;
        @media (max-width: $tablet){
            display: none;
        }
    }
}

.bonds__filters-tab--more{
    align-items: center;
    font-weight: bold;
    .media-obj{
        width: 15px;
        height: 15px;
        margin-left: 7px;
        margin-top: -3px;
    }
}

.bonds__filters-dropdown{
    position: absolute;
    min-width: 330px;
    background: white;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22);
    top: 60px;
    transform: translateY(-10px);
    transition-duration: .4s;
    opacity: 0;
    pointer-events: none;
    z-index: 3;
    @include respond-max(desktop) {
        min-width: 250px;
        display: block;
    }
    &:before{
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        left: 16px;
        top: -8px;
        transform: rotate(45deg);
        background: white;
        cursor: initial;
    }
  &.bonds__filters-dropdown--columns {
      display: flex;
    }
}
.bonds__filters-dropdown--column {
  min-width: 250px;
}

.bonds__filters-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition-duration: .4s;
    //will-change: transform;
    color: rgba(0,0,0,.7);
    font-size: 14px;
    padding: 12px 16px;
    position: relative;
    @media (max-width: $tablet){
      padding: 0;
    }
    .js__filter:not(.range) {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      align-items: center;
    }
    &.bonds__filters-separate {
      color: $black;
      will-change: auto;
      //padding: 5px 12px;
    }
    &:not(:last-child):after{
        content: '';
        position: absolute;
        bottom: 0;
        width: calc(100% - 16px);
        right: 0;
        height: 1px;
        background: #E6E6E6;
    }
    & > span{
        max-width: calc(100% - 60px);
        //white-space: nowrap;
        text-overflow: ellipsis;
        @media (max-width: $tablet){
          overflow: hidden;
          //padding-left: 30px;
        }
    }
    .checkbox{
        background: #E8E8E8;
        transition-duration: inherit;
        width: 40px;
        height: 24px;
        border-radius: 47px;
        position: relative;
        &:before{
            position: absolute;
            content: '';
            width: 20px;
            transition-duration: inherit;
            height: 20px;
            background-color: white;
            border-radius: 100%;
            top: 2px;
            left: 2px;
        }
    }
    &.is-active{
        color: rgba(0,0,0,1);
        .checkbox{
            background-color: $red;
            &:before{
                transform: translateX(16px);
            }
        }
    }
  &.btn-inline {
    display: inline-flex;
    .bonds__column-tooltip {
      margin-top: -6px;
    }
  }
}
.bonds__mode-replacement {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @include respond-max(tablet) {
    flex-direction: column;
    label {
      margin-bottom: 15px;
      &::after {
        display: none;
      }
    }
  }
  input {
    display: none;
  }
  .checkbox {
    margin-right: 15px;
  }
  &-block {
    display: block;
    text-align: center;
  }
  &.disabled {
    display: none;
  }
}
.bonds__filters-form{
    width: 100%;
    background: white;
    border-radius: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 20px;
    padding: 15px 4px 15px 20px;
    @include respond-max(tablet) {
        margin-top: 30px;
        margin-bottom: 0px;
        background: transparent;
        display: block;
        height: auto;
        padding: 0;
        text-align: center;
    }
    input{
        background: none;
        width: 100%;
        //max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
        border: none;
        min-width: 166px;
        @include respond-max(tablet) {
            width: 75%;
            //min-width: 100%;
            //max-width: 100%;
            font-size: 14px;
        }
    }
    &--localsearch {
        border: 1px solid #e6e6e6;
        width: auto;
        padding: 5px 15px;
        margin: 9px;
        height: auto;
    }
}

.bonds__filters-form_part{
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    @include respond-max(tablet) {
        background-color: white;
        padding: 15px 15px 15px 20px;
        border-radius: 35px;
        margin-bottom: 8px;
        position: relative;
        &-isin {
          margin-bottom: 15px;
          padding: 0 0 15px 0;
        }
    }
  &-isin {
    margin-bottom: 15px;
  }
}
.bonds__filters-chips--wrap {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    &:before{
      //content: '';
      position: absolute;
      height: 100%;
      right: 0;
      right: 0;
      top: 0;
      width: 50px;
      z-index: 1;
      background: linear-gradient(90deg, transparent, $l-green);
      @include respond-max(tablet) {
        display: none;
      }
    }
}
.bonds__filters-chips{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: start;
    margin-right: 20px;
    position: relative;
    scrollbar-color: $ed-grey $l-grey;
    scrollbar-width: thin;
    @include respond-max(tablet) {
        flex-wrap: wrap;
        padding-bottom: 8px;
    }
    &::-webkit-scrollbar {
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: $l-grey;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $ed-grey;
    }
}

.bonds__filters-chip{
    color: white;
    background-color: black;
    font-size: 12px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    position: relative;
    padding: 6px 4px 6px 12px;
    border-radius: 40px;
    margin-bottom: 8px;
    @include respond-max(tablet) {
        margin-top: 7px;
        white-space: normal;
    }
    &:not(:last-child){
        margin-right: 8px;
    }
}

.bonds__filters-chip_remove{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: $ed-grey;
    border: none;
    margin-left: 5px;
    transition-duration: .3s;
    position: relative;
    &:hover{
        &:before, &:after{
            background: hsla(0,0%,100%, 1);
        }
    }
    &:before, &:after{
        width: 55%;
        height: 1px;
        position: absolute;
        transition-duration: inherit;
        top: 0;
        bottom: 0;
        content: '';
        left: 0;
        right: 0;
        margin: auto;
        background: hsla(0,0%,100%,.8);
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
}

.bonds__filters-submit{
    cursor: pointer;
    border-radius: 100%;
    background-color: $red;
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition-duration: .4s;
    @include respond-max(tablet) {
      position: absolute;
      top: 5px;
      right: 5px;
      &__inner {
        right: 6px;
        top: 8px;
      }
    }
    &:hover{
        background-color: $d-red;
    }
    .media-obj{
        width: 16px;
        height: 16px;
        svg{
            fill: white;
        }
    }
  &__inner {
    position: absolute;
    right: 6px;
    top: 8px;
  }
}

.bonds__filters-form_icon{
    width: 20px;
    height: 20px;
    margin-top: -1px;
    margin-right: 5px;
    svg{
        width: initial;
    }
}

.bonds__filters-bottom{
    border-top: 1px solid rgba(0,0,0,.1);
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $l-green;
}

.bonds__control{
    & > span{
        font-size: 14px;
        margin-right: 10px;
    }
    &--outlined{
        .ss-main .ss-single-selected {
            border: 1px solid black;
        }
    }
}

.bonds__filters-download{
    display: flex;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase;
    border: none;
    background: none;
    padding: 0;
    text-decoration: none;
    color: black;
    font-weight: 700;
    letter-spacing: 1px;
    .media-obj{
        width: 18px;
        margin-top: -2px;
        height: 18px;
        margin-right: 8px;
    }
  &--text {
    white-space: nowrap;
  }
}
.bonds__rows_count {
  display: flex;
  align-items: center;
  @include respond-max(mobile-ml) {
    width: 140px;
  }
}
.bonds__filters-bg{
    position: absolute;
    z-index: 0;
    height: 100%;
    left: 0;
    width: 100%;
    top: 0;
    overflow: hidden;
    @include respond-max(desktop) {
        display: none;
    }
}

.bonds__filters-modal_btn{
    //width: 100%;
    margin: 3px;
    padding: 10px;
    @include respond-min(tablet) {
        display: none;
    }
}

.bonds-filter__modal{
    position: fixed;
    top: 0;
    overflow-y: auto;
    left: 0;
    background: white;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    padding: 20px 2.5rem 100px;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(.77,0,.175,1);
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    transform: translateY(130vh);
    &.is-active{
        transform: translateY(0);
    }
}

.bonds-filter__modal-head{
    display: flex;
    justify-content: space-between;
}

.bonds-filter__modal-head_btn{
    color: $red;
    border: none;
    background-color: transparent;
    font-size: 14px;
    padding: 0;
    opacity: 0;
    transition-timing-function: ease;
    transition-duration: .4s;
    pointer-events: none;
    transform: translateY(10px);
    display: block;
    font-weight: bold;
    &.is-active{
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
    }
    @media (min-width: $tablet) {
        display: none;
    }
}

.bonds-filter__modal-close{
    padding: 0;
    width: 20px;
    height: 20px;
    position: relative;
    display: block;
    background-color: transparent;
    border: none;
    &:before, &:after{
        width: 100%;
        height: 2px;
        position: absolute;
        transition-duration: inherit;
        top: 0;
        bottom: 0;
        content: '';
        left: 0;
        right: 0;
        margin: auto;
        background:black
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
}

.bonds-filter__modal-title{
    font-size: 32px;
    font-weight: 600;
    margin-top: 25px;
}

.bonds-filter__modal-list{
    margin-top: 24px;
    & > li{
        &:not(:last-child){
            margin-bottom: 12px;
        }
    }
}

.bonds-filter__modal-btn{
    width: 100%;
    background: white;
    border: none;
    text-align: left;
    font-size: 14px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-radius: 35px;
    padding: 13px 16px;
    box-shadow: 0px 0px 32px rgba(211, 214, 215, 0.05), 0px 0px 64px rgba(211, 214, 215, 0.05), 0px 0px 4px rgba(211, 214, 215, 0.2), 0px 16px 16px rgba(211, 214, 215, 0.2), 2px 2px 4px rgba(211, 214, 215, 0.2);
    font-weight: 500;
    &:after{
        content: '';
        width: 8px;
        height: 8px;
        margin-top: 2px;
        margin-left: 15px;
        border-top: 1px solid black;
        border-right: 1px solid black;
        transform: rotate(45deg);
    }
    &.is-applied{
        background: $l-grey;
        flex-direction: column;
        align-items: start;
        border-radius: 16px;
        box-shadow: none;
        .bonds-filter__modal-btn_title{
            color: rgba(0,0,0,.6);
            font-size: 12px;
        }
        .bonds-filter__modal-btn_list{
            margin-top: 8px;
        }
        &:after{
            display: none;
        }
        .bonds-filter__modal-btn_clear{
            display: block;
        }
    }
    &_currency {
      background-color: $l-red;
    }
    &_basic {
      background-color: $l-yellow;
    }
    &_strategy {
      background-color: $l-blue;
    }

}

.bonds-filter__modal-btn_clear{
    display: none;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 13px;
    height: 13px;
    &:before, &:after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 1px;
        background-color: black;
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
}

.bonds-filter__modal-btn_list{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px 12px;
    li{
        font-size: 14px;
        margin-bottom: 0;
    }
}

.bonds__range{
    padding: 20px 25px;
    cursor: initial;
}

.bonds-filter__modal-sections {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    z-index: 12;
}

.bonds-filter__modal-section_part{
    position: absolute;
    width: 100%;
    background: white;
    opacity: 0;
    top: 0px;
    font-size: 14px;
    left: 0;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(.77,0,.175,1);
    box-shadow: 0px 0px 14px rgba(0,0,0,.2);
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    .bonds__filters-dropdown{
        position: static;
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
        background: none;
        box-shadow: none;
        &:before{
            display: none;
        }
    }
}

.bonds-filter__modal-submit{
    background: $red;
    color: white;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    border: none;
    padding: 13px;
    margin-top: 20px;
    border-radius: 35px;
}

.bonds-filter__modal-section{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    &.is-active{
        position: fixed;
        .bonds-filter__modal-section_part{
            opacity: 1;
        }
        .bonds-filter__modal-section_overlay{
            opacity: 1;
            pointer-events: all;
        }
    }
}

.bonds-filter__modal-section_overlay{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    width: 100%;
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    transition-duration: .5s;
}

.bonds-filter__modal-section_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 16px;
}

.bonds-filter__modal-section_title{
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    max-width: calc(100% - 170px);
    text-overflow: ellipsis;
    //overflow: hidden;
    font-weight: bold;
    white-space: nowrap;
}

.bonds-filter__modal-section_set{
    font-size: 12px;
    color: $red;
    font-weight: 500;
    background: none;
    border: none;
    padding: 0;
}

.bonds-filter__modal-section_close{
    background: none;
    border: none;
    padding: 0;
    width: 14px;
    height: 14px;
    position: relative;
    &:before, &:after{
        width: 100%;
        height: 1px;
        position: absolute;
        transition-duration: inherit;
        top: 0;
        bottom: 0;
        content: '';
        left: 0;
        right: 0;
        margin: auto;
        background:black
    }
    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
}

.bonds__table{
    @media (max-width: $tablet) {
        padding: 35px 0;
        overflow-x: hidden;
        background-color: $l-grey;
    }
}
.bonds__thead {
  position: sticky;
  z-index: 1;
  top: 6.8rem;
}
.bonds__table-head{
    background-color: black;
    color: white;
    padding: 10px 0;
    position: sticky;
    top: 100px;
    z-index: 2;
    @media (max-width: $tablet) {
        display: none;
    }
}

.is-scrolling-up{
    .bonds__table-head{
        @media (min-width: $tablet) {
            top: 158px;
        }
    }
}

.bonds__table-list{
    .bonds__item:not(:last-child){
        @media (min-width: $tablet) {
            border-bottom: 1px solid rgba(0,0,0,.1);
        }
    }
    .bonds__item:nth-last-child(-n+10) {
      .bonds_special-tooltip_content--down {
        top: inherit;
        bottom: 17px;
        &:before {
          top: auto;
          bottom: -7px;
        }
      }
    }
    @media (max-width: $tablet) {
        display: grid;
        grid-gap: 16px;
    }
}

.bonds__table-head, .bonds__item-inner{
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(9, minmax(85px, 1.33fr));
}
.bonds__table-head__modal, .bonds__item-inner__modal {
  grid-template-columns: repeat(6, minmax(85px, 1.33fr));
}
th.bonds__column {
  padding: 0;
  word-break: break-word;
  &.sorting_disabled {
    .bonds__column-arrows {
      display: none;
    }
  }
}
.bonds__column-title{
    font-size: 12px;
}

.bonds__column-head{
    display: flex;
  justify-content: center;
}

.bonds__column-arrows{
    margin-left: 5px;
    margin-top: 2px;
}

.bonds__column-arrow{
    background: none;
    display: block;
    border: none;
    padding: 0;
    border: 3px solid transparent;
    border-top: 4px solid white;
    opacity: .4;
    transition-duration: .4s;
    &:first-child{
        transform: rotate(180deg);
        margin-bottom: 3px;
    }
    &:hover{
        opacity: 1;
    }
}

.bonds__column-tooltip{
  //margin-top: 4px;
  //margin-left: 4px;
  position: relative;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: inline-block;
  .media-obj{
    width: inherit;
    height: inherit;
    svg{
      fill: #7F7F7F;
      transition-duration: .4s;
    }
  }
  &:hover{
    .bonds__column-tooltip_content{
      opacity: 1;
      transform: translateY(0px);
    }
    svg{
      fill: white;
    }
  }
  @media (max-width: $tablet) {
    padding: 0 16px 0 4px;
  }
}

.bonds__column-tooltip_content{
  position: absolute;
  bottom: 15px;
  min-width: 330px;
  font-size: 14px;
  text-align: left;
  color: black;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(.77,0,.175,1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22);
  background: white;
  padding: 14px;
  left: -15px;
  z-index: 1;
  //right: 0;
  transform: translateY(-8px);
  opacity: 0;
  pointer-events: none;
  white-space: normal;
  font-weight: normal;
  &:before{
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    left: 16px;
    bottom: -7px;
    transform: rotate(45deg);
    background: inherit;
    cursor: initial;
  }
  &.bonds__column-tooltip_content--right {
    left: inherit;
    right: -15px;
    &:before {
      right: 16px;
      left: auto;
    }
  }
  &.bonds__column-tooltip_content--down {
    bottom: inherit;
    top: 15px;
    &:before {
      bottom: auto;
      top: -7px;
    }
  }
  @media (max-width: $tablet){
      padding: 7px 15px;
    }

}

.bonds_special-tooltip{
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover{
      .bonds_special-tooltip_content{
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @media (max-width: $tablet) {
      /*padding: 0 16px 0 4px;*/
    }
}

.bonds_special-tooltip_content{
    position: absolute;
    bottom: 17px;
    min-width: 250px;
    font-size: 14px;
    text-align: left;
    color: black;
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(.77,0,.175,1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22);
    background: white;
    padding: 14px;
    left: -21px;
    z-index: 1;
    //right: 0;
    transform: translateY(-8px);
    opacity: 0;
    pointer-events: none;
    white-space: normal;
    font-weight: normal;
    &:before{
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        left: 16px;
        bottom: -7px;
        transform: rotate(45deg);
        background: inherit;
        cursor: initial;
    }
    &.bonds_special-tooltip_content--right {
      left: inherit;
      right: -21px;
      &:before {
        right: 16px;
        left: auto;
      }
    }
    &.bonds_special-tooltip_content--down {
      bottom: inherit;
      top: 17px;
      &:before {
        bottom: auto;
        top: -7px;
      }
    }
  @media (max-width: $tablet){
      padding: 7px 15px;
    }

}

.bonds__table-bottom, .dataTables_paginate {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: space-between;
    /*@media (max-width: $tablet){
      padding-bottom: 0;
    }*/
}

.bonds__table-bottom_part{
    display: flex;
    align-items: center;
    @media (max-width: $tablet){
        display: none;
    }
}

.bonds__table-bottom_link{
    background: black;
    display: flex;
    border-radius: 40px;
    color: #fff;
    align-items: center;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: .12em;
    text-decoration: none;
    padding: 12px 15px;
    margin-left: 24px;
    .media-obj{
        width: 17px;
        height: 17px;
        margin-right: 6px;
    }
}

.bonds__pagination, .dataTables_paginate, .dataTables_paginate span:not(.media-obj) {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-right: -12px;
    padding-left: 7.75rem;
    padding-right: 7.75rem;
    li, a{
        margin-right: 12px;
    }
    @media (max-width: $tablet){
      //width: 100%;
      //flex-wrap: wrap;
      &:not(.ellipsis) {
        padding-left: 0rem;
        padding-right: 0rem;
      }
      padding-left: 0rem;
      padding-right: 1.25rem;
    }
}
.paginate_button.last, .paginate_button.first {
  display: none;
}
.bonds__pagination-link, .paginate_button {
    cursor: pointer;
    border-radius: 100%;
    background: $l-grey;
    transition-duration: .4s;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    min-width: 48px;
    color: black;
    text-decoration: none;
    height: 48px;
    @media (max-width: $tablet){
        &:not(.bonds__pagination-link--next){
          //background: white;
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
        &:not(.next){
          //background: white;
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
    }
    &:not(.bonds__pagination-link--next):hover, &.is-current{
        background: $red!important;
        color: white;
    }
    &:not(.next):hover, &.current{
        background: $red!important;
        color: white;
    }
    &--next{
        background: black;
    }
    &.next{
        background: black;
    }

    .media-obj{
        width: 20px;
        height: 10px;
    }
}

.bonds__footer-link{
    color: black;
    font-size: 14px;
    text-decoration: underline;
    text-underline-position: under;
    line-height: 1.7;
    text-decoration-color: $blue;
    padding-bottom: 4px;
    width: fit-content;
}

.bonds__footer{
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    @media (max-width: $tablet){
        background-color: $l-grey;
        flex-direction: column;
        grid-gap: 15px;
    }
    p{
        font-size: 14px;
    }
}

.bonds__footer-list{
    display: grid;
    grid-gap: 15px;
}

.bonds__table-wrapper{
    position: relative;
    transition-duration: .6s;
    //overflow: hidden;
    display: block;
}
.dataTables_processing{
  //@media (min-width: $tablet){
    height: 100%;
    width: 100%;
    position: absolute;
    .bonds__preloader-overlay{
      opacity: 1;
      pointer-events: all;
    }
  //}
}

.bonds__preloader-overlay{
    position: absolute;
    top: 0;
    pointer-events: none;
    opacity: 0;
    transition-duration: inherit;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1;
}

.bonds__preloader{
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    border: 4px solid $red;
    top: 150px;
    left: 0;
    right: 0;
    margin: auto;
    animation: loader 2s infinite ease;
}

.bonds__preloader-inner{
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $red;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }

  #DataTables_Table_0_filter {
    display: none;
  }
  #DataTables_Table_0_wrapper {
    overflow-x: clip;
  }
.dataTables_empty {
  display: none;
}
#DataTables_Table_0_info {
  text-align: center;
  padding: 20px;
  font-size: 1.3rem;
}
.dt-button {
  background: none;
  border: 0;
  span {
    display: flex;
    .bonds__column-tooltip {
      margin-top: 0;
    }
  }
}
input[type="date"] {
  -webkit-appearance: none
}
.bonds__table-sorting--mobile {
    font-size: 14px;
    padding-bottom: 0;
    .bonds__table-sorting--head {
        padding-bottom: 5px;
    }
    > .ss-main {
        width: 100%;
    }
    @media (min-width: $tablet){
        display: none !important;
    }
}
.market_map {
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
  &.is-active {
    display: block;
  }
}
.bonds-replacment__container .dataTables_length, .bonds-replacment__container .dataTables_filter {
  display: none;
}
.bonds-replacment__container th {
  width: auto!important;
}
.form-upload-xls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .file__upload {
    overflow: hidden;
    width: 45%;
    vertical-align: center;
    display: flex;
    align-items: center;
    border: 2px dashed #d1d7db;
    border-radius: 1.875rem;
    text-align: center;
    justify-content: center;
    label {
      vertical-align: center;
      display: block;
      cursor: pointer;
    }
  }
  input.input__file {
    display: none;
  }
}
.output-portfolio {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  p {
    font-size: 12px;
    line-height: 1.5;
  }
}
.xls-btn {
  margin: 20px auto 0;
  display: none;
}
.modal__container-xls {
  min-height: 260px;
}

.analytics-portfolio-replacement-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .file {
    width: 80px;
    height: auto;
    margin: 20px;
    * {
      transform-origin: center;
    }
    &:last-child {
      margin-right: 120px;
    }
  }
  .file-body {
    fill: #d7d7d7;
  }
  .file-bend {
    fill: #848484;
  }
  .file-check {
    fill: #53a21e;
  }
  .file-loading {
    fill: none;
    stroke: #848484;
    stroke-width: 3px;
    stroke-dasharray: 49;
    stroke-linecap: round;
    transform: rotate(-90deg);
  }
  .move {
    animation: move 2.5s infinite;
  }
  .load {
    animation: load 2.5s infinite;
  }
  .done {
    animation: done 2.5s infinite;
  }
  .fadein{
    animation: fadein 2.5s infinite;
  }
  .fadeout{
    animation: fadeout 2.5s infinite;
  }
  .zoomin{
    animation: zoomin 2.5s infinite;
  }
  .zoomout{
    animation: zoomout 2.5s infinite;
  }
  @keyframes move {
    0% {
      transform: translate(0);
    }
    60% {
      transform: translate(0);
    }
    90% {
      transform: translate(124px);
    }
    100% {
      transform: translate(124px);
    }
  }
  @keyframes load {
    0% {
      stroke-dashoffset: 49;
      opacity: 1;
    }
    30% {
      stroke-dashoffset: 0;
      opacity: 1;
    }
    35% {
      opacity: 0;
    }
    100% {
      stroke-dashhoffset: 0;
      opacity: 0;
    }
  }
  @keyframes done {
    0% {
      transform: scale(0);
    }
    35% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes fadein{
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeout{
    0% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes zoomin{
    0% {
      transform: scale(1);
    }
    60% {
      transform: scale(1);
    }
    90% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1.5);
    }
  }
  @keyframes zoomout{
    0% {
      transform: scale(1.5);
    }
    60% {
      transform: scale(1.5);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}
.best_score_text {
  color: rgba(0,0,0,.7);
  font-size: 14px;
}
.ibond_range .noUi-pips.noUi-pips-horizontal {
  display: none;
}
.ibond-modal-link {
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
}
.ibond-modal-status {
  font-weight: bold;
}
