/* Headings */

.t-h1 {
  @include text('h1');
}

.t-h2 {
  @include text('h2');
}

.t-h3 {
  @include text('h3');
}

.t-h4 {
  @include text('h4');
}


/* Text */

.t-small {
  @include text('t-small');
}

.t-little {
  @include text('t-little');
}

.t-normal {
  @include text('t-normal');
}

.t-middle {
  @include text('t-middle');
}

.t-large {
  @include text('t-large');
}

.t-big {
  @include text('t-big');
}


/* Style */

.t-ttu {
  text-transform: uppercase;
}

.t-tdn {
  text-decoration: none;
}

.t-wsn {
  white-space: nowrap;
}

.t-vab {
  vertical-align: bottom;
}

.t-tac {
  text-align: center;
}

.t-fwb {
  font-weight: bold;
}

.t-l-title {
  @include text('t-l-title');
}

.t-m-title {
  @include text('t-m-title');
}

.t-l-caption {
  @include text('t-l-caption');
}

//Links

.link--red{
  background-color: $red;
  text-align: center;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 50px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
  transition-duration: .4s;
  letter-spacing: 1px;
  &:hover{
    background-color: $d-red;
  }
}

.link--l-red{
  background-color: $l-red;
  text-align: center;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 50px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
  color: $black;
  transition-duration: .4s;
  letter-spacing: 1px;
  &:hover{
    background-color: $grey;
  }
}

.link--l-yellow{
  background-color: $l-yellow;
  text-align: center;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 50px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
  color: $black;
  transition-duration: .4s;
  letter-spacing: 1px;
  &:hover{
    background-color: $grey;
  }
}

.link--l-blue{
  background-color: $l-blue;
  text-align: center;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 50px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
  color: $black;
  transition-duration: .4s;
  letter-spacing: 1px;
  &:hover{
    background-color: $grey;
  }
}
.link--l-scarlet{
  background-color: $scarlet;
  text-align: center;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 50px;
  font-size: 10px;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px;
  color: $black;
  transition-duration: .4s;
  letter-spacing: 1px;
  &:hover{
    background-color: $grey;
  }
}

.section-title {
  margin-bottom: 2.5rem;

  @include respond-min(tablet) {
    margin-bottom: 1.7rem;
  }
}

.f-montserrat {
  font-family: 'Montserrat', sans-serif;
}

em {
  font-style: italic;
}