.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $l-green;
  padding: 2.4rem 2.5rem;

  
  &:not(.banner-green, .banner-about) {

    @include respond-min(tablet) {
      padding: .9rem 5rem;
    }
    @include respond-min(desktop) {
      padding: .9rem calc(50vw - #{$desktop} / 2 + 3.75rem);
    }
  }
  &__icon {
    width: 4.5rem;
    height: 3rem;
    margin-right: 1.7rem;
    align-self: flex-start;
    @include respond-min(tablet) {
      width: 1.5rem;
      height: 1.5rem;
      align-self: center;
      margin-right: 1rem;
    }
  }

  &__button-close {
    margin-left: auto;
    align-self: flex-start;
  }

}
.banner-green {
  text-decoration: none;  
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.6;
  color: $black;  
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @include respond-min(tablet) {
    padding: .9rem 5rem .9rem 8rem;
  }
  @include respond-min(desktop) {
    padding: .9rem calc(50vw - #{$desktop} / 2 + 3.75rem)
             .9rem calc(50vw - #{$desktop} / 2 + 3.75rem + 65px);
  }
}
.banner-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  &__wrapper {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  &__text,
  &__link-reg,
  &__link-input {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.6;

    @include respond-min(tablet) {
      font-size: 1.0rem;
      line-height: 1.4;
    }
  }

  &__text {
    width: 100%;
    letter-spacing: 0;
    @include respond-min(tablet) {
      width: auto;
      letter-spacing: .04rem;
    }
  }

  &__text:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: .6rem;
    @include respond-min(tablet) {
      margin-bottom: 0;
      margin-right: .8rem;
    }
  }

  &__link-reg,
  &__link-input,
  &__vl {
    margin-right: .5rem;
    @include respond-min(tablet) {
      margin-right: .5rem;
    }
  }

  &__link-reg,
  &__link-input {
    color: $blue;
    border-bottom: 2px solid transparent;
    text-decoration: none;
  }

  &__link-reg:hover,
  &__link-input:hover {
    cursor: pointer;
    border-bottom: 2px solid $blue;
  }

  &__link-reg,
  &__link-input {
    margin-top: 2px;
  }

  &__vl {
    background: $black;
    height: 2rem;
    width: 1px;
    @include respond-min(tablet-sm) {
      height: 1.5rem;
    }
  }
}
