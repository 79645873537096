//@import '/node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
@import '/node_modules/nouislider/distribute/nouislider.min.css';
@import '/node_modules/tippy.js/dist/tippy.css';
@import '/node_modules/swiper/css/swiper.min.css';
//@import '/node_modules/pikaday/scss/pikaday.scss';
@import '/node_modules/slim-select/dist/slimselect.min.css';

.bonds_filters__extend {
  display: none;
}

.page--analytic {
  .search-banner {
    margin-bottom: 3.75rem;
  }
}
.simplesearch-search-form {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 1rem;

  .form__btn_search {
    height: 28px;
    margin-left: 2px;
  }
  .form__input_search {
    height: 28px;
  }
  label {
    font-size: 12px;
  }
}

.form__input_search {
  width: 100%;
  .input__input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.btn--fill.form__btn_search {
  padding: 0.88rem 2.125rem;
}

.tabs.the-tabs-head_analytic {
  margin-bottom: 1rem;
}

.products__analytic_blog_item {
  margin-bottom: 2rem;
  .products__image::before {
    display: none;
  }
}

.simplesearch-paging {
  display: none;
}
#table-bonds_length {
  font-size: 0.85rem;
  float: right;
  margin-right: 160px;
}
.simplesearch-results {
  padding: 20px 0;
  font-size: 2rem;
}

.simplesearch-highlight {
  font-style: italic;
  text-decoration: underline;
}

.analytic__content {
  padding-bottom: 0;
  border-bottom: 0;
}

.bonds_row {
  display: flex;
  align-items: baseline;
}
@media screen and (orientation: portrait){
  .bonds_row {
      flex-direction: column;
    }
  .bonds_col__checkbox .input__label, .bonds_col__title {
      font-size: 1rem;
    }
  .bonds_investments {
    overflow: visible;
  }
  .bonds_col__table {
      max-width: 100%;
    overflow-x: scroll;
    }
  #table-bonds_length {
    padding-top: 1.5rem;
    margin-right: 90px;
    }
  .btn--excel {
    position: relative;
    top: 32px;
    padding: 0;
  }
  .dh-switch {
      font-size: 1em;
    }
  }
.bonds_row_inner_table {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bonds_p-01 {
  padding: 0.1rem;
  position: relative;
}
.bonds_p-1 {
  padding: 1rem;
}
.bonds_row__issue_date .bonds_col {
  padding-top: 1px;
}
#freset {
  margin-top: 1rem;
}
#table-bonds_filter {
  display: none;
}
.bonds_col .form__input {
  margin-bottom: 0.875rem;
}
.bonds_col-sm-5 {
  width: 40%;
}
.bonds_col-sm-7 {
  width: 60%;
}
.bonds_col-sm-12, .alert.description hr {
  width: 100%;
  height: 0;
}
.alert.description {
  padding: 1rem;
  background: #EDF1F2;
}
.alert.description p {
  margin-bottom: 0.5rem;
}
.bonds_col .input__input {
  padding: .2rem .8rem .2rem .8rem;
}
.bonds_col__title {
  margin-bottom: 1rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  font-size: 0.8em;
}
.bonds_filter__collapse strong {
  font-size: 0.8em;
}
.bonds_search .bonds_col__title:after, .bonds_search .bonds_col__title:before {
  display: none;
}
.bonds_col__title:after, .bonds_col__title:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.25rem;
  width: 10px;
  height: 2px;
  background-color: #000;
  -webkit-transform: translateY(-50%) translateZ(0);
  transform: translateY(-50%) translateZ(0);
  -webkit-transition: all .4s cubic-bezier(.33,0,0,1);
  -o-transition: all .4s cubic-bezier(.33,0,0,1);
  transition: all .4s cubic-bezier(.33,0,0,1);
}
.bonds_col__title:after {
  -webkit-transform: translateY(-50%) translateZ(0) rotate(90deg);
  transform: translateY(-50%) translateZ(0) rotate(90deg);
}
.bonds_col__title.active:before {
  -webkit-transform: translateY(-50%) translateZ(0) rotate(-180deg);
  transform: translateY(-50%) translateZ(0) rotate(-180deg);
}
.bonds_col__title.active:after {
  opacity: 0;
  -webkit-transform: translateY(-50%) translateZ(0) rotate(0);
  transform: translateY(-50%) translateZ(0) rotate(0);
}
.bonds__content {
  padding-left: 0 !important;
}
table.dataTable#table-bonds>tfoot>tr>th:first-child,
table.dataTable#table-bonds>thead>tr>th:first-child {
  visibility: hidden;
}
.btn--excel {
  float: right;
  top: 27px;
  position: relative;
  z-index: 2;
  background: none;
}
.btn--small.btn--excel {
  padding: .375rem .725rem;
}

#table-bonds_wrapper {
  padding: 0 10px;
}
td.details-control {
  background: url("/assets/images/dataTables-details_open.png") center center no-repeat;
}
.details td.details-control {
  background: url("/assets/images/dataTables-details_close.png") center center no-repeat;
}

table.dataTable thead .sorting_asc .bonds__column-arrows .bonds__column-arrow:last-child,
table.dataTable thead .sorting_desc .bonds__column-arrows .bonds__column-arrow:first-child {
  visibility: hidden;
}
.disclamer_bonds {
  font-size: 0.8rem;
}
.bonds_buy {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 11px;
}
.bonds_buy {
  font-size: 0.7em;
}
#freset__table {
  position: relative;
  top: 30px;
  left: 10px;
  z-index: 2;
}
.content-header.analytics_header {
  padding-bottom: 1rem;
}

button[disabled] {
  background: $grey;
}
