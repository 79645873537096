.analytic-research {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;
  max-width: 100%;

  @include respond-min(tablet) {
    /*padding-bottom: 6.375rem;*/
    padding-bottom: 5rem;
  }
}

.analytic-research__title {
  margin-bottom: 2.3rem;

  @include respond-min(tablet) {
    margin-bottom: 1.8rem;
    margin-top: 1.1rem;
    text-transform: uppercase;
    letter-spacing: .02em;
  }
}

.analytic-research__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 2fr 1fr;
    row-gap: 2rem;
    column-gap: 5rem;
  }
}

.analytic-research__item {
  .articles {
    margin-top: .9rem;
    margin-bottom: 0;
  }
  .tabs-container-flex {
    padding-right: 1.5rem; 
    max-width: 100%;
  }
}
.analytic-research__item {
  &--first {
    margin-top: 5rem;

    @include respond-min(tablet) {
      grid-row: auto;
      margin: 0;
    }

    .article__header {
      .article__type {
        text-align: end;
        width: 100%;
      }
    }
    .article__content {
      width: 100%;
    }
  }
  
  &--search {
    margin-top: 5rem;
    grid-column: span 2;
    & .form__inner {
      width: 100%;
      & .form__input {
        width: 100%;
      }
    }
    @include respond-min(tablet) {
      margin: 0;
    }
  }

  .form {
    align-items: flex-end;
    //.input__input {
    //  padding: .6rem 1.5rem;
    //  height: 2.9rem;
    //}
  }

  .btn {
    padding: 1.2rem 2.6rem;
    @include respond-min(tablet) {
      padding: 1rem 3rem;
    }
  }
}

.analytic-research__buttons {
  display: flex;
  .btn:not(:last-child) {
    margin-right: 2rem;
  }
}
.analytic_search {
  height: 40px !important;
  margin: 0px !important;
  padding: 0px;
  box-shadow: none !important;
  border-radius: 7px !important;
  &_input {
    height: 40px !important;
    background: #F0F2F5;
    border-radius: 7px !important;
    padding: .9rem 1.25rem !important;
    &::placeholder {
      color: $black-color1;
      opacity: 1;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      
    }
  }
  &_button {
    height: 40px !important;
    width: 120px;
    border-radius: 7px !important;
  }
}
.simplesearch-search-form {
  .page--analytic-research & {
    margin: 0px !important;
    padding: 0px !important;
    border-radius: 7px !important;
    background: #f0f2f5;
  }
}
.simplesearch-highlight {
  .page--analytic-research & {
    font-style: inherit;
    text-decoration: none;
  }
}
.simplesearch-results {
  .page--analytic-research & {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0;
  }
}
.search-no-res {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 0;
  text-align: left;
}
.content-header__title {
  .page--analytic-research & {
    margin-bottom: 1.25rem;
  }
}
.analytic-form__content {
  margin-top: 1.25rem;
  margin-bottom: 2.5rem;
  @include respond-min(tablet) {
    margin-bottom: 1.25rem;
  }
}
.year_filters {
  display: flex;
  margin-bottom: 2.75rem;
  @include respond-max(tablet) {
    margin-bottom: 3.75rem;
  }
  .analytic_years {
    background: rgba(245, 245, 245, 0.8);
    margin-right: .625rem;
    padding: 6px 12px;
    border-radius: 30px;
    list-style-type: none;
    &.active, &:hover {
      background: rgba(105, 113, 127, 0.2);
    }
  }
}

.flex_table.flex_table--analytic-research {
  display: none;
  &.active {
    display: flex;
  }
  margin-bottom: 4rem;
  margin-top: 4rem;
  @include respond-min(tablet) {
    margin-bottom: 2rem;
    margin-top: 0;
  }
  .flex_table__row {
    .flex_table__cell {
      @include respond-min(mobile-ml) {
        width: 100%;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      //width: 100%;
      @include respond-max(tablet) {
        a span.only-mobile {
          display: initial !important;
        }
      }
      @include respond-min(tablet) {
        a span.only-desktop {
          display: initial !important;
        }
      }
      a span:hover {
        border-bottom: 1px solid;
        border-bottom-color: inherit;
        padding-bottom: 0.3rem;
        display: initial!important;
      }
    }
  }
  .flex_table__row:hover flex_table__cell {
    color: $blue;
  }
  .flex_table__head .flex_table__cell {
    padding: 0.8rem 0;
  }
}