.slider-dots {
  display: flex;
  flex-wrap: wrap;
}

$circle-radius: 25;

.slider-dots__dot {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  padding: 1rem;
  color: currentColor;
  border: 0;
  background-color: transparent;
  opacity: 0.5;
  cursor: pointer;
  transition: $trs;

  svg {
    width: 2rem;
    height: 2rem;
  }

  circle {
    fill: transparent;
    stroke: currentColor;
    stroke-dasharray: get-dash-value($circle-radius, 100%);
    stroke-width: 50;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);

    .swiper-container:active .slider-dots &,
    .slider-dots:active & {
      animation: none;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;

    circle {
      animation-name: circle-animation;
      animation-duration: 6s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }

  @include respond-min(tablet) {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.65rem;
    margin-right: 0.875rem;
    padding: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &.active {
      circle {
        animation-duration: 9s;
      }
    }

  }
}

@keyframes circle-animation {
  0% {
    stroke-dashoffset: get-dash-value($circle-radius, 0%);
  }

  100% {
    stroke-dashoffset: get-dash-value($circle-radius, 100%);
  }
}
