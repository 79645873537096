.fond {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 139px;
  height: 139px;
  padding: 12px;

  cursor: pointer;
}

.fond::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  width: 100%;
  height: 100%;

  //background: linear-gradient(12.26deg, rgba(11, 31, 43, 0.8) 6.36%, rgba(0, 0, 0, 0.096) 115.69%);
}

.fond::after {
  content: '';

  position: absolute;
  z-index: 0;

  width: 95%;
  height: 95%;
  margin: auto;

  border: 1.5px solid #AD033B;

  transition: .3s all;
}

.fond:hover::after {
  width: calc(100% + 14px);
  height: calc(100% + 14px);
}

.fond__image, .fond__image picture img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  object-fit: cover;
}

.fond__text {
  position: relative;
  z-index: 3;

  margin-top: auto;

  @include font(m);
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 0.571429px;
  color: #FFFFFF;
}
