.analytic-services {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  @include respond-min(tablet) {
    padding-bottom: 6.375rem;
  }
}

.analytic-services__title {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 1.8rem;
    margin-top: 1.1rem;
    text-transform: uppercase;
    letter-spacing: .02rem;
  }
}

.analytic-services__text {
  @include respond-min(tablet) {
    margin-right: 20rem;
  }
}

.analytic-services__slider {
  overflow: visible;
  @include respond-min(tablet) {
    .page--main & {
      align-self: center;
      width: auto;
    }
  }
}

.analytic-services__slider-wrapper {
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(2,1fr);

  @include respond-min(tablet) {
    grid-template-columns: repeat(5, 1fr);
  }
  @include respond-max(mobile-md) {
    grid-template-columns: repeat(1,1fr);
  }
}

.analytic-services__slide {
  padding: 2.2rem;
  text-decoration: none;
  color: #000;
  transition: all .5s;


  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.02, 1.02);
  }

  @include respond-min(tablet) {
    width: auto;
    margin-right: 0;
    padding-top: 3.7rem;
    padding-bottom: 1.6rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.analytic-services__image {
  align-self: flex-start;
  margin-bottom: 0.6rem;
  height: auto;
  @include respond-max(mobile-md) {
    justify-content: center;
  }

  svg {
    width: 14rem;
    height: 9.75rem;
  }

  &--small-width {
    width: 4.8rem;
    @include respond-max(mobile-md) {
      width: auto;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 5rem;

    svg {
      width: 8.5rem;
      height: 6.5rem;
    }
  }
}

.analytic-services__slide-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  @include font('m');
  min-height: 5.5rem;
  margin-bottom: 0.6rem;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0;

  @include respond-max(mobile-md) {
    justify-content: center;
  }

  @include respond-min(tablet) {
    min-height: 2.8rem;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    line-height: 1.19;
  }
}