.news {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  &--single {
    margin-bottom: 0;
    padding-top: 5rem;
    padding-bottom: 7.5rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 6.375rem;

    &--single {
      margin-bottom: 0;
      padding-top: 9.375rem;
    }
  }
}

.news__header {
  position: relative;
  margin-bottom: 5rem;
  height: 50px;
  //margin-top: 5rem;

  @include respond-min(tablet) {
    margin-bottom: 7.0125rem;
    //margin-top: 7.0125rem;
    //margin-left: 15.3rem;

    .news--single & {
      margin-bottom: 6.25rem;
    }
  }
}

.news__caption {
  margin-bottom: 1.25rem;

  @include respond-min(tablet) {
    margin-bottom: 1.1475rem;
  }
}

.news__arrows {
  @include respond-min(tablet) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.news__slider {
  overflow: visible;
  margin-bottom: 7.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 4.303125rem;

    .news--single & {
      margin-left: 18rem;
    }
  }
}

.news__slider-wrapper {
  @include respond-min(tablet) {
    flex-wrap: nowrap;
  }
}

.news__slide {
  flex-direction: row;
  margin-right: $offset-mobile;
  padding-top: 0;
  padding-bottom: 0;

  &:last-of-type {
    margin-right: 0;
  }

  @include respond-min(tablet) {
    width: 30.4%;
    // max-width: 24rem;
    margin-right: 3.125rem;
    padding-bottom: 0;

    .news--single & {
      width: 100%;
    }
  }
}

.news__btn {
  min-width: 18.75rem;

  @include respond-min(tablet) {
    min-width: 12.375rem;
    margin-left: 18rem;
  }
}
