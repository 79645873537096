.reports {
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
}

.reports__tabs {
  display: flex;
  align-items: flex-start;
}

.reports__tab {
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;


  &.active {
    display: flex;
    animation: fadeIn 1s forwards;
  }

  @include respond-min(tablet) {
    padding-left: 0;
    padding-right: 0;
  }
}

.reports__block {
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.reports__block-header {
  position: relative;
  margin-bottom: 4.25rem;

  @include respond-min(tablet) {
    margin-bottom: 3.5rem;
  }
}

.reports__title {
  position: relative;

  @include respond-max(tablet) {
    select {
      padding: 0;
      padding-right: 24px;
      display: inline-block !important; /* stylelint-disable-line declaration-no-important */
      border: 0;
      background-color: transparent;
      background-image: url('../../img/i-arrow-accordion.svg');
      background-repeat: no-repeat;
      background-position: right center;
      cursor: pointer;
      appearance: none;

      option {
        &:disabled {
          color: $d-grey;
        }
      }
    }
  }

  @include respond-min(tablet) {
    button {
      position: relative;
      z-index: 2;
      display: inline-block !important; /* stylelint-disable-line declaration-no-important */
      padding: 0;
      border: 0;
      background-color: transparent;
      white-space: nowrap;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: 1.5rem;
        height: 0.75rem;
        vertical-align: middle;
      }
    }
  }
}

.reports__years {
  @include respond-min(tablet) {
    position: absolute;
    z-index: 1;
    top: calc(var(--reports-top) + 1rem);
    left: var(--reports-left);
    display: flex;
    flex-direction: column;
    max-height: 27rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    transform-origin: 50% 0;
    transition-property: opacity, transform;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    box-shadow: 0 2rem 2rem rgba(17, 31, 38, 0.24);
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0.75rem 0.75rem 0.75rem;
      border-color: transparent transparent $white transparent;
      transform: translateX(-50%);
    }

    &.active {
      opacity: 1;
      transform: translateX(-50%) scale(1);
      pointer-events: all;
    }
  }
}

.reports__years-items {
  @include respond-min(tablet) {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.reports__year {
  @include respond-min(tablet) {
    padding: 0.625rem 1.5rem;
    border: 0;
    background-color: transparent;
    transition-duration: 0.1s;

    &:disabled {
      color: $d-grey;
    }

    &:hover:not(:disabled) {
      color: $white;
      background-color: $red;
    }
  }
}
