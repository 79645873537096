.products {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &--person {
    margin-bottom: 10rem;
  }

  &--single {
    margin-bottom: 10rem;
  }

  &--buy {
    padding-top: 0;
    padding-bottom: 5rem;
  }

  .page--main & {
    margin-bottom: 8rem;
    padding-bottom: 7rem;
  }

  .page--personals &,
  .page--product & {
    //margin-bottom: 10rem;

    &--buy {
      margin-bottom: 0;
    }
  }

  .page--analytic-index & {
    margin-bottom: 0;
    padding-bottom: 5rem;
    @include respond-min(tablet) {
      padding-bottom: 0;
    }
  }

  .page--personals.page--du & {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    &--person {
      margin-bottom: 0;
      padding-bottom: 9.375rem;
    }

    &--single {
      margin-bottom: 9.375rem;
    }

    &--buy {
      padding-bottom: 5.125rem;
    }

    .page--main & {
      margin-bottom: 6.375rem;
      padding-bottom: 6.375rem;
    }

    .page--personals & {
      margin-bottom: 0;
    }

    .page--personals.page--du & {
      margin-bottom: 0;
    }

    .page--product & {
      margin-bottom: 9.375rem;

      &--buy {
        margin-bottom: 0;
        padding-bottom: 5.125rem;
      }
      &--buy.product--scrolled {
        transition: all .4s ease;
        top: 1.5rem;
        min-height: 1rem;
        background: #fdfdfd;
        position: sticky;
        z-index: 2;
        padding-bottom: 1rem;
        border-bottom: 1px solid #f3f3f3;

        .products__chart-result {
          font-size: 2rem;
          margin-top: 0.55rem;
          margin-bottom: 0;
          transition: all .2s ease;
        }
      }
      &--buy.product--scrolled.scrolled-max {
        top: 4.3rem;
      }
    }
  }
}

.products__mobile {
  display: flex;

  @include respond-min(tablet) {
    display: none !important;
  }
}

.products__catalog {
  margin-bottom: 5rem;
}

.products__tabular {
  padding-top: 3rem;
  padding-bottom: 3rem;

  &--ext-padding {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.products__header {
  display: flex;
  flex-direction: row;
  margin-bottom: 3.75rem;

  .products__tabs {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
  }

  @include respond-min(tablet) {
    margin-bottom: 3.825rem;
    margin-left: 18rem;

    .page--personals & {
      margin-bottom: 3.125rem;
      margin-left: 0;
    }
  }
}

.products__caption {
  margin-bottom: 1rem;

  @include respond-min(tablet) {
    margin-bottom: 1.1475rem;
  }
}

.products__subtitle {
  position: relative;
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &--tdn {

    &:hover {
      text-decoration: none;
    }
  }
  .amount {
    color: $black;
  }
}

.products__slider {
  overflow: visible;

  .page--main & {
    margin-bottom: 5rem;
  }

  .page--personals & {
    margin-bottom: 7.5rem;

    &:last-of-type {
      //margin-bottom: 5rem;
      margin-bottom: 0;
    }
  }

  @include respond-min(tablet) {
    width: auto;

    .page--personals & {
      width: 100%;
    }
  }
}

.products__slider-wrapper {
  @include respond-min(tablet) {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond-min(desktop-md) {
    .page--personals & {
      // grid-template-columns: repeat(auto-fit, minmax(25rem, 25rem));
    }
  }
}

.products__slide {
  display: flex;
  flex-direction: column;
  margin-right: $offset-mobile;
  padding-bottom: 3rem;

  .products--person & {
    overflow: hidden;
    padding-bottom: 2rem;
  }

  .products--buy & {
    padding-bottom: 0;
  }
  .opif-tiker {
    font-family: 'Roboto Medium', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    margin-top: 10px;
    line-height: 1.2;
    letter-spacing: 0.02em;
    position: relative;

    @include respond-min(tablet) {
      font-size: 1.8275rem;
    }
    span {
      text-decoration: underline dotted;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .help-box {
    background-color: $white;
    border: 1px solid $black;
    border-radius: 3px;
    position: absolute;
    top: calc(100% + 3px);
    left: 20%;
    color: $black;
    font-size: 14px;
    padding: 2px 10px;
    z-index: 9;
    transition: opacity .5s linear, visibility .5s linear;
    opacity: 1;
    word-break: normal;

    &-fadeout {
      transition: opacity .5s linear, visibility .5s linear;
      opacity: 0;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  @include respond-min(tablet) {
    width: auto;
    // max-width: 25rem;
    margin-right: 0;
    padding-bottom: 1.7rem;

    .products--person & {
      flex-direction: row;
      min-height: 18.75rem;
      margin-left: 18rem;
      padding-top: 3.125rem;
      padding-bottom: 1.875rem;
      padding-left: 3.125rem;
      padding-right: 3.125rem;
    }

    .products--buy & {
      margin-left: 18rem;
    }
    .products--buy__aside & {
      margin-left: 0;
    }
  }

  @include respond-min(desktop-md) {
    .page--personals & {
      // max-width: none;
    }
  }
}

.products__image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 22.5rem;
  margin-bottom: 1.25rem;

  img {
    object-fit: cover;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    height: 20rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0 0 50vw $white;
    transition: $trs;
    pointer-events: none;
  }

  &:hover {
    &::before {
      width: 40rem;
      height: 40rem;
    }
  }

  .products--person & {
    overflow: visible;
    align-self: center;
    width: 35rem;
    height: 35rem;
    margin-bottom: 0;

    img {
      object-fit: contain;
    }

    &::before {
      width: 25rem;
      height: 25rem;
    }

    &:hover {
      &::before {
        width: 56rem;
        height: 56rem;
      }
    }
  }

  @include respond-min(tablet) {
    height: 14.34375rem;
    margin-bottom: 1.7rem;

    &::before {
      width: 13.28125rem;
      height: 13.28125rem;
    }

    .products--person & {
      align-self: flex-start;
      width: 12.5rem;
      height: 12.5rem;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 3.125rem;

      &::before {
        width: 12.5rem;
        height: 12.5rem;
      }

      &:hover {
        &::before {
          width: 20rem;
          height: 20rem;
        }
      }
    }
  }

  @include respond-min(desktop-xlg) {
    &:hover {
      &::before {
        width: 33vw;
        height: 33vw;
      }
    }

    .products--person & {
      &:hover {
        &::before {
          width: 20rem;
          height: 20rem;
        }
      }
    }
  }
}

.products__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 1.875rem;
  margin-right: 1.875rem;

  .products--buy & {
    margin-left: 0;
    margin-right: 0;
  }

  @include respond-min(tablet) {
    .products--person & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.products__slider-caption {
  margin-bottom: 1.25rem;

  @include respond-min(tablet) {
    margin-bottom: 0;
    line-height: 1;
    font-size: 8px;
  }

  @include respond-min(desktop) {
    font-size: 9px;
  }
}

.products__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  border-top: 2px solid $black;
  border-bottom: 1px solid $black;

  .products--person & {
    margin-bottom: 2rem;
    padding-top: 2.5rem;
  }

  .products--buy & {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .products__slide--chart & {
    border-bottom: 0;
    position: relative;
    min-height: 16em;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.7rem;
    padding-bottom: 1.875rem;

    .products--person & {
      margin-bottom: 1.5rem;
      padding-top: 1.875rem;
    }

    .products--buy & {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }

    .products__slide--chart & {
      padding-bottom: 0.85rem;
      border-bottom: 1px solid $black;
    }
  }

  p {
    font-size: 1.625rem;
    line-height: 1.4;
    letter-spacing: 0.02em;

    .products--person & {
      @include text('t-small');
    }

    .products__slide--chart & {
      display: none;
    }

    @include respond-min(tablet) {
      font-size: 0.875rem;
      line-height: 1.4;

      .products__slide--chart & {
        display: block;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.products__slider-title {
  margin-bottom: 2rem;

  @include respond-min(tablet) {
    margin-bottom: 0.85rem;
    min-height: 2.975rem;
  }
}

.slider-main__slide {
  .products__chart-block {
    position: absolute;
    bottom: 0;
  }
}

.products__info {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 3.7rem;
  padding-top: 1.3rem;

  border-bottom: 2px solid $d-grey;
  position: relative;


  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, .2);
  }

  @include respond-min(tablet) {
    display: grid;
    grid-template-columns: 21.3rem 3fr;
    padding-bottom: 3.7rem;
    padding-top: 1.3rem;
  }

  .products__info-row {
    display: flex;
    flex-direction: row;
    @include respond-max(mobile-ml) {
      display: flex;
      flex-direction: column;
      padding: 1.6rem 0;
      border-bottom: 1px $d-grey solid;
    }

    .products__info-label,
    .products__info-value {
      font-weight: 700;
      font-size: 1.5rem;
      letter-spacing: .08em;
      line-height: 2.1rem;
      padding: 0.54rem 0;
    }

    .products__info-label {
      width: 50%;
      text-transform: uppercase;
    }

    .products__info-value {
      font-size: 1.5rem;
    }

    @include respond-min(tablet) {
      .products__info-label,
      .products__info-value {
        font-weight: 700;
        font-size: .7rem;
        letter-spacing: .08em;
        line-height: 1rem;
        padding: 0.54rem 0;
      }

      .products__info-label {
        width: 11rem;
        text-transform: uppercase;
      }

      .products__info-value {
        font-size: .9rem;
      }
    }
  }
}

.products__info-block:last-child {
  .products__info-row:last-child {
    border-bottom: none;
  }
}

.products__addinfo {
  display: grid;
  grid-template-columns: 3fr 4fr 2fr;
  justify-items: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 2.5rem;


  @include respond-min(tablet) {
    margin-bottom: 1.7rem;
    padding-bottom: 1.875rem;
    padding-top: 2rem;
    grid-template-columns: 18.2rem 3fr 2fr;
  }

}

.products__addinfo-btn {
  margin-right: 1rem;

  .btn__text {
    font-size: 1.1rem;
    width: max-content;

    @include respond-min(tablet) {
      font-size: 0.8rem;
      width: auto;
    }
  }
}

.products__chart-block {
  position: relative;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  @include respond-min(tablet) {
    min-height: 2.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .products--buy & {
      width: 20rem;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.products__cost-block {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: center;
  }

  .products__cost-cell {
    display: flex;
    flex-direction: row;
    JUSTIFY-CONTENT: flex-end;
    white-space: nowrap;
    margin-right: 0;
    margin-bottom: 3rem;
    @include respond-min(tablet) {
      margin-bottom: 0;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }

    .products__cost-wrapper {
      min-width: 15.8rem;
      margin-right: 0.8rem;
      align-items: flex-end;
      justify-content: flex-end;
      @include respond-min(tablet) {
        min-width: auto;
        margin-right: 0;
      }
    }

    .products__cost-title {
      color: $d-grey;
      font-size: 1.1rem;
      font-weight: bold;
      align-items: flex-end;
      display: flex;
      @include respond-min(tablet) {
        font-size: .8rem;

      }
    }

    .products__cost-value {
      font-size: 2.2rem;
      margin-top: 1.7rem;
      padding-left: 2.2rem;
      line-height: 1;

      @include respond-min(tablet) {
        font-size: 1.9rem;
        line-height: 2.3;
        padding-left: 0;
        margin-top: 0;
      }
      .value-icon {
        margin-left: 0;
        position: relative;
        display: block;

        @include respond-min(tablet) {
          margin-left: 2rem;
        }

        &::before {
          color: $green;
          content: '';
          position: absolute;
          top: 50%;
          right: calc(100% + 0.625rem);
          width: 0;
          height: 0;
          transform: translateY(-50%);
          border-style: solid;
          border-width: 0 0.7rem 1.275rem;

          border-color: transparent transparent currentColor transparent;
          z-index: 1;
        }

        &--loss:before {
          right: calc(100% + 1.25rem);
          transform: translateY(-50%) rotate(180deg);
          color: $red;
        }

      }

    }


  }
}

.products__chart-result {
  @include font('l');
  margin-right: 1.375rem;
  font-size: 3.75rem;
  line-height: 1.57;
  letter-spacing: 0.02em;

  @include respond-min(tablet) {
    margin-right: 0.5rem;
    //font-size: 2.8125rem;
    font-size: 1.8275rem;
    line-height: 1.05;

    .products--buy & {
      margin-bottom: 0.5rem;
      margin-right: 0;
      font-size: 4.0625rem;
      white-space: nowrap;
    }
  }
}

.products__chart-period {
  font-size: 1.25rem;
  line-height: 1.465;
  letter-spacing: 0.02em;
  color: $text-color;

  @include respond-min(tablet) {
    @include font('m');
    font-size: 0.8rem;
    line-height: 1.172;
  }
}

.products__chart {
  position: absolute;
  z-index: -1;
  max-width: 100%;
}



.products-profit__block {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: 2rem;
  margin-right: auto;
  align-items: flex-start;
  max-width: 20rem;

  @include respond-min(tablet) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.products-profit__result {
  @include font('l');
  margin-right: 1.375rem;
  font-size: 3.75rem;
  line-height: 1.57;
  letter-spacing: 0.02em;
  white-space: nowrap;

  @include respond-min(tablet) {
    margin-right: 0.5rem;
    //font-size: 2.8125rem;
    font-size: 1.8275rem;
    line-height: 1.05;

    .products--buy & {
      margin-bottom: 1.25rem;
      margin-right: 0;
      font-size: 4.0625rem;
    }
  }
}

.products-profit__period {
  font-size: 1.25rem;
  line-height: 1.465;
  letter-spacing: 0.02em;
  color: $text-color;

  @include respond-min(tablet) {
    @include font('m');
    font-size: 0.8rem;
    line-height: 1.172;
  }
}


.products__slider-btn {
  @include respond-min(tablet) {
    margin-left: auto;
    margin-right: auto;

    .products--person & {
      margin-top: auto;
      margin-left: -0.25rem;
    }
  }
}

.products__footer {
  display: flex;
  flex-direction: column;
  //margin-bottom: 5rem;

  @include respond-min(tablet) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    margin-left: 18rem;
    //margin-bottom: 7rem;

    & > * {
      margin-right: 3.75rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.products__more-button {
  margin: 0 1.875rem;
}

.products__btn {
  justify-content: center;
  min-width: 20rem;
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    min-width: 13.75rem;
    margin-bottom: 0;

    .products--buy & {
      align-self: flex-end;
    }
  }
}

.products__link {
  position: relative;
  align-self: flex-start;
  margin-bottom: 2.5rem;
  padding-right: 4rem;
  transition: $trs;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.4;
    transform: scale(1.02);
    text-decoration: underline;
  }

  @include respond-min(tablet) {
    align-self: auto;
    margin-bottom: 0;
    padding-right: 2rem;
  }
}

.products__link-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1.75rem;
  height: 1.125rem;

  @include respond-min(tablet) {
    width: 0.875rem;
    height: 0.5625rem;
  }
}

.products__actual {
  width: 32.5rem;
  padding-bottom: 1.7rem;
  margin-right: 3rem;

  @include respond-min(tablet) {
    width: 23rem;
    margin-right: 0;
  }
  .alternative-funds-block & {
    display: none;

    @include respond-min(tablet) {
      margin-right: 2rem;
    }
    &.active-product {
      display: flex;
    }
  }

  .products__actual-chart-block {
    //position: absolute;
    flex-direction: column;
    top: 10px;
    right: 10px;
    bottom: auto;
    z-index: 1;
    //background: rgba(0, 0, 0, .4);
    border-radius: 5px;
    padding: 7px 12px;
    line-height: unset;
    margin-top: auto;

    .products__actual-chart-result {
      font-size: 2rem;
      color: #fff;
    }
    .products__actual-chart-year {
      text-align: right;
      color: #fff;
      font-size: 1.2rem;
    }
  }
  .products__actual-content {
    display: flex;
    flex-direction: column;
    min-height: 40rem;
    padding: 2rem 0 2rem 0;
    margin-bottom: 1.7rem;
    border-bottom: 1px solid #000;
    border-top: 2px solid #000;

    @include respond-min(tablet) {
      position: relative;
      min-height: 18rem;
    }

    .products__text {
      font-size: 1.625rem;
      line-height: 1.4;
      letter-spacing: .02em;

      @include respond-min(tablet) {
        font-size: .875rem;
        line-height: 1.4;
        letter-spacing: .02em;
      }
    }
  }
}
.micromodal-slide {
  .content-table {
    td {
      display: table-row;

      @include respond-min(tablet) {
        display: table-cell;
      }
    }
  }
}

.content__table.only-mobile {
  display: flex;
  flex-direction: column;

  .content__table-block {
    margin-bottom: 3.188rem;
  }
  .content__table-title {
    margin-bottom: 3.188rem;
    padding-left: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .content__table-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid black;
    font-size: 1.5rem;
  }
  .content__table-row:first-child {
    border-top: 1px solid #000;
  }
  .content__table-cell {
    line-height: 1.75rem;
  }
  .content__table-cell--value {
    color: #75808F;
    font-weight: 700;
    margin-left: 1rem;
  }
}
.content__table.only-desktop {
  table {
    thead {
      tr {
        height: 2.875rem;
        padding: 0;

        th {
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 1.313rem;
          color: #000;
        }
      }
    }
    tbody {
      tr {
        padding: 0;
        margin: 0;

        &:first-of-type {
          border-top: 1px solid #000000;
        }
        &:nth-last-of-type(-n+2) {
          td:nth-last-of-type(-n+2) {
            font-weight: 800;

            &.value {
              font-size: 1.125rem;
            }
          }
        }

        td {
          margin: 0;
          padding: 1.375rem 0;
          vertical-align: middle;

          &.value {
            width: 8%;
            text-align: center;
            color: #75808F;
            font-weight: 700;
          }
        }
      }
    }
  }
}
