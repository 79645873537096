.content-nav {
  position: fixed;
  background: rgba($white, 0.95);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  z-index: 1;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  border-bottom: 1px solid rgb(237, 241, 242);


  @include respond-min(tablet) {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    top: 10.6rem;
    left: 7.75rem;
    border-bottom: none;
  }

  @include respond-min(desktop) {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    background: none;
    padding-top: 0;
    padding-bottom: 0;
    top: 10.6rem;
    left: calc(50vw - #{$desktop} / 2 + 3.75rem);
    border-bottom: none;
  }

  &__list {
    display: flex;
    flex-direction: row;
    @include respond-min(tablet) {
      flex-direction: column;
    }
  }

  &__item {
    padding: .8rem;
    white-space: nowrap;
    width: auto;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    @include respond-min(tablet) {
      padding: .8rem 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__item:hover &__link {
    color: $red;
  }

  &__link {
    color: $d-grey;
    font-size: 1.4rem;
    text-decoration: none;
    @include respond-min(tablet) {
      font-size: .9rem;
    }
    cursor: pointer;
  }
  &_search {
    margin-right: 0.938rem;

    .lk-nav-ol__icon {
      @include respond-min(tablet) {
        width: 2.1rem;
        height: 2.1rem;
        padding: .5rem;
      }
    }
  }
}

.content-nav--hidden {
  opacity: 0;
}