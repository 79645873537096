.article {
  display: flex;
  flex-grow: 1;
}
.articles__btn {
  &.button_read_more {
    width: 165px !important;
    margin-left: calc(50% - 110px) !important;
    &:hover {
      text-decoration: none;
    }
    &.btn--fill {
      border-radius: 10px;
      border: 1px solid rgba(41, 45, 50, 0.3);
    }
    & .btn__text {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-transform: none;
    }
    @include respond-max(tablet) {
      margin: 0 !important;
      width: 100% !important;
    }
  }
}
.btn__text {
  .page--analytic-research &, .page--blog &, .page--news & {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-transform: none;
  }
}
.read_more_header {
  font-family: 'Montserrat', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: left;
  margin-bottom: 2.5rem;
  margin-top: 2.75rem;
  @include respond-max(tablet) {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.article__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  .articles__blog & {
    padding-top: 0.25rem;
    &:hover {
      padding-top: 0;
      padding-bottom: 0.25rem;
    }
  }
  &_container {
    padding: 1.25rem 1.75rem !important;
    border: 1px solid rgba(41, 45, 50, 0.1);
    border-radius: 15px;
    @include respond-max(tablet) {
      padding: 2.5rem !important;
    }
  }
}
.blog-nav {
  @include respond-max(tablet) {
    background: $grey-9;
    padding: 3rem;
    border-radius: 15px;
  }
}
.content-header__title {
  .articles__blog & {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 2.75rem;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 39px;
    text-align: left;
    margin-left: 0;
    margin-top: 0;
  }
}
.categories_filter {
  margin-bottom: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;

  @include respond-max(tablet) {
    margin-bottom: 0;
  }
  .categories_list {
    padding-right: 3rem;
    @include respond-max(tablet) {
      padding-right: 0;
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @include respond-max(tablet) {
      font-size: 13px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      margin-top: 2.25rem;
    }
  }
  .categories__link {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $black-color1;
    margin-bottom: 1.25rem;
    &_active {
      color: $red;
    }
    & div.media-obj {
      height: 18px;
      width: 18px;
      @include respond-max(tablet) {
        margin-right: 1rem;
      }
    }
    &_title {
      width: 100%;
      padding-left: 1.25rem;
      padding-right: 2rem;
    }
    &:hover {
      color: $red;
    }
    @include respond-max(tablet) {
      margin-bottom: 2.25rem;
    }
  }
}
.article__link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: currentColor;
  @include respond-max(tablet) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    .article_more &, .articles__blog & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

progress {
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  color: $red;
  z-index: 1;

  @include respond-min(tablet-sm) {
    top: 62px;
  }

  @include respond-min(desktop-sm) {
    top: 78px;
  }

  @include respond-min(desktop-lg) {
    top: 80px;
  }
}
progress::-webkit-progress-bar {
  background-color: transparent;
}

progress::-webkit-progress-value {
  background-color: $red;
}

progress::-moz-progress-bar {
  background-color: $red;
}

.article__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  .page--analytic-research &, .page--blog &, .page--news & {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    text-align: left;
    text-transform: none !important;
    align-items: flex-end;
    & time {
      font-weight: 700;
    }
    & .article__type {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 80px);
    }
  }
  .article_more & {
    flex-direction: column;
  }
  & .second_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @include respond-max(tablet) {
    .page--analytic-research &, .page--blog &, .page--news & {
      margin-top: 1.25rem;
    }
  }
  @include respond-min(tablet) {
    margin-bottom: 1.125rem;
    .page--analytic-research &, .page--blog &, .page--news & {
      margin-bottom: .625rem;
    }
    .article_more & {
      margin-bottom: .625rem !important;
    }
  }
  & .more {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 0.5rem;
    border-radius: 10px;
    padding: 4px 10px 4px 10px;
    &_category {
      background: $pink1;
      width: fit-content;
    }
    &_reading {
      background: rgba(35, 222, 168, 0.14);
    }
    &_time {
      background: $grey-9;
    }
  }
}
.article__content__container {
  display: flex;
  @include respond-max(tablet) {
    flex-direction: column;
  }
}
.clr-black1 {
  opacity: 0.4;
}
.socials_container {
  position: relative;
}
.article__stats {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
  & div.media-obj {
    width: 18px;
    height: 18px;
    margin-right: 0.75rem;
  }
  span:not(.media-obj):not(.xlike__icon) {
    margin-right: 0.75rem;
    font-size: 12px !important;
    @include respond-max(tablet) {
      font-size: 12px !important;
    }
  }
  & .xlike__icon {
    width: 18px;
    height: 18px;
    margin-right: 0.75rem;
  }
}
.send-list {
  display: none;
  flex-direction: column;
  position: absolute;
  right: calc(-20px - .75rem);
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1.25rem;
  background: $white;
  &.active {
    display: flex;
  }
  & .info-content__social {
    margin-bottom: 1rem;
    :hover {
      color: $red;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.disclaimer {
  li {
    padding-left: 0 !important;
    &::before {
      display: none;
    }
    &.active {
      & .accordion__title_arrow {
        transform: none;
      }
    }
  }
  & .accordion__title {
    background: $grey-9;
    margin-left: 0 !important;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    justify-content: space-between;
    padding: 1.25rem !important;
    border-radius: 8px;
    align-items: center;
    &::before, &:after {
      display: none;
    }
    &_arrow {
      transform: rotate(180deg);
      & .main-nav__link-triangle-red {
        margin-left: 0;
        height: 20px;
        width: 20px;
      }
    }
  }
  & .accordion__content {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    padding: 1.25rem;
    padding-bottom: 0;
    p {
      margin-bottom: 1.25rem !important;
    }
  }
  .articles__blog & {
    padding-left: 0 !important;
  }
}
.back_button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 2.5rem;
  & div.media-obj {
    width: 18px;
    height: 18px;
    margin-right: 1.25rem;
  }
}
.info-content {
  &__content {
    text-align: left;
    & p {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px !important;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      margin-bottom: 1.25rem !important;
    }
    & a {
      color: $red;
      text-decoration: none !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600 !important;
      font-size: 15px !important;
      line-height: 24px;
      letter-spacing: 0em;
    }
    & div:not(.inner_faq):not(.inner_form):not(.form-header):not(.form-b__content):not(.form-group):not(.form__success-content-wrapper):not(.form-success__content):not(.form-success__logo):not(.form-success__title):not(.form-success__text) {
      margin: 0;
      margin-bottom: 1.25rem !important;
      padding: 8px 0 8px 20px;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px !important;
      font-weight: 400;
      font-style: italic;
      line-height: 24px;
      border-left: 3px solid #000;
    }
    & h2 {
      font-family: 'Montserrat', sans-serif !important;
      font-size: 26px !important;
      font-weight: 700 !important;
      line-height: 26px !important;
      letter-spacing: 0.02em !important;
      text-align: left;
      text-transform: none !important;
      margin-bottom: 26px !important;
    }
    & .inner_form {
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px;
      position: relative;
      box-shadow: 0 20px 80px -10px rgba($black,.1);
      min-height: 600px;

      .form-header {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: normal;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include respond-min(tablet-sm) {
          font-size: 24px;
          line-height: 30px;
        }

        @include respond-min(tablet) {
          font-size: 28px;
          line-height: 34px;
          margin-right: 30px;
        }

        p {
          margin: 0 !important;
        }
      }

      .form__form {
        padding: 0;
        width: 100%;

        .form-b__content {
          margin: 0;
        }
        .input-b:not(.input-b--checkbox) {
          background: $grey-8;
          border-radius: 10px;
          border: 0;
          padding: 5px 25px;

          input {
            padding: 1.5rem 1.5rem 1.5rem 0;
          }

          input, textarea, label {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            border-radius: 0;
          }

          &::after {
            font-size: 10px;
            line-height: 14px;

            @include respond-min(tablet) {
              font-size: 11px;
              line-height: 16px;
            }
          }
        }
        .input-b__input:-webkit-autofill {
          box-shadow: inset 0 0 0 2.75rem $grey-8!important;
        }
        .input--checkbox {
          & .input__label {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.04em;
            color: rgba($black-color1, 0.6);

            a {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 10px !important;
              line-height: 16px;
              letter-spacing: 0.04em;
              color: $black-color1;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
          & .input__label:before {
            @include respond-min(tablet) {
              height: 23px;
              width: 22px;
            }
          }
        }
        .input--checkbox.invalid {
          margin-bottom: 10.125rem;

          @include respond-min(tablet) {
            margin-bottom: 3.125rem;
          }
        }
        .input__input--checkbox:checked+.input__label:after {
          background: url('../../img/check.svg');
          background-size: cover;
          left: 0;
          width: 2.4rem;
          height: 2.5rem;

          @include respond-min(tablet) {
            height: 23px;
            width: 22px;
            left: 0;
            top: 0;
          }
        }
        .form-b__btn {
          border-radius: 8px;

          @include respond-max(tablet) {
            width: 100%;
          }
          .btn__text {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: $white;
          }
        }
      }

      .form__success-content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 80px);
        border-radius: 15px;

        .form-success__content {
          border-radius: 15px;
          max-height: 100%;
          padding: 20px 15px;
        }
        .form-success__header {
          border-radius: 15px 15px 0 0;
        }
        .form-success__title {
          font-family: 'Montserrat', sans-serif;
          order: 2;
          margin-top: 40px;
        }
        .form-success__logo {
          opacity: 0;
          padding: 0;
          height: 0 !important;
        }
      }
      .form-success__text {
        order: 1;
        margin: 0;
        padding: 0;

        @include respond-min(tablet) {
          margin: 0;
          padding: 0;
        }
        img {
          padding: 0 !important;
          margin: 5px 0 0 15px;
          width: fit-content;
          border: 0;
          max-width: 100%;
          
          @include respond-min(tablet) {
            margin: 0;    
          }
        }
        .btn__to-main-page {
          background: $red;
          border-radius: 8px;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          text-align: center;
          color: $white;
          padding: 15px 25px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  &.articles__blog {
    padding-top: 0 !important;
    padding-left: 1rem;
  }
  & .content-header__info {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 1.25rem;
    margin-top: 0;
    margin-left: 0;
    & .content-header__date {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  & .header_container {
    margin-bottom: 2.5rem;
    margin-right: 6.25rem;
  }
  & .article-header__title {
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0;

    @include respond-min(tablet-sm) {
      font-size: 33px;
      line-height: 39px;
    }

    @include respond-min(desktop-sm) {
      font-size: 40px;
      line-height: 46px;
    }
  }
}

.article__content__text {
  min-height: auto;
  .info-content & {
    h4 {
      margin-top: 3.25rem;
      margin-bottom: 4rem;
    }
  }
  .articles__blog & {
    padding: 0 1rem;
    overflow: hidden;
  }

  @include respond-min(tablet) {
    min-height: 285px;

    .page--analytic-research &, .page--blog &, .page--news & {
      min-height: 150px;
    }

    .info-content & {
      min-height: auto;
      h4 {
        margin-top: 0.75rem;
        margin-bottom: 1rem;
      }
    }
    .article_more & {
      min-height: auto;
      margin-bottom: 1.5rem;
    }
  }
}

.article__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 0.75rem;
  padding-bottom: 2.5rem;
  border-top: 3px solid currentColor;
  border-bottom: 1px solid $grey;
  .article_more & {
    border: 0;
    padding: 0 !important;
  }
  .page--analytic-research &, .page--blog &, .page--news & {
    &:not(.blog-news-article) {
      border: 0;
      padding-top: 0;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0px 22px 44px 0px rgba(25, 35, 54, 0.08);
      &:hover {
        box-shadow: 0px 22px 44px 0px rgba(25, 35, 54, 0.15);
      }
    }
  }
  .events & {
    border-top: 1px solid #626d7d;
    border-bottom: 1px solid #626d7d;
  }

  .article--single & {
    padding-top: 0;
  }

  @include respond-min(tablet) {
    padding-top: 0.625rem;
    padding-bottom: 1.75rem;

    .article--single & {
      padding-top: 0.625rem;
      padding-bottom: 2.5rem;
    }
  }
}

.article__image {
  margin-top: 1.125rem;
  .articles__blog & {
    margin-top: 0;
  }
  img {
    //max-height: 60vh;
    object-fit: cover;
    width: 100%;
  }

  @include respond-min(tablet) {
    margin-top: 0;
  }
}

.article__title {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0.02em;
  .page--analytic-research &, .page--blog &, .page--news & {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  .article_more & {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-top: 0 !important;
  }
  .events & {
    font-family: 'Roboto Regular',Arial,sans-serif;
    font-weight: 500;
    font-size: rem(35, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(35, $body-size-mobile);
    }
    @include respond-max(mobile-ml) {
      font-size: rem(25, $body-size-mobile);
      word-break: break-word;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-top: 0.875rem;
    margin-bottom: 1.25rem;
    letter-spacing: 0.02em;
  }
}

.article__text {
  font-size: 1.75rem;
  line-height: 1.4;
  letter-spacing: 0.02em;
  .page--analytic-research &, .page--blog &, .page--news & {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .article_more & {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .events & {
    font-family: 'Roboto Regular',Arial,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem(14, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(14, $body-size-mobile);
    }
  }

  @include respond-min(tablet) {
    font-size: 0.9375rem;
    .info-content & {
      margin-bottom: 0;
      height: 2.5rem;
      overflow: hidden;
    }
  }
}

.article__btn {
  margin-top: 2.5rem;

  @include respond-min(tablet) {
    margin-top: 1.625rem;
  }
}

.article__header-col {
  display: flex;
  flex-direction: column;
  &:last-child {
    align-items: flex-end;
    margin-top: 15px;
  }
}


.article__location,
.article__important {
  margin: 0.6rem 0 0;
}

.article__date {
  font-family: Roboto Bold,sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.375rem;
  line-height: 1.17;
  letter-spacing: .1em;
  text-transform: uppercase;
  @include respond-min(tablet) {
    font-size: .9rem;
    .page--analytic-research &, .page--blog &, .page--news & {
      font-size: .65rem;
    }
  }
}

.article__location {
  font-size: 1.375rem;
  .events & {
    font-size: rem(14, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(14, $body-size-mobile);
    }
  }
  @include respond-min(tablet) {
    font-size: .8rem;
  }
}
.blog-like {
  fill: none;
  stroke: $black-color1;
  opacity: 0.4;
  &_active {
    fill: $red;
    stroke: $red;
    opacity: 1;
  }
}
.articles__blog {
  width: 74%;
  @include respond-max(tablet) {
    width: 100%;
    margin-bottom: 0;
  }
  &__content {
    display: flex;
    &.blog_column {
      flex-direction: column;
    }
    padding-bottom: 4rem;
    @include respond-max(tablet) {
      display: initial;
    }
  }
  .the-tabs-item {
    margin-bottom: 6.25rem;
  }
}
.blog_menu {
  display: none;
  justify-content: space-between;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  & div.media-obj {
    width: 22px;
    height: 18px;
    margin-right: 2rem;
  }
  & .categories__menu_title {
    width: 100%;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  &.accordion__title {
    padding-right: 0 !important;
    &::before, &:after {
      display: none;
    }
    .active & .media-obj {
      transform: rotate(90deg);
      filter: none;
    }
    .main-nav__link-triangle-red {
      height: 14px;
      width: 14px;
      margin-left: 5px;
    }
  }
  @include respond-max(tablet) {
    display: flex;
  }
}
.reg_banner {
  background-image: url("../../../src/assets/img/reg_banner.png");
  min-height: 160px;
  padding: 60px 45px;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: 100%;
  margin-top: 0;
  margin-bottom: 1.25rem;
  border-radius: 8px;

  @include respond-max(tablet-sm) {
    background-image: url("../../../src/assets/img/mobile_reg_banner.png");
    background-position: top;
    padding: 230px 30px 45px;
  }
  & .content_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px !important;
    font-weight: 700;
    line-height: 31px !important;
    letter-spacing: 0em;
    text-align: left;
    width: 65%;
    margin-bottom: 1rem !important;

    @include respond-max(tablet-sm) {
      font-size: 18px !important;
      line-height: 25px !important;
      width: 100%;
    }
  }

  & .invitation_content {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

    @include respond-max(tablet) {
      font-size: 15px !important;
      line-height: 23px !important;
    }
    & .content_description {
      margin-bottom: 1rem;
    }
    & .content_button {
      width: fit-content;
      & p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px !important;
        font-weight: 600;
        line-height: 18px !important;
        letter-spacing: 0.01em;
        text-align: left;
        @include respond-max(tablet) {
          font-size: 13px !important;
          line-height: 17px !important;
        }
      }
      @include respond-max(tablet-sm) {
        width: 100%;
      }
    }
  }

}
.blog__invitation {
  background-image: url("../../../src/assets/img/banner_rocket.png");
  min-height: 160px;
  padding: 30px 45px;
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: 100%;
  margin-top: 0;
  margin-bottom: 1.25rem;
  border-radius: 8px;

  @include respond-max(tablet-sm) {
    background-image: url("../../../src/assets/img/banner_mob_rocket.png");
    background-position: bottom;
    padding: 20px 30px;
  }
  &.only-mobile {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  &__tg {
    background-image: url("../../../src/assets/img/banner_tg.png");

    @include respond-max(tablet-sm) {
      background-image: url("../../../src/assets/img/banner_mob_tg.png");
    }
  }
  &__sn {
    border-radius: 15px;
    background: #DFE8F1;
    margin-top: 6.25rem;
    @include respond-max(tablet-sm) {
      flex-direction: column;
      background: #dfe8f1;
    }
    & .content_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: left;
      @include respond-max(tablet) {
        font-size: 16px !important;
        line-height: 22px !important;
      }
    }
    & .invitation_content {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      @include respond-max(tablet) {
        font-size: 13px !important;
        line-height: 21px !important;
        margin-bottom: 30px !important;
      }
    }
  }
  & .blog_sn {
    display: flex;
    align-self: center;
    @include respond-max(tablet) {
      align-self: flex-start;
    }
    a {
      margin-right: 16px;
      &:last-of-type {
        margin-right: 0;
      }
      & div.media-obj {
        width: 34px;
        height: 34px;
      }
    }
  }
  & .invitation_content {
    display: flex;
    flex-direction: column;
    width: 80%;

    @include respond-max(tablet-sm) {
      width: 100%;
      margin-bottom: 77px;
    }
    & p {
      margin: 10px 0;
    }
    & .content_title {
      font-family: 'Montserrat', sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: .04em;
      text-align: left;
      color: #000;
    }
    & .content_description {
      color: rgba(0,0,0,.75);
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      text-align: left;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: .04em;
    }
    & .content_button {
      margin-top: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      text-decoration: none;
      svg {
        width: 35px;
        height: 35px;
        background: $white;
        border-radius: 50%;
        margin-right: 10px;
        padding: 7px;

        @include respond-min(tablet) {
          margin-right: 15px;
          width: 30px;
          height: 30px;
        }
      }
      &:hover {
        svg {
          border: 1px solid $black;
          background: transparent;
        }
      }
      & p {
        font-size: 10px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: .5px;
        text-align: left;
        color: #292d32;
        text-transform: uppercase;
      }
    }
  }
}
.blog-article__content_stats {
  height: 18px;
  display: flex;
  justify-content: space-between;
  @include respond-max(tablet) {
    margin-left: 0;
    font-size: 1.6rem;
  }
  .xlike__count {
    font-size: 12px !important;
    @include respond-max(tablet) {
      font-size: 12px !important;
    }
  }
}
.article__statistics {
  display: flex;
  height: 18px;
  margin-top: .625rem;
  padding: 0 1rem;
  width: 100%;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(41, 45, 50, 0.4);

  .article_more & {
    margin-top: 0;
    padding: 0;
    @include respond-max(tablet) {
      margin-top: 3rem;
    }
  }
  @include respond-max(tablet) {
    //font-size: 1.9rem;
    margin-top: 3rem;
  }
  &__rating {
    display: flex;
    width: 25%;
  }
  &__likes {
    display: flex;
    width: auto;
    min-width: 25%;
    max-width: 50%;
    justify-content: center;
    align-items: center;
    .statistics-icon {
      width: 18px;
      height: 18px;
      margin: 0 4px;
    }
    span:first-of-type {
      margin-right: 0.4rem;
      .article_more & {
        margin-right: 0.8rem;
      }
    }
  }
  &__copy {
    display: flex;
    width: 10%;
  }
  &__reading {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: .625rem;
    width: 100%;
    padding: 0 1rem;
    &.analytic_article {
      margin-top: 0;
      padding: 0;
      width: auto;
    }
  }
}
.blog-tabs-head {
  position: sticky !important;
  background-color: $l-grey;
  top: 6.8rem;
  z-index: 2 !important;
  padding-top: 1rem;
  flex-direction: row !important;
  @include respond-max(tablet) {
    position: initial !important;
    top: initial;
    z-index: initial !important;
    flex-direction: inherit !important;
    padding-top: 0;
  }
  .tabs__list {
    width: 75%;
    @include respond-max(tablet) {
      width: 100%;
    }
  }
}
.blog-head-socials {
  display: flex;
  width: 25%;
  height: 2.5rem;
  justify-content: end;
  @include respond-max(tablet) {
    display: none;
  }
  a:not(:last-of-type) {
    margin-right: 0.5rem;
  }
  div.media-obj {
    height: 30px;
    width: 30px;
  }
}
.article__banner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: $vd-grey2;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: $black-color1;
  padding: 25px;
  min-height: 200px;
  width: 100%;
  margin-top: 1.275rem;
  margin-bottom: 1.5rem;

  @include respond-min(tablet) {
    padding: 24px;
    font-size: 18px;
  }
  &.only-mobile {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  &__social {
    padding: 25px;
    min-height: 140px;
    background: linear-gradient(250.86deg, #FFEEF4 -19.14%, #F4F9FF 110.79%);
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

    &__icons {
      display: flex;
      margin-bottom: 16px;
      margin-top: 1.25rem;
      flex-wrap: wrap;
      & div.media-obj {
        height: 34px;
        width: 34px;
        margin-right: 16px;
      }
      & a {
        margin-bottom: 0.5rem;
      }
    }
  }
  &__container {
    padding-right: 1.25rem;
    width: 26%;
    padding-bottom: 3.5rem;

    @include respond-max(tablet) {
      width: 100%;
    }
  }
  &__sticky {
    position: sticky;
    top: 10rem;
  }
  &__arrow {
    margin-top: 25px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: $black-color1;
    text-transform: uppercase;
    text-decoration: none;

    @include respond-min(tablet) {
      font-size: 10px;
    }
    svg {
      width: 35px;
      height: 35px;
      background: $white;
      border-radius: 50%;
      margin-right: 10px;
      padding: 7px;

      @include respond-min(tablet) {
        margin-right: 15px;
        width: 30px;
        height: 30px;
      }
    }
    &:hover {
      svg {
        border: 1px solid $black;
        background: transparent;
      }
    }
  }
}
.article__mini-banner {
  min-height: 110px;
  background: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 1.275rem;
  margin-bottom: 1.5rem;
  .close-btn {
    padding: 0;
    height: 16px;
    width: 16px;
    align-self: flex-end;
    background-color: $white;
    border: 0;
    border-radius: 50%;
    margin-bottom: 5px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &__container {
    display: flex;
    background: $white;
    align-items: center;
    box-shadow: 0px 20px 70px -10px #0000001A;
    padding: 15px 10px;
    border-radius: 10px;
    &__info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      background: $white;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: $black-color1;
      border-radius: 10px;
      width: 100%;
      padding-left: 5px;
      word-break: break-word;
    }
  }
  &__arrow {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: $black-color1;
    text-transform: uppercase;
    text-decoration: none;

    @include respond-min(tablet) {
      font-size: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
      background: $red;
      border-radius: 50%;
      margin-right: 10px;
      padding: 4px;

      @include respond-min(tablet) {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    &:hover {
      svg {
        border: 1px solid $black;
      }
    }
  }
}
.article--big {
  grid-column: 1/4;
  .article__content-wrap {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $vd-grey;
    .events & {
      padding: rem(45) rem(50) rem(35);
      border-bottom: 0 solid $vd-grey;
    }
  }
  .article__header {
    justify-content: start;
    margin: 0;
    .article__important,
    .article__type {
      margin: 0;
      margin-right: 1.8rem;
      font-size: 0.6rem;
      .page--analytic-research &, .page--blog &, .page--news & {
        font-size: .55rem;
      }
    }
  }
  .article__inner {
    flex-direction: row;
    @include respond-max(mobile-ml) {
      flex-wrap: wrap;
    }
  }
  .article__image {
    margin-top: 0;
    padding-right: 2rem;
    width: 108%;

    .events & {
      flex: 1 0 40%;

      img {
        height: 100%;
      }

      @include respond-max(mobile-ml) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 2rem;
      }
    }
  }
  .article__btn {
    flex-direction: row;
    position: relative;
    /* display: flex; */
    width: 12rem;
    padding: 1rem 0;
  }
  .article__content {
    border: none;
    padding: 0;
    .events & {
      border-top: 1px solid #626d7d;
      border-bottom: 1px solid #626d7d;
      padding-bottom: 1.875rem;
    }
  }
  .article__text {
    padding-bottom: 0.7rem;
  }
  .article__date {
    text-transform: uppercase;
    padding: .8rem 0 0;
    font-weight: bold;
    font-size: .6rem;
    .events & {
      font-size: rem(14, $body-size-pc);
      @include respond-max(tablet) {
        font-size: rem(14, $body-size-mobile);
      }
    }
  }
}

.events {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background: #E5E5E5;

  .btn__arrow {
    position: relative;
    left: 8%;
    top: initial;
    transform: translate(0%,0%);
  }
}
.page--news {

  .article__content {
    margin-bottom: 2rem;

    @include respond-min(tablet) {
      margin-bottom: 0;
    }
  }
}
