.anchor {
  display: none;
  z-index: 2;
  &--scrolled {
    position: fixed;
    top: 6.9em;
    width: 100%;
  }
  @include respond-min(tablet) {
    display: flex;
  }
}

.anchor-nav {
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: auto;


  .mobileapp-mainmenu-img {
    max-width: 100%;
    max-height: 183px;
  }

  @include respond-min(tablet) {
    order: 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
    margin-right: auto;
  }
}

.anchor-nav__list {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.anchor-nav__item {
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $black;

  &:hover > a {
    background: #23dea8;
  }

  &:hover > .anchor-nav__dropdown {
    top: 100%;
    opacity: 1;
  }

  @include respond-max(tablet) {
    right: -100%;

    .header.opened & {
      right: 0;

      &:nth-of-type(1) {
        transition-delay: 0.2s;
      }

      &:nth-of-type(2) {
        transition-delay: 0.3s;
      }

      &:nth-of-type(3) {
        transition-delay: 0.4s;
      }
    }
  }

  @include respond-min(tablet) {
    overflow: hidden;
    position: relative;
    flex-direction: row;
    border-bottom: 0;

    &:hover {
      overflow: visible;
    }
  }
}
.anchor-nav__dropdown {
  display: block;
  position: absolute;
  width: 200%;
  top: -9999px;
  opacity: 0;
  background: #52ae94;
  z-index: 100;
  transition: opacity 0.2s ease-in-out, margin 0.2s ease-in-out;

  .anchor-nav__dropdown-item {
    a {
      display: block;
      padding: .7em 2rem .5em;
      color: #fff;
      font-size: .75em;
      font-weight: 700;
      text-decoration: none;
      font-family: Roboto Bold,sans-serif;
      text-transform: uppercase;

      &:hover {
        background: #23dea8;
      }
    }
  }
}
.anchor-nav__link, .anchor-nav__href {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  line-height: 1.4;
  text-decoration: none;
  font-family: Roboto Bold,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: .75em;
  color: white;

  &.active, &:hover {
    background: $green;
  }

  .anchor-nav__content & {
    position: sticky;
    top: 0;
    margin-bottom: 3.75rem;
    padding-left: 5.5rem;
    border-bottom: 1px solid $black;
  }

  @include respond-min(tablet) {
    padding: 0.5em 2rem;

    .anchor-nav__item:last-of-type & {
      padding-right: 1rem;
    }
  }
}
