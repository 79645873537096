.page--about {

  & .card__title {
    margin-bottom: 5rem;
    margin-top: 2.5rem;
  }

  & .info-content--ratings {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include respond-min(tablet) {

    & .info-content--ratings {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem!important;
    }

    & .info-content {
      padding-bottom: 0;
    }

    & .files__block+.files__block {
      margin-top: 5rem;
    }

    & .files {
      padding-bottom: 3.5rem;
    }

    & .cards__card {
      margin-bottom: 0;

      & .card__title {
        margin-bottom: 3rem;
        margin-top: 5rem;
      }
    }
  }
}
