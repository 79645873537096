.u-mta {
  margin-top: auto;
}

.u-mba {
  margin-bottom: auto;
}

.u-mla {
  margin-left: auto;
}

.u-mra {
  margin-right: auto;
}

.u-mt {
  margin-top: $offset-mobile;

  @include respond-min(tablet) {
    margin-top: $offset-desktop;
  }
}

.u-mb {
  margin-bottom: $offset-mobile;

  @include respond-min(tablet) {
    margin-bottom: $offset-desktop;
  }
}

.u-ml {
  margin-left: $offset-mobile;

  @include respond-min(tablet) {
    margin-left: $offset-desktop;
  }
}

.u-mr {
  margin-right: $offset-mobile;

  @include respond-min(tablet) {
    margin-right: $offset-desktop;
  }
}
.mb-0 {
  margin-bottom: 0;

  @include respond-min(tablet) {
    margin-bottom: 0;
  }
}
.mb-1 {
  margin-bottom: 0.125rem;

  @include respond-min(tablet) {
    margin-bottom: 0.25rem;
  }
}
.mb-2 {
  margin-bottom: 0.25rem;

  @include respond-min(tablet) {
    margin-bottom: 0.5rem;
  }
}
.mb-3 {
  margin-bottom: 0.5rem;

  @include respond-min(tablet) {
    margin-bottom: 1rem;
  }
}
.mb-4 {
  margin-bottom: 1.125rem;

  @include respond-min(tablet) {
    margin-bottom: 2.25rem;
  }
}

.mb-5 {
  margin-bottom: 2.25rem;
}

