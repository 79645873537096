.form {
  display: flex;
  flex-direction: column;
  //margin-bottom: 10rem;

  .page--contacts &,
  .page--product & {
    margin-bottom: 0;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include respond-min(tablet) {
    //margin-bottom: 9.375rem;

    .page--contacts & {
      padding-top: 4.5rem;
      padding-bottom: 9.375rem;
    }

    .page--product & {
      padding-top: 9.375rem;
      padding-bottom: 9.375rem;
    }
  }

}

.form__title {
  margin-bottom: 3.375rem;

  @include respond-min(tablet) {
    margin-bottom: 5rem;
    margin-left: 18rem;
  }
}

.form__inner {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
  }

  &--right {
    justify-content: flex-end;
    width: 100%;
  }
}

.form__image {
  height: 27.5rem;

  img {
    max-height: 60vh;
    object-fit: cover;
  }

  @include respond-min(tablet) {
    width: 50%;
    height: auto;

    img {
      max-height: 100%;
    }
  }
}

.form__form {
  //position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  align-self: stretch;

  @include respond-min(tablet) {
    width: 50%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .counter-box{
      position: relative;
      //display: inline-block;
      .counter-comment{
          position: absolute;
          right: 10px;
          bottom: 5px;
      }
  }
}

.form__content {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  .error_form-messages .error {
    display: flex;
    flex-direction: row;
    margin: 2.2em 0;
    font-size: .75rem;
    background: #FFF4F5;
    border-radius: 8px;
    padding: 16px;
    gap: 10px;

    .error-img {
      position: initial !important;
      width: fit-content !important;
      height: fit-content !important;
      margin-top: 0 !important;
    }

    .error-text-container {
      display: flex;
      flex-direction: column;

      .error-header {
        font-size: 13px !important;
        font-weight: 500 !important;
        line-height: 18.2px !important;
        text-align: left !important;
        color: #DA111B !important;
      }

      .error-description {
        font-size: 13px !important;
        font-weight: 400 !important;
        line-height: 18.2px !important;
        text-align: left !important;
        color: #0E0F1180 !important;
      }
    }
  }

  .form-group {
    font-size: 1.75rem;
    @include respond-min(tablet) {
      font-size: .9rem;
    }
  }

  .form-group.form__input_readonly {
    padding: 1rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-group:not(.form__input_readonly) .js-link-tab {
    padding: 1rem 2.5rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 2.875rem;
  }
}
.form_Auth {
  .form__content {
    margin-bottom: 0;
  }
}
.form__description {
  margin: 1rem 0 3rem;
  font-size: 1.6rem;
  @include respond-min(tablet) {
    margin: 0.2rem 0 2rem;
    font-size: 1rem;
  }
}

.form__input,
.form__checkbox,
.form__textarea {
  margin-bottom: 2.5rem;
  text-align: left;

  &.invalid {
    margin-bottom: 4.25rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.875rem;

    &.invalid {
      margin-bottom: 1.875rem;
    }

    .form--small & {
      margin-bottom: 1.25rem;
    }
    &__npf {
      margin-bottom: 2.875rem !important;
    }
  }
}

.form__checkbox {
  @include respond-min(tablet) {
    .form--small & {
      margin-top: 0.625rem;
    }
  }
}

.form__btn {
  align-self: center;

  @include respond-min(tablet) {
    align-self: flex-start;
    min-width: 13.75rem;
  }
}

.form__success-content-wrapper {
  position: relative;
  display: none;

  &.active {
    display: block;
    margin: 0 auto;
  }
  .form-success__content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    box-sizing: content-box;

    .form__form.active & {
      display: flex;
      pointer-events: all;
      opacity: 1;
    }

    .info-content p {
      margin-bottom: 2.5rem!important;
    }

    @include respond-min(tablet) {
      height: 100%;
      //max-width: 521px;
      //padding-top: 5rem;
      //padding-bottom: 5rem;
      //padding-left: 3.125rem;
      //padding-right: 3.125rem;
      //background-image: url(/assets/dist/img/check.png);
      //background-repeat: no-repeat;
      //background-position: 130% 130%;
    }

    .form-success__img-checked {
      margin-bottom: 2rem;
      @include respond-min(tablet) {
        margin-bottom: 4rem;
      }
      svg {
        width: 4rem;
        height: 4rem;
        @include respond-min(tablet) {
          width: 4rem;
          height: 4rem;
        }
      }
    }


    .form-success__header {
      display: block;
      position: relative;
      background: #AD033B;
      width: 100%;

      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 50%;
        height: 50%;
        bottom: 0;
        left: 0;
        background: #fff;
        opacity: .3;
      }

      .form-success__logo {
        position: relative;
        height: 253px;

        svg {
          position: absolute;
          width: 150px;
          left: 50%;
          top: 50%;
          margin-left: -75px;
          margin-top: -75px;
          z-index: 1;
        }
      }
      .form-success__close.btn__circle {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 25px;
        right: 25px;
        color: #fff;

        &:hover:before {
          background-color: #000;
        }

        &:hover:after {
          box-shadow: inset 0 0 0 1px #000;
        }

        &::after {
          box-shadow: inset 0 0 0 1px currentColor;
        }

        .btn__close::before,
        .btn__close::after {
          background-color: #fff;
        }
      }
    }

    .form-success__main {
      width: 100%;
      margin-top: 3rem;
      margin-bottom: 3rem;
      height: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 2rem;

      .form-success__title {
        font-style: normal;
        font-weight: bold;
        font-size: 3.526rem;
        line-height: 5.063rem;
        text-align: center;
        letter-spacing: 0.02em;
        color: #000000;
        margin-bottom: 3rem;

        @include respond-min(tablet) {
          font-size: 2.63rem;
          line-height: 3.063rem;
          margin-bottom: 1.5rem;
        }
      }
      .form-success__text {
        font-style: normal;
        font-weight: normal;
        font-size: 1.75rem;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #616161;
        padding: 0 2rem;

        @include respond-min(tablet) {
          font-size: .875rem;
          margin-bottom: 1.5rem;
        }
      }

      .form-success__logo {
        text-align: center;
        padding-top: 3.5rem;
        justify-content: center;

        svg {
          width: 128px;
          height: 36px;
        }
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

#jGrowl {
  display: none;
}


.form-b {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  .page--contacts &,
  .page--product & {
    margin-bottom: 0;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  &--padding-top {
    padding-top: 10rem;
  }

  @include respond-min(tablet) {
    margin-bottom: 9.375rem;

    &--padding-top {
      padding-top: 9.375rem;
    }

    .page--contacts & {
      padding-top: 7.5rem;
      padding-bottom: 9.375rem;
    }

    .page--product & {
      padding-top: 9.375rem;
      padding-bottom: 9.375rem;
    }
  }
}

.form-b__title {
  max-width: 75%;
  margin: auto;
  margin-bottom: 1.1rem;

  @include font('r');
  line-height: 130%;
  text-align: center;
  font-size: 26px;
  letter-spacing: 0.04em;
  color: #000000;
  text-transform: none;

  @include respond-min(tablet) {
    max-width: 55%;
    margin: auto;
    margin-bottom: 1.1rem;

    font-size: 34px;
  }
}

.form-b__subtitle {
  margin-bottom: 4.5rem;

  @include font('b');
  line-height: 130%;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;

  @include respond-min(tablet) {
      max-width: 55%;
      margin: auto;
      margin-bottom: 4.5rem;

      font-size: 30px;
  }
}

.form-b__inner {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.form-b__image {
  position: relative;
  height: 27.5rem;

  img {
    max-height: 60vh;
    object-fit: cover;
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  @include respond-min(tablet) {
    width: 50%;
    height: auto;

    img {
      max-height: 100%;
    }
  }
}

.form-b__form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  padding-top: 3.75rem;
  padding-bottom: 4rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet) {
    width: 57%;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    padding-left: 2.7rem;
    padding-right: 5.15rem;
  }
}

.form-b__content {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 3rem;
  }
}

.form-b__input,
.form-b__textarea {
  margin-bottom: 2.5rem;

  &.invalid {
    //margin-bottom: 5rem;
  }
  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;

    &.invalid {
      //margin-bottom: 1.875rem;
    }
  }
}

.form-b__checkbox {
  margin-top: 18px;
}

.form-b__btn {
  min-width: 23rem;
  align-self: flex-start;
  border-radius: 30px;

  @include respond-min(tablet) {
    min-width: 11.5rem;
    padding: 1rem 1rem;

    span {
      font-size: 1rem;
      font-family: Roboto Regular, "Helvetica Neue";
    }
  }
  span {
    font-size: 2rem;
    text-transform: unset;
  }
}

.form-b__success-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.75rem;
  padding-bottom: 4rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;
  pointer-events: none;
  opacity: 0;

  .form__form.active & {
    pointer-events: all;
    opacity: 1;
  }

  @include respond-min(tablet) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
}

.form-b__success-title {
  margin-bottom: 1.5rem;
  text-align: center;

  @include respond-min(tablet) {
    margin-bottom: 1rem;
  }
}

.form-b__success-text {
  margin-bottom: 5rem;

  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
  }
}

.form-b__success-btn {
  align-self: center;
  min-width: 20rem;

  @include respond-min(tablet) {
    min-width: 13.75rem;
  }
}

.form-b .form__input {
  box-shadow: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.form-b__title {
  max-width: 75%;
  margin: auto;
  margin-bottom: 1.1rem;

  @include font('r');
  line-height: 130%;
  text-align: center;
  font-size: 26px;
  letter-spacing: 0.04em;
  color: #000000;
  text-transform: none;

  @include respond-min(tablet) {
    max-width: 55%;
    margin: auto;
    margin-bottom: 1.1rem;

    font-size: 34px;
  }
}

.form-b__subtitle {
  margin-bottom: 4.5rem;

  @include font('b');
  line-height: 130%;
  font-size: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;

  @include respond-min(tablet) {
      max-width: 55%;
      margin: auto;
      margin-bottom: 4.5rem;

      font-size: 30px;
  }
}

.form-fullname-group, .fullname-field {

  &.hidden {
    display: none;
  }
  .extended-tooltip {
    position: absolute;
    color: $red;
    font-size: 1.4rem;
    line-height: 1.465;
    left: 2.5rem;
    top: calc(100% + 0.3125rem);

    @include respond-min(tablet) {
      font-size: .85rem;
    }
    &.green {
      color: #0bbb7c;
    }
  }
}
