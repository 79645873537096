.user-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .header.opened & {
    margin-bottom: 5rem;
    transition-delay: 0.6s;
  }

  @include respond-min(tablet-sm) {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 40%;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }
}

.user-nav__list {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  align-items: center;

  @include respond-min(tablet-sl) {
    gap: 16px;
  }

  @include respond-min(desktop-lg) {
    gap: 24px;
  }
}

.user-nav__item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #0E0F11B2;

  @include respond-min(tablet-sl) {
    font-size: 13px;
    line-height: 28px;
  }

  @include respond-min(desktop-lg) {
    font-size: 15px;
    line-height: 20px;
  }

  & .enter-item {
    letter-spacing: -.25px;
    background-image: url("../../../src/assets/sprite/i-profile-user-new.svg");
    line-height: 1.4;
    transition: opacity .2s;
    background-color: #f3f3f3;
    opacity: .75;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    height: 40px;
    align-items: center;
    text-decoration: none;
    display: none;

    @include respond-min(desktop-sm) {
      display: flex;
      padding: 16px 18px;
      background-position: 50%;
    }

    @include respond-min(desktop) {
      padding: 9px 38px 9px 12px !important;
      border-radius: 6px;
      background-position: 95% !important;
    }

    @include respond-min(desktop-lg) {
      padding: 10px 46px 10px 14px !important;
      border-radius: 10px;
    }

    .enter-text {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      letter-spacing: -0.005em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #0E0F11B2;
      display: none;

      @include respond-min(tablet-sl) {
        font-size: 13px;
        line-height: 18px;
      }

      @include respond-min(desktop) {
        display: flex;
      }

      @include respond-min(desktop-lg) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    
    &:hover {
      opacity: 1;
      .enter-text {
        color: $black;
      }
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet-sm) {
    margin-bottom: 0;
    //margin-right: 2.25rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.open-button {
  border: 2px solid $red;
  background-image: none;
  background-position: 95%;
  background-color: $red;
  background-repeat: repeat;
  background-size: 20px 20px;
  transition: background-color .2s, color .2s;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 12px;
  min-width: fit-content;

  @include respond-min(tablet-sm) {
    background-color: $white !important;
    padding: 9px 38px 9px 12px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("../../../src/assets/sprite/i-wallet.svg");
  }

  @include respond-min(desktop-sm) {
    border-radius: 6px !important;
  }

  @include respond-min(desktop-xlg) {
    padding: 10px 46px 10px 14px !important;
    border-radius: 10px !important;
  }

  a {
    align-items: center;
    display: flex;
    text-decoration: none;

    .open-text {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      letter-spacing: 0%;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $white;
      font-size: 12px;
      line-height: 16px;

      @include respond-min(tablet-sm) {
        color: #0E0F11B2 !important;
        font-size: 13px;
        line-height: 18px;
      }

      @include respond-min(desktop-xlg) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  &:hover {
    .open-text {
      @include respond-min(tablet-sm) {
        color: $white !important;
      }
    }
    @include respond-min(tablet-sm) {
      background-color: $red !important;
      background-image: url("../../../src/assets/sprite/i-wallet-white.svg");
    }
  }
}
.user-nav__link {
  @include font('b');

  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 0;
  font-size: 1.5rem;
  text-decoration: none;

  @include respond-min(tablet-sm) {
    font-size: 0.75rem;
    line-height: 1.4;
  }
}

.login__menu {
  display: none;
  position: absolute;
  flex-flow: column;
  width: 21.5em;
  top: 95%;
  bottom: auto;
  left: auto;
  right: 0%;
  z-index: 100;
  min-width: 100%;
  border-radius: .5em;
  background-color: $white;
  overflow: hidden;
  box-shadow: 0 5px 12px 5px rgba(0, 0, 0, .2);

  /*&::before {
    content: "";
    position: absolute;
    right: 15px;
    top: -14px;
    border: 7px solid $white;
    border-bottom-color: $white;
    z-index: 101;
  }
  &::after {
    content: "";
    position: absolute;
    right: 14px;
    top: -17px;
    border: 8px solid $white;
    border-bottom-color: $white;
    z-index: 100;
  }*/

  a {
    opacity: .75;
    padding: .75em 1.5em 1em 1em;
    display: flex;
    max-width: 100%;
    color: #202020;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);

    .menu-item__text-wrap {
      flex: 1;
      padding-right: 1em;

      .login-menu__link-title {
        letter-spacing: -.25px;
        padding-bottom: .25em;
        font-weight: 500;
        transition: background-color .2s;
      }
      .login-description {
        color: rgba(32, 32, 32, .75);
        font-size: .75em;
        font-weight: 500;
      }
    }
    .menu-item__icon-wrap {
      align-self: center;

      img {
        vertical-align: middle;
        max-width: 100%;
        display: inline-block;
      }
    }
    &:hover {
      background-color: #f3f3f3;
      opacity: 1;
      .login-menu__link-title {
        background-color: #f3f3f3;
      }
    }
  }
  /*a.login__menu-exit {
    color: #75808f;

  }*/

  &.open {
    display: flex;
  }
}
