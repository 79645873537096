.pif-faq-accordion {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    
    &__title {
        margin-bottom: 3.125rem;
    }
    .accordion {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        letter-spacing: 0.04em;
        color: $black-color1;
        
        &__item {
            border-bottom: 1px solid $grey-9;
        }
        &__item.active {
            margin-bottom: 15px;
                    
            .accordion__title::after {
                background-color: $red; 
            }
            .accordion__content {
                padding: 0 0 20px 0;
                
                ul, ol {
                    
                    li {
                        list-style: initial;
                        margin: 0 0 25px 25px;
                        padding-left: 10px;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 160%;
                    }
                }
                ol li {
                    list-style: decimal;
                }
                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 160%;
                }
                p, ul, ol {
                    margin-bottom: 10px;
                }
            }
        }
        &__title {
            margin: 0 !important;
            padding: 20px 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 110%;
            
            @include respond-min(tablet) {
                font-size: 20px;
                line-height: 120%;
                padding-right: 7rem !important;
                padding-left: 0 !important;
            }
        }
    }
}