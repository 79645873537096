.bonds__item {
  font-size: 14px;
  //cursor: pointer;
  background-color: $white;
  &.even {
    background-color: $l-grey;
  }

  &.highLightedRow {
    background-color: $g-yellow;
  }

  @media (max-width: $tablet) {
    border: 1px solid black;
  }

  &:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }

  span  {
    @media (max-width: $tablet) {
      font-weight: 600;
      font-size: 14px;
      width: fit-content;
      display: block;
      margin: 0 auto;
      padding-bottom: 3px;
    }
  }
  .yield_hidden {
    display: none;
  }
}

.dt-center, .dt-left {
  //padding: 4px;
  cursor: pointer;
  @media (max-width: $tablet) {
    padding: 0;
  }
  @media (min-width: $tablet) {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
.years_to_maturity__term,
.special_indicator__term,
.bonds__item-yield,
.bonds__item-credit,
.bonds__item-issuer,
.bonds__item-liquidity,
.bonds__item-quality {
  border-radius: 40px;
  text-align: center;
  padding: 8px 14px;
  min-width: 60px;
  display: inline-block;
  font-size: 14px;
  line-height: 150%;

  &.is-green {
    background: $green;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $green;
      }
    }
  }
  &.is-b-green {
    background: $b-green;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $b-green;
      }
    }
  }
  &.is-l-green {
    background: $l-green;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $l-green;
      }
    }
  }
  &.is-l-green1 {
    background: $l-green1;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $l-green1;
      }
    }
  }

  &.is-blue {
    background: $l-blue;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $l-blue;
      }
    }
  }

  &.is-s-blue {
    background: $s-blue;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $s-blue;
      }
    }
  }

  &.is-l-red {
    background: $l-red;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $l-red;
      }
    }
  }
  &.is-red {
    background: $red;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $red;
      }
    }
  }
  &.is-red1 {
    color: $white;
    background: $red1;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $red1;
      }
    }
  }
  &.is-orange {
    background: $orange;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $orange;
      }
    }
  }
  &.is-l-orange {
    background: $l-orange;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $l-orange;
      }
    }
  }
  &.is-vd-grey1 {
    color: $white;
    background: $vd-grey1;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $vd-grey1;
      }
    }
  }

  &.is-grey {
    background: $grey-2;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $grey-2;
      }
    }
  }
  &.is-black {
    background: $black;

    span:not(.media-obj) {
      @media (max-width: $tablet) {
        border-bottom: 2px solid $black;
      }
    }
  }

  @media (max-width: $tablet) {
    padding: 5px;
    border-radius: 0;
  }
}

.bonds__item-button {
  display: flex;
  white-space: nowrap;
  padding: 0;
  border: none;
  background: none;
  align-items: center;

  @media (max-width: $desktop) {
    display: none;
  }
}

.bonds__item-button_icon {
  width: 19px;
  height: 19px;
  transition-duration: 0.4s;
  margin-right: 10px;
}

.bonds__item-inner {
  transition-duration: 0.4s;
  margin: 0 -10px;
  align-items: center;
  width: calc(100% + 20px);
  padding: 0 10px;
  position: relative;
  text-align: center;

  @media (max-width: $tablet) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: initial;
    width: 100%;
    align-items: stretch;
    grid-gap: 0;
  }

  & > * {
    @media (max-width: $tablet) {
      //max-height: 51px;
      min-height: 40px;
      position: relative;
      display: grid;
      align-items: center;
      text-align: center;

      &::before {
        content: '';
        top: 7px;
        right: 0;
        bottom: 7px;
        width: 1px;
        position: absolute;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.bonds__item-credit {
  @media (max-width: $tablet) {
    width: 100%;
  }
  &.is-black {
    color: $white;
  }
}
.bonds__item-title {
  @media (max-width: $tablet) {
    font-weight: 600;
    padding: 12px;
    width: 22%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    order: 1;
    &.shortname.name {
      white-space: normal;
      word-break: break-word;
    }
    &-br {
      width: 33.3%;
    }
  }

  span {
    @media (max-width: $tablet) {
      width: calc(100% - 60px);
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      margin-left: 0;
    }
  }
}

.bonds__item-currency {
  @media (max-width: $tablet) {
    width: 21%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    order: 2;
  }
}

.bonds__item-profitability {
  @media (max-width: $tablet) {
    order: 4;
    text-align: center;
    width: 22%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    &::before {
      display: none;
    }
    &-br {
      width: 33.3%;
    }
  }
}

.bonds__item__cell-quality {
  @media (max-width: $tablet) {
    order: 6;
    width: 33.3%;
    text-align: center;
    .bonds__item-quality {
      width: 100%;
      height: 100%;
    }
  }
}

.bonds__item__cell-liquidity {
  @media (max-width: $tablet) {
    order: 7;
    width: 33.3%;
    text-align: center;
    padding: 0;
    .bonds__item-liquidity {
      width: 100%;
      border-top: none;
      margin-top: 0;
      height: 100%;
    }
  }
}

.bonds__item-market {
  @media (max-width: $tablet) {
    order: 7;
    text-align: center;
    width: 25%;
    padding: 11px;
    display: none;
  }

  @media (max-width: $mobile-md) {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 15px;
    width: 50%;
  }
}

.bonds__item-date {
  @media (max-width: $tablet) {
    order: 3;
    width: 22%;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 11px;
    position: relative;
    .bonds__item-issuer {
      width: 100%;
    }
    span {
      display: none;
    }
    &-br {
      width: 33.3%;
    }
  }
}
.bonds__item-size {
  @media (max-width: $tablet) {
    order: 7;
    width: 30%;
    text-align: center;
    position: relative;
    &::before {
      display: none;
    }
  }
}

.bonds__item-ratingnetdebt_equity {
  @media (max-width: $tablet) {
    order: 7;
    width: 33.3%;
    text-align: center;
    position: relative;
    &::before {
      display: none;
    }
  }
}

.bonds__item-maturity {
  @media (max-width: $tablet) {
    order: 7;
    padding-top: 15px;
    width: 50%;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin: 15px 0;
  }
}

.bonds__item__cell-credit {
  @media (max-width: $tablet) {
    order: 5;
    width: 33.3%;
    height: initial;
    display: block;
    border-radius: 0;
  }
}

.bonds__item-subtitle, .bonds__item-date_mobile {
  @media (min-width: $tablet + 1) {
    display: none;
  }
  color: $md-grey;
  font-size: 11px;
  text-align: center;
  width: 100%;
  margin-bottom: 5px;

  .is-red & {
    color: $white;
  }
}
.bonds__item__cell-link_to_bond {
  order: 0;
  padding: 10px;
  @media (max-width: $tablet) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}
