.lk-nav__buttons {
  align-items: center;
  display: none;
  
  @include respond-min(tablet-sm) {
    display: flex;
    gap: 34px;
  }
  
  @include respond-min(desktop-sm) {
    gap: 24px;
    padding-right: 24px;
  }
  
  @include respond-min(desktop-lg) {
    gap: 32px;
    padding-right: 32px;
  }
}

.lk-nav-ol {
  margin-right: 2.6rem;

  &__en {
    margin-right: 1.6rem;
  }

  &__open {
    background-color: $red;
    border-radius: 8px;
    padding: 0.6rem;
    text-decoration: none;
    span.media-obj {
      height: 10px;
      width: 10px;
    }
    span {
      font-size: 10px;
      color: $white;
    }
  }
  
  &__item {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    letter-spacing: -0.005em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #0E0F11B2;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    height: 20px;
    width: 20px;

    @include respond-min(desktop-xlg) {
      font-size: 15px;
      line-height: 20px;
      width: 24px;
      height: 24px;
    }
    &:hover {
      color: #5c6e8a;
    }
  }

  &__icon-wrap {
    position: relative;
    padding: .5rem;
  }

  &__icon {
    display: flex;
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    padding: 0.6rem;
  }

  &__icon--telegram {
    display: flex;
    height: 20px;
    width: 20px;

    @include respond-min(desktop-xlg) {
      width: 24px;
      height: 24px;
    }
  }
  &__icon--search-new {
    display: flex;
    height: 20px;
    width: 20px;

    @include respond-min(desktop-xlg) {
      width: 24px;
      height: 24px;
    }
  }

  &__icon--small {
    width: 1.3rem;
    height: 1.3rem;
    padding: 0.2rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: #e83872;
  }

  &__text {
    margin-left: 0.5rem;
    color: $vd-grey;
    font-size: 1.5rem;
    text-decoration: none;
  }
  &__lang-link {
    font-family: 'Montserrat', sans-serif;
    margin-left: 0.5rem;
    color: $vd-grey;
    font-size: 16px;
    line-height: 19px;
    text-decoration: none;
  }
  &_search {
    margin-right: 0.938rem;

    .lk-nav-ol__icon {
      @include respond-min(tablet-sm) {
        width: 2.1rem;
        height: 2.1rem;
        padding: .5rem;
      }
    }
  }
  &_button {
    margin-left: 0.938rem;
    margin-right: 0;
  }
}

.lk-nav {
  display: none;
  &.is-open {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    will-change: transform;
    animation: mmfadeIn .3s cubic-bezier(0,0,.2,1);
    z-index: 999;
  }
  &__content {
    animation: mmslideIn .3s cubic-bezier(0,0,.2,1);
    position: fixed;
    background: $white;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 2rem 0;
    will-change: transform;
    z-index: 999;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
    text-decoration: none;
  }

  &__item:hover {
    background: $l-grey;
  }

  &__item:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }

  &__icon {
    width: 4rem;
    height: 4rem;
    display: inline-flex;
    padding: 0.7rem;
    border-radius: 2rem;
  }

  &__text {
    color: $vd-grey;
    font-size: 1.7rem;
    padding-left: 4rem;
    width: 100%;
  }

  &__superscript-text {
    position: absolute;
    top: 3.3rem;
    font-size: 1.1rem;
  }
  &__main-text {
    padding: 0.2rem 0.7rem;
    background: $l-green;
    display: flex;
  }

}


