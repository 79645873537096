.advantages {
  /* margin-bottom: 10rem; */
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  padding-left: 7.5rem;
  padding-right: 4rem;

  &--list {
    padding-top: 5rem;
    padding-left: $offset-mobile;
    padding-right: $offset-mobile;
  }

  .page--info & {
    margin-bottom: 0;
  }

  .page--product & {
    margin-bottom: 2em;
  }

  @include respond-min(tablet) {
    /* margin-bottom: 9.375rem; */
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    padding-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.advantages__items {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 15rem;
  }
}

.advantages__item {
  display: flex;
  align-items: center;
  margin-bottom: 6.25rem;
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    width: 50%;
    margin-top: 3.5rem;
    margin-bottom: 0;
    padding-right: 4rem;

    &:nth-of-type(-n + 2) {
      margin-top: 0;
    }
  }
}

.advantages__item-image {
  flex-shrink: 0;
  width: 4rem;
  height: 4.75rem;
  margin-right: 2.5rem;

  @include respond-min(tablet) {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.advantages__item-text {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.465;
  letter-spacing: 0.02em;

  .advantages--list & {
    @include text('t-normal');
  }

  .page--info & {
    @include font('b');
    font-size: 1.375rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  @include respond-min(tablet) {
    font-size: 0.875rem;
    letter-spacing: 0.02em;

    .page--info & {
      font-size: 0.8125rem;
    }
  }
  &[href] {
    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }
}
