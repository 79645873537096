.main-content, .new-main-content {
  display: flex;
  flex-direction: column;
  margin-top: 62px;

  @include respond-min(desktop-sm) {
    margin-top: 72px;
    .scroll--header & {
      margin-top: 0;
    }
  }
  
  @include respond-min(desktop-sm) {
    margin-top: 80px;
  }
  
  .header-wrapper__banner {
    opacity: 0;
  }
}

.main-content__body {
  //@include respond-min(tablet) {
  //  display: grid;
  //  column-gap: 1.25rem;
  //  row-gap: 3rem;
  //  grid-template-columns: 12rem auto;
  //}
}

.main-content__image {
  height: auto;
}

.main-content__nav {
  float: left;
  width: 12rem;
}