.cards {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  &.wrapper-mmw {
    margin-bottom: 2rem;
    @include respond-min(tablet) {
      margin-bottom: 2rem;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 4.375rem;
  }
}

.cards__card {
  margin-bottom: 5rem;

  &--big {
    margin-bottom: 7.5rem;
  }

  &--ttb {
    margin-bottom: 5rem;
  }

  &--ttb1 {
    margin-top: 1.7rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 3.75rem;

    .page--business & {
      margin-bottom: 7.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
