.banner-bpif {
  width: 100%;
  margin-bottom: 5rem;

  @include respond-min(tablet) {
    width: auto;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: auto;
    z-index: 3;
    margin-bottom: 0;

    .media-obj,
    .media-obj picture {
      height: auto;
    }

    &--side {
      max-width: 60px;
    }
  }

  &__content {
    text-decoration: none;
    /* background: $red; */
    width: 100%;
    /* padding: 2.7rem 2rem; */

    @include respond-min(tablet) {
      padding: 2rem .8rem;
      margin: auto 0;
    }
  }

  &__icon {
    width: 9rem;
    height: 6.8rem;

    @include respond-min(tablet) {
      width: 2.2rem;
      height: 3rem;
      margin-bottom: .9rem;
    }
  }

  &__text {
    font-weight: 700;
    color: #fff;
    font-size: 2.7rem;
    margin: 3rem 0;

    @include respond-min(tablet) {
      width: 2.3rem;
      height: 16rem;
      margin: 0;
    }
  }
}