.custom-link{
  display: flex;
  white-space: nowrap;
  text-transform: uppercase;
  color: $red;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;

  transition: .1s all;
  
  @include respond-min(tablet) {
    margin-left: 35%;
  }
  svg{
    margin-right: 16px;
    width: 23px;
    height: 23px;
  }
  .media-obj{
    width: initial;
  }

  &:hover {
    opacity: .5;
  }
}

.custom-link__content {
  margin: 4.5px 0 0 0;
}

.custom-link .custom-link__content p{
  margin: 6px 0 0 0;

  @include font('r');
  text-transform: none;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.571429px;
  color: rgba(#000000, .75);

  white-space: normal;
}
