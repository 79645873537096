.content-header {




  @include respond-min(tablet) {
    //padding-top: 6.5rem;
    //padding-top: 0;




  }

}




.content-header__title {


  @include respond-min(tablet) {


    .content-header--big & {
      //margin-top: 5.75rem;
      margin-top: 1rem;

    }

  }

}




.compass-header__span {
  color: $red;
}

.stages__content {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
    margin-left: 1rem;
  }
}

.stages__list {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.625rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-shrink: 0;
    width: 33%;
    margin-bottom: 0;
  }
}

.stages__list + .stages__list {
  margin-top: 18rem;
}

.stages__list + .stages__list + .stages__list {
  margin-top: 36rem;
}

.stages__item {
  position: relative;
  margin-bottom: 2.25rem;
  padding-left: 3.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -0.625rem;
    padding: 0 0.75rem 0;
    border-bottom: 0.75rem solid $red;
    color: $red;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
  }

  &.i_00::before {
    content: '1';
  }

  &.i_01::before {
    content: '2';
  }

  &.i_02::before {
    content: '3';
  }

  &.i_10::before {
    content: '4';
  }

  &.i_11::before {
    content: '5';
  }

  &.i_12::before {
    content: '6';
  }

  &.i_20::before {
    content: '7';
  }

  &.i_21::before {
    content: '8';
  }

  &.i_22::before {
    content: '9';
  }

  @include respond-min(tablet) {
    margin-bottom: 5.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    //&::before {
    //  top: 0.5rem;
    //}
  }
}

.info-content-compass__block h3 {
  color: $red;
}

em.compass-header__span {
  font-style: italic;
  font-size: 2rem
}

.compass_card__image {
  display: none;
}

.visibility_hidden {
  visibility: hidden;
  opacity: 0;
}

.opened .menu-trs.visibility_hidden {
  display: none;
}

.header::before {
  display: none;
}

.em {
  font-style: italic;
}

.products--person .products__image {
  display: none;

  .page--about & {
    display: block;
  }
}

@media (min-width: 1024px) {

  .products--person .products__image {
    display: flex;
  }

  .form {
    margin-bottom: 0 !important;
  }

  .info-content__block {
    //margin-left: 0 !important;
  }

  .info-content p, .info-content li, .card__info li, .card__info p {
    &:not(.analytic_reg_button):not(.analytic_reg_title):not(.dohod-banner-title):not(.charts__legend-name):not(.dohod-banner-title):not(.dohod-banner-description):not(.dohod-banner-button_btn):not(.dohod-banner-button_text) {
      font-size: 1.051875rem !important;
    }
  }

}

//.footer__disclaimer p {
//  font-size: .725rem !important;
//}

.header__tel {
  flex-direction: column;
  text-align: right;
}

hr {
  height: 2px;
  background-color: currentColor;
}
