.modal {
  font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  background-color: #fff;
  padding: 40px 30px;
  max-width: 500px;
  max-height: 100vh;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  overflow-y: auto;
  &-wide {
    max-width: 850px;
    @include respond-max(tablet) {
      max-width: 100%;
    }
  }
  .modal-inline & {
    max-width: 1000px;
  }

  @include respond-min(tablet-sm) {
    max-height: 100vh;
    overflow-y: auto;
  }

}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .exit__link {
    margin-left: auto;
    margin-right: 1.8rem;
    font-size: 1.75rem;
    @include respond-min(tablet) {
      font-size: .9rem;
    }
  }
}

.modal__footer {
  display: flex;
  justify-content: space-between;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.25;
  color: #000;
  box-sizing: border-box;
  .tabs {
    margin-bottom: 0;
    .tabs__item {
      margin-bottom: 0;
    }
  }
  @include respond-min(tablet) {
    font-size: 1.25rem;
  }
}

.modal__desc {
  margin-top: 2rem;
}

.modal__close.btn__circle {
  background: transparent;
  border: 0;
  position: relative;
  z-index: 9;
  display: flex;
  width: 3em;
  height: 3em;
  &:hover::before {
    background-color: currentColor;
    -webkit-transform: translate(-50%,-50%) translateZ(0) scale(1);
    transform: translate(-50%,-50%) translateZ(0) scale(1);
  }
  &:hover .btn__close::before,
  &:hover .btn__close::after {
    background-color: #fff;
  }
  @include respond-min(tablet) {
    width: 2em;
    height: 2em;
  }
}

.modal__content .form__form {
  width: 100%;
  padding: 0;
}

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);

  .social-auth__providers {
    display: flex;
    margin: 0 -5px 1rem;

    .btn__circle:after, .btn__circle:before {
      border-radius: 30%;
    }
  }
}

.modal__btn {
  font-size: 0.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out,-webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #ffffff;
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
  to { transform: translateY(0); }
}

@keyframes mmslideOut {
  from { transform: translateY(0); }
  to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
  z-index: 999;
}

.modal__container.success_show {
  color: #000;
  padding: 0;
  .modal__content {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal__header {
    position: absolute;
    width: 100%;
    right: 0;
    justify-content: flex-end;
  }
  .modal__close.btn__circle {
    color: #fff;
    top: 17px;
    right: 20px;
  }
  .btn__close:after, .btn__close:before {
    background: #fff;
  }
  .modal__close.btn__circle:hover .btn__close:after, .modal__close.btn__circle:hover .btn__close:before {
    background: #000;
  }
  .form__success-content-wrapper .form-success__content .form-success__main {
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-success__title {
    font-family: 'Roboto Bold',Arial,sans-serif;
  }
  .form-success__text {
    font-family: 'Roboto Regular',Arial,sans-serif;
  }
  .reports__tab {
    padding-left: 0;
    padding-right: 0;
  }
}
.modal__close {
  position: absolute;
  right: 0;
  height: 32px;
  width: 32px;
}
.premium-discount--btn {
  position: relative;
  z-index: 1;
  @include respond-max(tablet) {
    margin-top: -30px;
  }
}
.highcharts-button-box {
  @include respond-max(tablet-sm) {
    max-height: 40px;
  }
}
.social-auth__greetings {
  color: $grey-7;
  padding-top: 20px;
  padding-bottom: 10px;
}
