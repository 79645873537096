.s-fonds {
  padding: 0 0 55px 0;

  @include respond-min(tablet) {
    padding: 0 0 71px 0;
  }
}

.s-fonds__title {
  margin: 0 0 2rem 0;

  text-transform: unset;

  @include respond-min(tablet) {
    margin: 0 0 1rem 0;
  }
}

.s-fonds__wrapper {
  display: flex;
  margin-left: 7px;
}

.s-fonds__subtitle {
  margin: 0 0 4rem 0;

  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(#000000, .75);

  @include respond-min(tablet) {
    width: 60%;

    font-size: 1rem;
    line-height: 170%;
  }

  span {
    color: $red;
  }
}

.s-fonds__header {
  position: relative;

  width: 100%;
  min-height: 60px;
  margin: 0 0 2rem 0;
  align-items: flex-start;

  @include respond-min(tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.s-fonds__thead {
  width: 100%;
}

.s-fonds__fond {
  flex-shrink: 0;
}

.s-fonds__list {
  overflow-x: auto;
  
  @include respond-min(tablet) {
    overflow-x: clip;
  }
}
