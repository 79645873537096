*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
}

html {
  @include fluid(
    'font-size',
    (
      360px: 8px,
      768px: 10px,
      1024px: 12px,
      1440px: 16px,
    )
  );
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  position: relative;
  min-width: $mobile;
  font-family: 'Roboto Regular', 'Arial', sans-serif;
  letter-spacing: 0.02em;
  line-height: 1.1;
  color: $black;

  &::after {
    content: '';
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 200vmax;
    height: 200vmax;
    background-color: $white;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: $trs;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    pointer-events: none;
  }
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;

  clip-path: inset(100%);

  
}
.mark-hidden::after {
  opacity: 0 !important;
}

.no-scroll {
  overflow: hidden !important;
}

.overlay {
  &::after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.only-mobile {
  display: flex !important;

  @include respond-min(tablet) {
    display: none !important;
  }
}

.only-desktop {
  display: none !important;

  @include respond-min(tablet) {
    display: flex !important;
  }

  &--inline {
    display: none !important;

    @include respond-min(tablet) {
      display: inline-block !important;
    }
  }

  &--table-cell {
    display: none !important;

    @include respond-min(tablet) {
      display: table-cell !important;
    }

  }
}

.wrapper-m {
  margin-left: $offset-mobile;
  margin-right: $offset-mobile;

  @include respond-min(tablet) {
    margin-left: $offset-desktop;
    margin-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    margin-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    margin-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}
.wrapper-m--wide {
  margin-left: $offset-mobile;
  margin-right: $offset-mobile;

  @include respond-min(tablet) {
    margin-left: calc($offset-desktop - 2.75rem);
    margin-right: calc($offset-desktop - 2.75rem);
  }

  @include respond-min(desktop) {
    margin-left: calc(50vw - #{$desktop} / 2 + (#{$offset-desktop} - 2.75rem));
    margin-right: calc(50vw - #{$desktop} / 2 + (#{$offset-desktop} - 2.75rem));
  }
}

.wrapper-mmw {
  margin-left: 0;
  margin-right: 0;

  @include respond-min(tablet) {
    margin-left: $offset-desktop;
    margin-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    margin-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    margin-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.wrapper-p {
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet) {
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    padding-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.link {
  position: relative;
  align-self: flex-start;
  padding-bottom: 1px;
  border-bottom: 2px solid currentColor;

  &--thin {
    padding-bottom: 1px;
    border-bottom-width: 1px;
  }

  &--no-line {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.link__text {
  .link:hover & {
    color: currentColor;
  }

  .link--no-line:hover & {
    color: initial;
  }
}

// Чтобы календарь не перекрывал хедер
//.pika-single {
//  z-index: 2;
//}

.pika-lendar {
  margin: 0;
  padding: 8px;
}


// всплывающие окна https://popper.js.org/
.tooltip {
  background: hsla(0,0%,96.9%,.95);
  color: #000;
  font-weight: 500;
  padding: 0.5em 0.5em;
  font-size: 0.8em;
  border: 1px solid grey;
  border-radius: 0.2em;
  max-width: 12rem;
  display: none;
  z-index: 3;

  &.tooltip--question-small {
    background: $white;
    box-shadow: 0px 3px 4px $l-grey;
    color: hsla(0,0,0,.8);
    border: none;
    line-height: 1.5;
    padding: 1rem;
    font-size: 1.5rem;
    @include respond-min(tablet) {
      padding: 1rem;
      font-size: .7rem;
    }
  }
  &[data-show] {
    display: block;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 0.6em;
    height: 0.6em;
    z-index: 1;
  }

  .arrow::before {
    content: '';
    transform: rotate(45deg);
    background: hsla(0,0%,96.9%,.85);
    border-top: 1px solid grey;
    border-left: 1px solid grey;
  }

  &[data-popper-placement^='top'] {
    & > .arrow {
      bottom: -0.4em;
      &::before {
        transform: rotate(-135deg);
      }
    }
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -0.4em;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -0.4em;
  }

  &[data-popper-placement^='right'] {
    left: 0.4rem!important;
    & > .arrow {
      left: -0.4em;
      &::before {
        transform: rotate(-45deg);
      }
    }

  }
  .new-header & {
    filter: drop-shadow(0px 6px 25px rgba(74, 80, 91, 0.2));
    background: $white;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    padding: 12px;
    color: rgba($color: $black, $alpha: 0.8);
    max-width: unset;
  }
}


//input[type="date"] {
//  position: relative;
//}
//
//input[type="date"]::-webkit-calendar-picker-indicator {
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  width: auto;
//  height: auto;
//  color: transparent;
//  background: transparent;
//}
//
//input[type="date"]::-webkit-inner-spin-button,
//input[type="date"]::-webkit-clear-button {
//  z-index: 1;
//}


