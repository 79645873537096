.ss-main{
    width: auto;
    .ss-single-selected{
        border: none;
        border-radius: 35px;
        height: initial;
        color: black;
        font-size: 14px;
        letter-spacing: 0px;
        padding: 12px 15px;
        .ss-arrow{
            margin: -2px 0 0 12px;
        }
        .ss-arrow span{
            border: solid black;
            border-width: 0 1px 1px 0;
        }
        &.ss-open-below{
            border-bottom-left-radius: 35px;
            border-bottom-right-radius: 35px;
        }
    }
}

.ss-content{
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    margin: 16px 0 0px 2px;
    &:before{
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        left: 14px;
        top: -5px;
        transform: rotate(45deg);
        background: white;
    }
}

.ss-option{
    padding: 9px 11px!important;
    color: black;
    font-size: 14px;
    letter-spacing: 0px;
    &:hover{
        background-color: transparent!important;
        color: black!important;
    }
    &:not(:last-child){
        border-bottom: 1px solid rgba(0,0,0,.1);
    }
}