.faq {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  @include respond-min(tablet) {
    margin-bottom: 7.5rem;
  }
}

.faq__title {
  position: relative;
  display: flex;
  align-items: flex-end;
  align-self: flex-start;
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
  }
}

.faq__accordion {
  display: flex;
  flex-direction: column;
}

.faq__item {
  border-top: 1px solid $black;

  &:last-of-type {
    border-bottom: 1px solid $black;
  }
}

.faq__caption {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-right: 7rem;
  cursor: pointer;
  font-size: 1.25rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1.25rem;
    width: 16px;
    height: 2px;
    background-color: $black;
    transform: translateY(-50%) translateZ(0);
    transition: $trs;
  }

  &::after {
    transform: translateY(-50%) translateZ(0) rotate(90deg);
  }

  .faq__item.active & {
    &::before {
      transform: translateY(-50%) translateZ(0) rotate(-180deg);
    }

    &::after {
      opacity: 0;
      transform: translateY(-50%) translateZ(0) rotate(0);
    }
  }
}

.faq__content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 0;

  .faq__item.active & {
    max-height: var(--faq-content-max-height);
  }
  .faq--full & {
    padding: 0;
  }

  @include respond-min(tablet) {
    //padding-left: 18rem;
  }
}

.faq__text {
  p {
    margin-bottom: 2.5rem;
    line-height: 1.4;
    letter-spacing: .02em;
  }
}
