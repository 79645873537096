.question {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.5rem;
  padding: 5rem 2.5rem;

  .page--product & {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-direction: row;
    margin-bottom: 5rem;
    padding: 2.5rem 3.125rem;
  }
}

.question__image {
  width: 13rem;
  height: 11rem;
  margin-bottom: 3rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    width: 10.375rem;
    height: 7.5rem;
    margin-bottom: 0;
    margin-right: 4.5rem;
  }
  &--start {
    align-self: flex-start;
  }
}

.question__title {
  margin-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;

  @include respond-min(tablet) {
    margin-bottom: 0;
    margin-right: 4.5rem;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    line-height: 170%;
    font-size: 1.25rem;
    letter-spacing: 0.04rem;
  }
  &--h4 {
    font-size: 1.75rem;
    padding: 0;
    text-align: start;
    line-height: 160%;
    letter-spacing: .04em;
    @include respond-min(tablet) {
      font-size: 1.25rem;
      line-height: 170%;
      letter-spacing: 0.04rem;
      color: rgba(0,0,0,.75);
      margin-right: 1.5rem;
    }
  }
}

.question__btn {
  align-self: center;
  min-width: 20rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    min-width: 13.75rem;
    margin-left: auto;
  }
  .question--two-btns & {
    width: 100%;
  }
}
