.breadcrumbs {
  display: flex;
  flex-direction: column;

  .breadcrumbs__list {
    display: flex;
    flex-wrap: wrap;

    .breadcrumbs__item {
      display: flex;
      align-items: center;

      .breadcrumbs__link {
        &:hover {
          color: $black;
        }
      }

      .breadcrumbs__delimiter {
        flex-shrink: 0;
        width: 0.75rem;
        height: 0.75rem;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        fill: $text-color;
      }

    }
    .B_currentCrumb { 
      transition: $trs;
      @include text('t-small');

      &.clr-black {
        color: $black !important;
      }
    }
  }
}

.content-header--bg {
  .breadcrumbs .breadcrumbs__item {
    a.breadcrumbs__link {
      color: $white;
      &:hover {
        color: $d-grey;
      }
    }
  }
  .B_currentCrumb {
    color: $white;

    @include respond-min(tablet) {
      color: $white;
    }
  }
}

.content-header {
  .page--info & {
    @include respond-min(tablet) {
      padding-bottom: 2rem;
    }
  }
}
.content-header__title {
  .page--info & {
    @include respond-max(tablet) {
      margin-bottom: 5rem;
    }
  }
}
//.page--reports {
//  .breadcrumbs {
//    .clr-text {
//      color: #fff;
//    }
//    .breadcrumbs__link:hover {
//      color: #919aa6;
//    }
//  }
//}
