.btn {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 0;
  color: $black;
  background-color: transparent;
  border: 0;


  &--right {
    margin-left: auto;
  }

  &--inline {
    display: inline-block;
  }

  &--black {
    color: $black;
  }

  &--black-mobile-white {
    color: $white;
    @include respond-min(tablet) {
      color: $black;
    }
  }

  &--red {
    color: $red;
  }

  &--white {
    color: $white;
  }

  &--green {
    color: $green;
  }

  &--grey {
    color: $text-color;
  }

  &--b-none {
    background: none!important;
  }

  &--fill {
    justify-content: center;
    padding: 2rem 2rem;
    background: currentColor;
    border-radius: 2.75rem;

    @include respond-min(tablet) {
      padding: 0.91375rem 1.55125rem;
      border-radius: 1.875rem;
    }

    @include respond-max(mobile-md) {
      padding: 2rem 3rem;
    }

    &.btn--white {
      color: $white;
    }

    &.btn--black-mobile-white {
      color: $white;
      @include respond-min(tablet) {
        color: $black;
      }
    }

    &.btn--white:hover {
      background-color: $black;
    }

    &.btn--black:hover {
      background-color: $black;
      
      @include respond-min(tablet) {
        background-color: $text-color;
      }
    }

    &.btn--black-mobile-white:not(.btn--nh):hover {
      background-color: $black;
      @include respond-min(tablet) {
        background-color: $text-color;
      }
    }

    &.btn--red:hover {
      background-color: $l-red1;
      cursor: pointer;
    }
  }

  &--plain:not(.articles__btn) {
    margin-left: -0.5rem;

    @include respond-min(tablet) {
      margin-left: -0.25rem;
    }
  }

  &--big {
    @include respond-min(tablet) {
      padding: 0;
      color: currentColor;
      background-color: transparent;
    }
  }

  &--b-fill {
    @include respond-min(tablet) {
      padding: 1.6rem 3.1rem;
    }
  }

  &--small {
    .btn__icon {
      width: 1.5rem;
    }
    @include respond-min(tablet) {
      .btn__icon {
        width: 1rem;
      }
      padding: 0;
      align-self: auto;
    }
  }
  &--question-small {
    width: 2rem;
    height: 2rem;
    &.btn--plain .btn__circle {
      width: 22px;
      height: 22px;
    }
    .btn__icon {
      width: 1.1rem;
      height: 1.1rem;
      @include respond-min(tablet) {
        width: 0.7rem;
        height: 0.7rem;
      }
    }
    &.btn--icon:hover .btn__icon  {
      cursor: pointer;
      svg {
        fill: currentColor;
        stroke: $white;
      }
    }
  }


  &--over-block {
    margin-bottom: 2em;
  }
  &--over-block:last-child {
    margin-bottom: 5em;
  }

  &.disabled {
    pointer-events: none;
  }
}



.main-nav__btn-wrap {
  text-decoration: none;
  color: inherit;
}

.btn__circle {
  position: relative;
  display: none;
  flex-shrink: 0;

  .btn--plain & {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: transparent;
  }

  .btn--plain:hover:not(.info-content__social) &,
  .main-nav__btn-wrap:hover & {
    &::before {
      background-color: currentColor;
      transform: translate(-50%, -50%) translateZ(0) scale(1);
    }

    & .btn__line {
      background-color: #fff;
    }

    & .btn__triangle {
      border-left-color: #fff;
    }

    &::after {
      box-shadow: inset 0 0 0 0.25rem transparent;
    }
  }

  .btn--icon & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn--small & {
    width: 3.5rem;
    height: 3.5rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(0);
    border-radius: 50%;
    background-color: transparent;
    transition: $trs;
    transition-duration: 0.15s;
    transition-timing-function: linear;
  }

  &::before {
    width: 95%;
    height: 95%;
    transform: translate(-50%, -50%) translateZ(0) scale(0);
  }

  &::after {
    width: 80%;
    height: 80%;
    border: 2px solid currentColor;
  }

  @include respond-min(tablet) {
    .btn--plain & {
      width: 50px;
      height: 50px;
    }

    .btn--plain:hover & {
      &::after {
        box-shadow: none;
        border: 2px solid currentColor;
      }
    }

    .btn--big & {
      display: flex;
      width: 50px;
      height: 50px;
      margin-right: 0;

      &::after {
        width: 100%;
        height: 100%;
        opacity: 0.5;
      }
    }

    .btn--small & {
      width: 2.5rem;
      height: 2.5rem;
    }

    .btn--fill.btn--big & {
      &::after {
        background-color: currentColor;
      }
    }

    .btn.disabled & {
      &::after {
        background-color: $white;
      }
    }

    .btn--big:hover & {
      &::before {
        transform: translate(-50%, -50%) scale(0);
      }

      &::after {
        opacity: 1;
        background-color: currentColor;
      }
      .btn__arrow{
        color: #fff;
      }
    }

    &::after {
      box-shadow: none;
      border: 2px solid currentColor;
    }
  }
}

.btn__arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 1rem;
  z-index: 1;

  @include respond-min(tablet) {
    width: 1.25rem;
    height: 0.5rem;

    .btn--fill.btn--big & {
      color: $white;
    }

    .btn.disabled & {
      color: currentColor;
    }
  }
}

.btn__line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 80%;
  height: 0.25rem;
  background-color: currentColor;

  .btn--plain:hover & {
    background-color: $white;
  }

  .btn--plain.btn--white:hover & {
    background-color: $black;
  }

  @include respond-min(tablet) {
    height: 0.125rem;
  }
}

.btn__triangle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.35rem 0 0.35rem 0.7rem;
  border-color: transparent transparent transparent currentColor;

  .btn--plain:hover & {
    border-left-color: $white;
  }

  .btn--plain.btn--white:hover & {
    border-left-color: $black;
  }

  @include respond-min(tablet) {
    border-width: 0.25rem 0 0.25rem 0.5rem;
  }
}

.btn__icon {
  position: relative;

  svg {
    transition: $trs;
  }

  .btn--icon:hover & {
    svg {
      fill: $white;
    }
  }
}

.btn__icon--black {
  position: relative;

  svg {
    transition: $trs;
  }

  .btn--icon:hover & {
    svg {
      fill: $black;
    }
  }
}


.btn__text {
  transition: $trs;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  .btn--fill &,
  .btn--fill-title & {
    color: $white;
  }

  .btn--b-fill & {
    font-size: .8rem;
    letter-spacing: 0.1em;
  }

  .btn--white & {
    color: $black;
  }

  .btn--white:hover & {
    color: $white;
  }

  .btn--white.btn--plain & {
    color: $white;
  }

  .btn--white.btn--plain:hover & {
    color: $black;
  }

  .btn--black-mobile-white & {
    color: $black;
    @include respond-min(tablet) {
      color: $white;
    }
  }

  .btn--black-mobile-white:not(.btn--nh):hover & {
    color: $white;
    @include respond-min(tablet) {
      color: $black;
    }
  }

  .btn--plain & {
    margin-left: 2rem;
    color: $black;

    @include respond-min(tablet) {
      margin-left: 1.125rem;
    }
  }

  .btn--plain:hover & {
    opacity: 0.8;
  }
}


.btn__close {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 1px;
    background-color: $black;
    transform: translateY(-50%) translateZ(0) rotate(45deg);
    transition: $trs;
    @include respond-min(tablet) {
      width: 1.1em;
    }
  }

  &::after {
    transform: translateY(-50%) translateZ(0) rotate(-45deg);
  }

  @include respond-min(tablet) {
    .btn.disabled & {
      color: currentColor;
    }
  }

}
