.trs {
  transition: $trs;

  .resized & {
    transition: none !important;
  }
}

.menu-trs {
  .resized & {
    transition: none !important;
  }

  @include respond-max(tablet) {
    right: -100%;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: $trs;
    transition-property: visibility, right, opacity;
    transition-duration: 0s, 0.4s, 0.4s;
    pointer-events: none;

    .opened & {
      right: 0;
      max-height: 100%;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}

.btn-trs {
  .resized & {
    transition: none !important;
  }

  @include respond-min(tablet) {
    opacity: 0;
    transform: scale(0);
    transform-origin: 20% 50%;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    .anim-target:hover & {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wood-shine {
  display: flex !important;
  flex-direction: row-reverse !important;
  width: 160px !important;
  align-items: center;
}
.wood-plate {
  color: white;
  font-size: 14px;
  position: absolute;
  left: 15px;
}
.wood-shine::before {
  opacity: 0.65;
  background-color: inherit;
  display: block;
  width: 180px;
  height: 84px;
  content: '';
  position: absolute;
  right: -9px;
  bottom: -10px;
  border-radius: 50px;
  -webkit-animation: woot-pulse 2s infinite ease-in-out;
  -moz-animation: woot-pulse 2s infinite ease-in-out;
  animation: woot-pulse 2s infinite ease-in-out;
  animation-delay: 0s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
  z-index: -1;
}
@-webkit-keyframes woot-pulse {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@-moz-keyframes woot-pulse {
  0% {
    -moz-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
  to {
    -moz-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes woot-pulse {
  0% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
