.calculator {
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  padding-bottom: 7.5rem;

  @include respond-min(tablet) {
    padding-top: 9.375rem;
  }
}

.calculator__title {
  margin-bottom: 5rem;

  @include respond-min(tablet) {
    margin-left: 18rem;
  }
}

.calculator__inner {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.calculator__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 7.5rem;
  padding-top: 3.75rem;
  border-top: 2px solid $black;

  @include respond-min(tablet) {
    flex-grow: 1;
    width: 31.875rem;
    margin-bottom: 0;
    margin-right: 6.125rem;
    padding-top: 1.875rem;
  }
}

.calculator__result {
  padding-top: 3.75rem;
  border-top: 2px solid $black;

  @include respond-min(tablet) {
    flex-grow: 1;
    width: 31.875rem;
    padding-top: 1.875rem;
  }
}

.calculator__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.75rem;

  .calculator__result & {
    margin-bottom: 1.75rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid $grey;

    &--result {
      border-bottom: 0;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.875rem;

    .calculator__result & {
      margin-bottom: 1.25rem;
      padding-bottom: 1.125rem;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}

.calculator__input {
  flex-shrink: 0;
  width: 50%;
  min-height: 5.5rem;
  padding-top: 1.75rem;
  padding-bottom: 1.375rem;
  padding-left: 2.5rem;
  padding-right: 5rem;
  border-radius: 2.75rem;
  border: 1px solid $grey;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  white-space: nowrap;
  -webkit-appearance: none;

  @include respond-min(tablet) {
    width: 12.5rem;
    min-height: 3.375rem;
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 3rem;
  }
}

.calculator__icon {
  position: absolute;
  top: 50%;
  right: 2.25rem;
  width: 2rem;
  height: 2rem;
  transform: translateY(-50%);
  pointer-events: none;

  @include respond-min(tablet) {
    right: 1.5rem;
    width: 1rem;
    height: 1rem;
  }
}

.calculator__range {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.25rem;
}

.calculator__range-track {
  width: 100%;
  height: auto;
  border: 0;
  background-color: transparent;
  box-shadow: none;

  .noUi-base {
    height: 1.25rem;
    cursor: pointer;

    @include respond-min(tablet) {
      height: 0.625rem;
    }
  }

  .noUi-connects {
    border-radius: 1.25rem;
    box-shadow: inset 0 0 0 0.125rem $red;
  }

  .noUi-connect {
    background-color: $red;
  }

  .noUi-handle {
    top: auto;
    bottom: 100%;
    right: -1.5rem !important; /* stylelint-disable-line declaration-no-important */
    width: 0;
    height: 0;
    border-radius: 0;
    border-style: solid;
    border-width: 2.5rem 1.5rem 0 1.5rem;
    border-color: $red transparent transparent transparent;
    background: none;
    box-shadow: none;
    cursor: grab;

    &::before,
    &::after {
      display: none;
    }

    @include respond-min(tablet) {
      right: -0.75rem !important; /* stylelint-disable-line declaration-no-important */
      border-width: 1.25rem 0.75rem 0 0.75rem;
    }
  }

  .noUi-marker {
    display: none;
  }

  .noUi-value {
    @include text('t-normal');
    position: static;
    color: $text-color;

    @include respond-min(tablet) {
      @include font('m');
      font-size: 0.9375rem;
      line-height: 1.172;
    }
  }

  .noUi-value-horizontal {
    transform: none;
  }

  .noUi-pips {
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .noUi-pips-horizontal {
    height: auto;
    margin-top: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;

    @include respond-min(tablet) {
      margin-top: 1.25rem;
    }
  }
}

.calculator__profit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.calculator__percents {
  @include text('t-normal');
  position: absolute;
  top: calc(100% + 1.25rem);
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3.75rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border-radius: 1.875rem;

  @include respond-min(tablet) {
    @include font('m');
    position: static;
    min-height: 1.875rem;
    margin-left: 1.25rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0;
  }
}

.calculator__footnote {
  max-width: 20rem;

  @include respond-min(tablet) {
    max-width: none;
  }
}
