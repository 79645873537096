.search-banner {
  min-height: 23.188rem;
  padding: 2.625rem 4rem;
  background: url("../img/search-bg.png") #2363DE;
  background-position-x: right;
  background-repeat: no-repeat;

  &__topbar {
    display: flex;
    margin-bottom: 2.5rem;

    &.only-desktop {
      flex-wrap: wrap;
    }
    &.only-mobile {
      flex-direction: column;

      & > p {
        &:after {
          display: none;
        }
      }

      .search-banner__words-group {
        display: flex;

        p {
          &:after {
            top: 50%;
            margin-top: -1.75rem;
            height: 3.5rem;
          }
        }
      }
    }

    p {
      position: relative;
      padding: 0 1.25rem 0.5rem 0;
      margin-right: 1.25rem;
      font-weight: bold;
      font-size: 2rem;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @include respond-max(tablet) {
        font-size: 3.25rem;
        line-height: 4.5rem;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &:after {
          display: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 3px;
        height: 100%;
        background: white;
      }
    }
  }

  &-field {
    position: relative;
    width: 100%;

    &__icon {
      position: absolute;
      top: 50%;
      left: 1.125rem;
      margin-top: -0.625rem;
      width: 1.25rem;
      height: 1.25rem;

      @include respond-max(tablet) {
        left: 2.5rem;
        margin-top: -1.125rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    input {
      width: 100%;
      padding: 0.938rem 0 0.938rem 3.375rem;
      border-radius: 35px;
      border: none;

      @include respond-max(tablet) {
        padding: 1.876rem 0 1.876rem 6.75rem;
      }
    }
  }
  &__footer {
    &.only-mobile {
      flex-direction: column;

      p:first-child,
      p:last-child {
        font-size: 1.75rem;
        letter-spacing: 0.02em;
        opacity: 0.7;
      }
      p:first-child {
        margin-bottom: 1rem;
      }
      p:nth-child(2) {
        margin-bottom: 1rem;
      }
      .search-banner__link {
        text-decoration-line: underline;
        font-size: 2.25rem;
        padding: 0 1rem 0 0;

        &::after {
          display: none;
        }
      }
    }

    p {
      font-size: 1rem;
      line-height: 140%;
      letter-spacing: 0.02em;
      color: #FFFFFF;

      @include respond-max(tablet) {
        font-size: 1.75rem;
      }
    }
  }
  &__link {
    position: relative;
    padding-left: 0.313rem;
    font-size: 1.563rem;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-decoration: none;
    color: #FFFFFF;

    &::after {
      content: "";
      position: absolute;
      bottom: -0.188rem;
      right: 0.375rem;
      height: 2px;
      width: 95%;
      background: #fff;
    }
  }
}