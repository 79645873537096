.infoblock-b {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 7.5rem;
  padding: 4.2rem 2.5rem;

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 5rem;
    padding: 1.5rem 3.8rem;
  }

  .page--business-zpf &{
    margin-top: -127px;

    @include respond-min(tablet) {
      margin-top: -100px;
    }
  }

  &--mg1{
    margin-top: -76px;
    margin-bottom: 0;

    @include respond-min(tablet) {
      margin-top: -66px;
      margin-bottom: 0;
    }
  }

  &--middle{
    @include respond-min(tablet) {
      padding: 1.5rem 3.8rem;

      @include respond-min(desktop) {
        margin-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop} + 80px);
        margin-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop} + 80px);
      }
    }
  }
}

.infoblock-b .flexCol {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
  }
}

.infoblock-b__image {
  position: absolute;
  right: 33px;
  top: 28px;

  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 3rem;

  @include respond-min(tablet-sm) {
    width: 5em;
    height: 5rem;
  }
  @include respond-min(tablet) {
    position: relative;
    right: 0;
    top: 0;

    flex-shrink: 0;
    width: 6.3rem;
    height: 7.3rem;
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
}

.infoblock-b__content--margin {
  @include respond-min(tablet) {
    margin-left: 3.2rem;
  }
}

.infoblock-b__title {
  width: 60%;
  margin-bottom: 2rem;

  line-height: 120%;
  letter-spacing: 0.04em;
  color: #000000;
  font-size: 3.25rem;

  @include respond-min(tablet) {
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    line-height: 100%;
    font-size: 1.9rem;
    text-align: left;
  }
}

.infoblock-b__text {
  margin-bottom: 5rem;

  line-height: 160%;
  letter-spacing: 0.04em;
  font-size: 1.5rem;
  color: rgba(#000000, .75);

  @include respond-min(tablet) {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    line-height: 170%;
    text-align: left;
    font-size: .88rem;
  }
}

.infoblock-b__subtitle {
  @include font('m');
  line-height: 120%;
  letter-spacing: 0.04em;
  font-size: 2.5rem;
  color: #000000;

  @include respond-min(tablet) {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    text-align: left;
    font-size: 1.25rem;
  }
}

.infoblock-b__btn {
  align-self: center;
  min-width: 20rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    min-width: 13.75rem;
    margin-left: auto;
  }
}

.infoblock-b__link {
  display: flex;
  text-decoration: unset;

  @include respond-min(tablet) {
    margin-top: 1.4rem;
  }
}

.infoblock-b__button-ico {
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin: auto 0 0 36px;

  @include respond-min(tablet) {
    margin: 0 0 0 16px;
  }
}
