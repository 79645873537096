.infoblock-d {
  display: flex;
  flex-direction: column;
  text-decoration: unset;

  @include respond-min(tablet-sm) {
  }
  @include respond-min(tablet) {
  }
}

.infoblock-d__image-wrapper{
  position: relative;

  width: 100%;
  padding: 15%;

  @include respond-min(tablet) {
    padding: 25%;
  }
}

.infoblock-d__image {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.infoblock-d__image img {
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infoblock-d__content {
  display: flex;
  align-items: center;
  padding: 16px 20px;

  @include font('b');
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: #000000;

  background: #ffffff;

  @include respond-min(tablet) {
    min-height: 83px;
    padding: 16px 22px;

    font-size: 20px;
  }
}
