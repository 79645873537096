.structure-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #d1d7db;
    border-radius: 4px;
    max-height: 50px;
}

.structure-file input {
    -webkit-appearance: none;
    padding: 10px 5px;
    background: #fff;
    border: none;
    width: auto;
    max-width: 100px;
    height: fill;
    min-height: 0;
    font-size: 14px;
    text-align: center;
    max-height: 50px;
    line-height: 1.15;
    margin: auto;
}

.structure-file svg {
    padding: 0 5px;
    color: #333333;
}