.slider-arrows {
  display: flex;
}

.slider-arrows__arrow {
  &--prev {
    margin-right: 0.625rem;
    transform: rotate(180deg);
  }
}
