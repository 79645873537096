.upward {
  position: fixed;
  z-index: 4;
  bottom: $offset-desktop;
  left: -6rem;
  display: block;
  width: 7rem;
  height: 7rem;
  padding: 1rem;
  opacity: 0;
  transform: translate(100%, 100%) rotate(-90deg);
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .btn__circle {
    &::after {
      background-color: $white;
    }
    .btn__arrow {
      z-index: 1;
    }
  }

  &:hover {
    .btn__circle {
      &::before {
        background-color: $black;
      }

      &::after {
        background-color: transparent;
      }
    }

    .btn__line {
      background-color: $white;
    }

    .btn__triangle {
      border-left-color: $white;
    }
  }

  @include respond-min(tablet) {
    width: 6rem;
    height: 6rem;
    padding: 1.125rem;

    .btn__circle {
      &::after {
        opacity: 1;
      }
    }
  }
}
