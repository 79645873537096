.s-stocks {
  padding: 0 0 75px 0;
  overflow-x: hidden;

  @include respond-min(tablet) {
    padding: 0 0 75px 0;
    overflow-x: initial;
  }

  &[data-mode="list"] {

    .s-stocks__nav {
      display: none;
    }

    .s-stocks__list-wrapper {
      flex-direction: column;
    }

    hr {
      display: none;
    }
  }

  &[data-mode="slider"] {
    .s-stocks__thead {
      display: none;
    }

    .s-stocks__stock .stock__info {
      &-price, &-assets, &-index {
        display: flex;
      }
    }
  }
}

.s-stocks__title {
  margin: 0 0 2rem 0;

  text-transform: unset;

  @include respond-min(tablet) {
    margin: 0 0 1rem 0;
  }
}

.s-stocks__subtitle {
  margin: 0 0 2.4rem 0;

  font-size: 1.8rem;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(#000000, .75);

  @include respond-min(tablet) {
    width: 60%;

    font-size: 1rem;
    line-height: 170%;
  }

  span {
    color: $red;
  }
}

.s-stocks__header {
  position: relative;
  display: none;
  width: 100%;
  min-height: 60px;
  margin: 0 0 2rem 0;
  align-items: flex-end;

  &-top {
    display: block;
  }
  @include respond-min(tablet) {
    display: flex;
  }
}

.s-stocks__thead {
  width: 100%;
}

.s-stocks__tr {
  display: flex;
  margin-left: auto;
  width: calc(100% - 250px);
  padding: 0 1.5rem;
}

.s-stocks__td {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 8);

  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #828D8F;
}

.s-stocks__nav {
  margin-left: auto;

  color: $green;
}

.s-stocks__switches {
  position: absolute;
  left: 0;
}

.s-stocks__nav--prev {
  transform: rotate(180deg);

  @include respond-min(tablet) {
    margin: 0 .65rem 0 0;
  }
}

.s-stocks__list {
  overflow-x: hidden;
}
.s-stocks__list-wrapper {
  margin: 0 0 6rem;
}

.s-stocks__list-wrapper {
  display: flex;
}

.s-stocks__stock {
  flex-shrink: 0;
}

.s-stocks__stock .stock__info {
  display: none;
}

.s-stocks__footer   {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;

  @include respond-min(tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
  }
  .s-stocks__subtitle {
    width: 100%;
  }
}

.s-stocks__footer-flexCol {
  margin: 30px 0 0 0;

  @include respond-min(tablet) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

.s-stocks__footer p {
  margin: 0;
}

.s-stocks__header-link {
  margin: 0 0 1.7rem 0;
}

.s-stocks__btn {
  width: max-content;
  margin: 19px 0 0 0;

  @include respond-min(tablet) {
    padding: 17px 47px;
  }
}
