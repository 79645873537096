.slider-top {
  position: relative;
  width: 100%;
  user-select: none;
}

.slider-top__slide {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 10rem;
  padding-bottom: 11.25rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  .slider-top--type-b & {
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: $offset-mobile;
    right: $offset-mobile;
    background-color: $white;
  }

  &::before {
    top: 7.5rem;
    height: 2px;

    .slider-top--type-b & {
      top: 7.5rem;

      @include respond-min(tablet) {
        top: 5.1rem;
      }
    }
  }

  &::after {
    bottom: 7.5rem;
    height: 1px;

    .slider-top--type-b & {
      display: none;
    }
  }

  @include respond-min(tablet) {
    padding-bottom: 9.5rem;
    padding-left: 18rem;
    padding-right: 7rem;

    &::before,
    &::after {
      left: 18rem;
      right: 7rem;
    }

    .slider-top--type-b &{
      padding-left: 7rem;

      &::before,
      &::after {
        left: 7rem;
        right: 20rem;
      }
    }
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + 18rem);
    padding-right: calc(50vw - #{$desktop} / 2 + 7rem);

    &::before,
    &::after {
      left: calc(50vw - #{$desktop} / 2 + 18rem);
      right: calc(50vw - #{$desktop} / 2 + 7rem);

      .slider-top--type-b & {
        left: calc(50vw - #{$desktop} / 2 + 7rem);
        right: calc(50vw - #{$desktop} / 2 + 20rem);
      }
    }

    .slider-top--type-b &{
      padding-left: calc(50vw - #{$desktop} / 2 + 7rem);
    }
  }
}

.slider-top__image {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $d-grey;

  img {
    object-fit: cover;
  }
}

.slider-top__title {
  @include font('m');
  margin-top: 0;
  margin-bottom: 2rem;

  .slider-top--type-b &{
    margin-bottom: 1.35rem;

    text-transform: unset;
    font-weight: 900;
  }

  @include respond-min(tablet) {
    font-size: 3.125rem;

    .slider-top--type-b &{
      width: 50%;
    }
  }
}

.slider-top__text {
  margin-bottom: 5.5rem;

  @include respond-min(tablet) {
    margin-bottom: 3rem;

    .slider-top--type-b &{
      width: 50%;
      margin-bottom: 3.5rem;

      p {

        font-size: 1.4rem;
      }
    }
  }
}
.slider-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    grid-gap: 20px;
  }
  .telegram_qrcode {
    width: 20%;
    img {
      width: 100%;
    }
    @include respond-max(tablet) {
      display: none;
    }
  }
}
.slider-top__btn {
  align-self: flex-start;
  margin-top: auto;
}

.slider-top__pages {
  .slider-top--type-b &{
    display: none;
  }

  @include respond-min(tablet) {
    position: absolute;
    z-index: 1;
    top: 4.75rem;
    left: 18rem;
  }

  @include respond-min(desktop) {
    left: calc(50vw - #{$desktop} / 2 + 18rem);
  }
}

.slider-top__dots {
  position: absolute;
  z-index: 1;
  bottom: 0 !important;/* stylelint-disable-line declaration-no-important */
  width: auto !important;/* stylelint-disable-line declaration-no-important */
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include respond-min(tablet) {
    margin-bottom: 2.75rem;
    padding-left: 17.5rem;

    .slider-top--type-b &{
      margin-bottom: 6.75rem;
      padding-left: 7rem;
    }
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + 17.5rem);

    .slider-top--type-b &{
      padding-left: calc(50vw - #{$desktop} / 2 + 7rem);
    }
  }
}

.slider-top__arrows {
  @include respond-min(tablet) {
    position: absolute;
    z-index: 1;
    bottom: 2.5rem;
    right: 7rem;

    .slider-top--type-b &{
      bottom: 7.8rem;
    }
  }

  @include respond-min(desktop) {
    right: calc(50vw - #{$desktop} / 2 + 7rem);
  }
}

.slider-top__breadcrumbs {
  .slider-top--type-b &{
    position: absolute;
    z-index: 1;
    top: 3.2rem;
    left: 2.5rem;

    .breadcrumbs__link, .B_currentCrumb{
      color: #ffffff;

      font-size: 1.4rem;
    }
    .breadcrumbs__link:hover, .B_currentCrumb:hover {
      color: #ffffff;
    }
    .breadcrumbs__delimiter {
      fill: #ffffff;
    }

    @include respond-min(tablet) {
      position: absolute;
      z-index: 1;
      top: 3.2rem;
      left: 7rem;

      font-size: .8rem;

      .breadcrumbs__delimiter{
        width: .43rem;
        height: .43rem;
        fill: $text-color;
      }
      .breadcrumbs__link,.B_currentCrumb{
        font-size: .75rem;
      }
      .breadcrumbs__link:hover {
        color: $text-color;
      }
    }
    @include respond-min(desktop) {
      left: calc(50vw - #{$desktop} / 2 + 7rem);
    }
  }
}
