.info-content {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-bottom: 7.5rem;

  &--b .lists {
    display: flex;
    flex-direction: column;

    @include respond-min(tablet) {
      display: flex;
      flex-direction: row;
    }
  }
  &--b hr {
    width: 100%;
    height: 1.5px;
    margin-bottom: 4.1rem;

    border: unset;
    background: rgba(#000000, .15);

    @include respond-min(tablet) {
      margin-bottom: 3.9rem;
    }
  }
  &--b h2 {
    margin: 0 0 2.4rem;

    font-size: 3.5rem;
    text-transform: unset;

    @include respond-min(tablet) {
      margin: 0 0 3rem;

      font-size: 46px;
      line-height: 120%;
      letter-spacing: 0.04em;
      color: #000000;
    }
  }
  &--b h3{
    margin-bottom: 3rem;

    font-size: 2.5rem;

    @include respond-min(tablet) {
      margin-bottom: 1.25rem;
    }
  }
  &--b h4{
    @include text('h4');
    margin-bottom: 3.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 4.8rem;

      font-size: 1.5rem;
      line-height: 170%;
    }
  }

  &--b ol {
    li {
      display: flex;
      padding-left: 0;

      @include font('r');
      font-size: 1.8rem;
      line-height: 140%;
      letter-spacing: 0.571429px;
      color: #000000;

      &::before {
        content: counter(list);
        counter-increment: list;

        position: relative;

        top: unset;
        left: unset;

        margin-top: 1px;
        margin-right: 2rem;

        @include font('b');
        font-size: 2rem;
        letter-spacing: 0.01em;
        color: #AD033B;
      }

      &:nth-of-type(n + 10) {
        &::before {
          content: counter(list) '.';
        }
      }
    }

    @include respond-min(tablet) {
      padding-left: 0;

      li {
        padding-left: 0;

        font-size: 18px;
        line-height: 170%;

        &::before {
          position: relative;
          top: -2px;
          left: unset;

          margin-right: 15px;

          font-size: 1.25rem;
          line-height: 170%;
          letter-spacing: 0.571429px;
        }
      }
    }
  }

  &--b ul {

    margin-bottom: 5.3rem;
    padding-left: 0;

    &:nth-last-child(1) {
      margin-bottom: 3.3rem;
    }

    li {
      padding-left: 0;

      color: #000000;

      &::before {
        margin-top: 0px;
      }
    }

    @include respond-min(tablet) {
      width: 70%;
      margin-bottom: 2rem;
      margin-right: 2.8rem;
      padding: 0;

      li {
        margin-top: 1.15rem;

        display: flex;
        @include font('r');
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.571429px;
        color: #000000;

        &::before {
          position: relative;
          top: unset;
          left: unset;

          margin-top: 7px;
          margin-right: 16px;
        }
      }
    }
  }
  .info-content__content {
    img {
      width: 100%;
      height: auto;
      border: 1px solid #ccc;
      margin: 1rem 0;
    }
    .icon-download-pdf,
    .icon-download-excel {
      display: inline-block;
      vertical-align: middle;
      color: $blue;
    }
    .icon-download-pdf:before {
      content: url(/assets/dist/img/icon/download-pdf.svg);
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-right: .5rem;
      vertical-align: middle;
    }
    .icon-download-excel:before {
      content: url(/assets/dist/img/icon/download-excel.svg);
      width: 2rem;
      height: 2rem;
      display: inline-block;
      margin-right: .5rem;
      vertical-align: middle;
    }
  }

  &--ratings {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  @include respond-min(tablet) {
    &--product {
      padding-top: 6.45em;
      padding-bottom: 2.75rem!important;

      .page--product & {
        padding-top: 5.125rem;
      }
    }
  }

  &--product {
    padding-bottom: 0;

    .page--product & {
      padding-top: 0;
      padding-bottom: 5rem;

      .accordion__content .content {
        padding-bottom: 0;
      }
    }

    .accordion__content .info-content {
      padding-top: 0;
      padding-bottom: 0;
    }

    .accordion__content .content {
      padding-bottom: 3rem;
    }

  }

  @include respond-min(tablet) {
    padding-bottom: 5rem;

    &--ratings {
      padding-top: 7.5rem;
      padding-bottom: 3.125rem;
    }
  }

  h1 {
    @include text('h1');
    margin-bottom: 5rem;

    @include respond-min(tablet) {
      margin-bottom: 3.75rem;
    }
  }

  h2 {
    @include text('h2');
    margin-bottom: 5rem;

    @include respond-min(tablet) {
      margin-bottom: 3.75rem;
    }
  }

  &--product h2 {
    @include respond-min(tablet) {
      margin-left: 18rem;
    }
  }

  h3 {
    @include text('h3');
    margin-bottom: 5rem;
    scroll-margin-top: 100px;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 3.125rem;
      scroll-margin-top: 150px;
    }
  }

  &.articles__blog {
    & h3 {
      font-family: 'Montserrat', sans-serif;
      font-size: 24px !important;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      margin-bottom: 1.25rem !important;
    }
  }

  &--ratings h3 {
    margin-bottom: 4rem;

    @include respond-min(tablet) {
      margin-bottom: 3.125rem;
    }
  }

  h4 {
    @include text('h4');
    margin-bottom: 3.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 1.875rem;
    }
  }

  h5 {
    @include text('t-l-caption');
  }

  p:not(.charts__legend-name):not(.dohod-banner-title):not(.dohod-banner-description):not(.dohod-banner-button_btn):not(.dohod-banner-button_text) {
    @include text('t-normal');
    margin-bottom: 2rem;
    overflow-x: hidden;

    .page--about & {
      @include text('t-big');
    }

    .page--product & {
      overflow-x: initial;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 2.8125rem;

      .page--about & {
        margin-bottom: 2.8125rem;
      }
    }
  }

  figure {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 5rem;
    }

    picture {
      display: flex;
      width: 100%;
      height: 48vw;
      max-height: 60vh;

      @include respond-min(tablet) {
        height: 35vw;
      }

      @include respond-min(desktop) {
        height: 31.25rem;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    figcaption {
      @include text('t-small');
      margin-top: 2rem;
      padding-bottom: 1.875rem;
      border-bottom: 1px solid $black;

      @include respond-min(tablet) {
        margin-top: 1.5rem;
        padding-bottom: 1.25rem;
      }
    }
  }

  table:not(.content-table) {
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 5rem;
    }
  }

  table:not(.content-table) {
    thead {
      display: flex;
      flex-direction: column;
      color: $text-color;

      tr {
        padding-top: 0;
        padding-bottom: 0.625rem;
        border-top: 0;

        &:last-of-type {
          border-bottom: 0;
        }

        @include respond-min(tablet) {
          padding-top: 0;
          padding-bottom: 0.8125rem;
        }
      }
    }

    tbody {
      display: flex;
      flex-direction: column;
    }

    tr {
      @include text('t-normal');
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 1rem;
      border-top: 1px solid $black;

      &:last-of-type {
        border-bottom: 1px solid $black;
      }

      @include respond-min(tablet) {
        padding-bottom: 1.25rem;
      }
    }

    th,
    td {
      display: flex;
      flex-direction: column;
      width: 50%;
      text-align: left;
      word-break: break-word;

      &:first-of-type {
        padding-right: 3.75rem;
      }

      &:nth-of-type(n + 2) {
        margin-left: auto;
      }

      @include respond-min(tablet) {
        &:first-of-type {
          flex-shrink: 0;
          width: 42%;
        }

        &:nth-of-type(n + 2) {
          width: 58%;
          margin-left: auto;
        }
      }
    }

    th {
      @include text('t-small');
    }

    td {
      @include text('t-normal');
      padding-top: 1.875rem;
      padding-right: 0.5rem;

      @include respond-min(tablet) {
        padding-top: 1.25rem;
        padding-right: 0.25rem;
      }

      span {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.disclosure__base-info {
    th, td {
      //border: 1px solid;
    }
  }

  table.content-table {
    margin-bottom: 7.5rem;
    width: 100%!important;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 5rem;
    }
    thead {
      color: $text-color;

      tr {
        padding-top: 0;
        padding-bottom: 0.625rem;
        border-top: 0;

        &:last-of-type {
          border-bottom: 0;
        }

        @include respond-min(tablet) {
          padding-top: 0;
          padding-bottom: 0.8125rem;
        }
      }
    }
    th,
    td {
      text-align: left;
      word-break: break-word;
      line-height: 3em;
      padding: 1rem;

      &:last-of-type {
        padding-right: 0;
      }

      &:nth-of-type(n + 2) {
        margin-left: auto;
      }

      &.t-left {
        text-align: left;
      }

      &.t-right {
        text-align: right;
      }

      &.t-center {
        text-align: center;
      }
    }

    th {
      @include text('t-small');
    }

    td {
      @include text('t-normal');
      padding-top: 1.875rem;
      padding-right: 1rem;

      @include respond-min(tablet) {
        padding-top: 1.25rem;
        padding-right: 0.25rem;
      }

      span {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &--product tr {
    flex-direction: column;
    margin-bottom: 3.75rem;
    padding-top: 3.75rem;
    border-top: 1px solid $black;

    &:last-of-type {
      margin-bottom: 0;
      border-bottom: 0;
    }

    @include respond-min(tablet) {
      flex-direction: row;
      margin-bottom: 5rem;
      padding-top: 2.5rem;

      &:first-of-type {
        border-top: 0;
      }
    }
  }

  &--product table:not(.content-table) td {
    width: 100%;
    padding-top: 0;
    padding-right: 0;

    &:first-of-type {
      padding-right: 0;
    }

    h4 {
      &:last-child {
        margin-bottom: 3.75rem;
      }
    }

    @include respond-min(tablet) {
      &:first-of-type {
        flex-shrink: 0;
        width: 18rem;
      }

      &:nth-of-type(n + 2) {
        width: calc(100% - 18rem);
      }

      h4 {
        &:last-child {
          margin-bottom: 0;
        }
      }

      ul,
      ol {
        padding-left: 2rem;
      }
    }
  }

  ul:not(.tabs__list):not(.files__list),
  ol {
    counter-reset: list;
    display: flex;
    flex-direction: column;
    margin-bottom: 7.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 3.125rem;
      padding-left: 7rem;
    }
    li {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

  }
  &.articles__blog {
    ul:not(.tabs__list):not(.files__list) {
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      li {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px !important;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-bottom: 1rem;
        @include respond-min(tablet) {
          font-size: 1.125rem !important;
        }
        &::before {
          background-color: $red;
          left: -1.5rem;
        }
      }
    }
    ol:not(.tabs__list):not(.files__list) {
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      li {
        font-family: 'Montserrat', sans-serif;
        font-size: 15px !important;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        margin-bottom: 1rem;
        @include respond-min(tablet) {
          font-size: 1.125rem !important;
        }
        &::before {
          top: 0;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px !important;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
        }
      }
    }
  }
  ul:not(.tabs__list):not(.files__list) {
    li {
      padding-left: 3.25rem;

      &::before {
        content: '';
        top: 0.7625rem;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        background-color: $black;
        color: $black;
      }
    }

    @include respond-min(tablet) {
      li {
        padding-left: 0;

        &::before {
          top: 0.5rem;
          left: -2rem;
          width: 0.5rem;
          height: 0.5rem;
        }
      }
    }
  }

  ol {
    > li {
      padding-left: 4.375rem;

      &::before {
        content: '0' counter(list) '.';
        counter-increment: list;
        top: 0.45rem;
        font-size: 1.375rem;
        line-height: 1.17;
        letter-spacing: 0.01em;
      }

      &:nth-of-type(n + 10) {
        &::before {
          content: counter(list) '.';
        }
      }

      ul li:first-child {
        margin-top: 1.5rem;
      }

    }

    @include respond-min(tablet) {
      > li {
        padding-left: 0;

        &::before {
          top: 0.125rem;
          left: -3rem;
          font-size: 1rem;
          line-height: 1.406;
        }
        ul li::before {
          left: -3rem;
          top: 0.7rem;
        }
        ul li:first-child {
          margin-top: 1.5rem;
        }
      }
    }
  }

  li {
    @include text('t-normal');
    position: relative;
    margin-bottom: 2.5rem;

    &::before {
      @include font('b');
      position: absolute;
      left: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 1.5rem;
    }
  }
}

.info-content__block {
  margin-bottom: 3.75rem;

  &:last-child {
    margin-bottom: 0;
  }

  &.no--offset  {
    margin-left: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
    margin-left: 18rem;

    .info-content--product & {
      margin-left: 0;
    }
  }
  &--reverse {
    @include respond-min(tablet) {
      margin-left: 0;
      margin-right: 18rem;

      .info-content--product & {
        margin-right: 0;
      }
    }
  }
}

.info-content__btns {
  display: flex;
  flex-direction: column;
  margin-top: 7.5rem;
  padding-top: 2.5rem;
  border-top: 2px solid $black;

  .info-content--ratings & {
    margin-top: 0;
    margin-bottom: 7.5rem;
    padding-top: 0;
    border-top: 0;
  }

  @include respond-min(tablet) {
    margin-top: 5rem;
    padding-top: 1.25rem;

    .info-content--ratings & {
      margin-bottom: 5rem;
    }
  }
}

.info-content__btn {
  align-self: flex-start;
  margin-bottom: 2.75rem;

  .info-content--ratings & {
    margin-bottom: 2.25rem;

    .btn__circle {
      align-self: flex-start;
      margin-top: -0.5rem;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  & .btn__text {
    line-height: 1.64;
  }

  @include respond-min(tablet) {
    margin-bottom: 0.5rem;

    .info-content--ratings & {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      .btn__circle {
        align-self: center;
        margin-top: 0;
      }
    }
  }
}

.info-content__btn-fill {
  min-width: 20rem;

  @include respond-min(tablet) {
    min-width: 13.75rem;
    margin-left: 18rem;
  }
}

.info-content__socials {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2.25rem;

  #socials {
    display: none;
  }

  & .btn--plain .btn__circle {
    width: 34px;
    height: 34px;
  }

  & .btn--plain {
    margin-left: 0;
  }

  .btn__circle:after {
    border: 2px solid currentColor;
  }

  @include respond-min(tablet) {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    margin-bottom: 2.25rem;

    & .btn--plain .btn__circle {
      width: 43px;
      height: 43px;
    }
    .btn__circle:after {
      border: 2px solid currentColor;
    }

  }
}

.info-content__social {
  @include respond-min(tablet) {
    margin-left: 0;
  }
}

.info-content__social-image {

  width: 1.25rem;
  height: 1.25rem;

  @include respond-min(tablet) {
    width: 1.25rem;
    height: 1.25rem;
  }

  /*&.dzen-yt-btn {
    width: 75%;
    height: 75%;
  }*/
}

.info-content__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.75rem;
  padding-top: 3.75rem;
  border-top: 1px solid $black;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-direction: row;
    margin-bottom: 5rem;
    padding-top: 2.5rem;

    &:first-of-type {
      border-top: 0;
    }

    h4 {
      flex-shrink: 0;
      width: 18rem;
    }

    ul,
    ol {
      padding-left: 2rem;
    }
  }
}

.info-content__wrapper {
  max-height: 80rem;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255, 0), $white 100%);
  }
  @include respond-min(tablet) {
    max-height: 22rem;
    &::after {
      height: 100%;
    }
  }
}

.info-content__hover-text {
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  color: rgba(0, 0, 0, 0);
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: context-menu;
  transition: 1s;
}
.info-content--product-top {
  position: relative;
  z-index: 1;
}