.switches {
  overflow: hidden;
  display: flex;
  height: 33px;

  cursor: pointer;
  border-radius: 5px;
}

.switches__switch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;

  background: #FFFFFF;

  &.is-active {
    background: $red;
  }

  &.is-active .switches__switch-svg svg {
    fill: #FFFFFF;
  }
}

.switches__switch-svg {
  width: 20px;
}

.switches__switch-svg svg{
  fill: #A6B0B2;
}
