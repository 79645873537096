.range__part{
    display: grid;
    grid-gap: 11px;
}

.range__field-wrapper{
    grid-row: 1;
    position: relative;
    @media (max-width: $tablet) {
     grid-row: 0;
    }
}
.vpo {
  .range__field-wrapper{
    @media (max-width: $tablet) {
      grid-row: auto;
    }
  }
}
.range__field-text{
    position: absolute;
    top: 11px;
    left: 18px;
    font-size: 12px;
    color: $text-color;
    &-bottom {
      top: 60px;
      display: none;
      cursor: pointer;
    }
}

.range__field{
    width: 100%;
    height: 100%;
    border: 1px solid $d-grey;
    border-radius: 35px;
    padding: 24px 18px 10px;
    transition-duration: .4s;
    &:focus{
        border: 1px solid $red;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

  &-select {
    padding: 10px;
  }
}

.range__title{
    text-align: left;
    margin-bottom: 15px;
}

.range__slider{
    margin-top: 30px;
    margin-bottom: 10px;
    z-index: 0;
}

// Noui styles
.noUi-horizontal {
    height: 1px;
    background: $d-grey;
    border: none;
    .noUi-handle {
        width: 27px;
        height: 27px;
        top: -13px;
        border-radius: 100%;
        background: $red;
        box-shadow: none;
        border: none;
        &:before, &:after{
            display: none;
        }
    }
    & * {
        will-change: transform;
    }
    .noUi-tooltip{
        transform: none;
        left: 0;
        bottom: 0;
        width: 100%;
        background: none;
        border: none;
        color: white;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
    }
}

.noUi-target{
    box-shadow: none;
}

.noUi-connect{
    background: $red;
}

