.epif-modal{
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  pointer-events: none;
  opacity: 0;

  &.is-active{
    pointer-events: all;
    opacity: 1;

    .epif-modal__overlay{
      opacity: 1;
    }
  }
}

.epif-modal__overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-duration: .5s;
  background-color: rgba(0,0,0,.5);
}

.epif-modal__inner{
  position: absolute;
  top: 0;
  left: 0;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 65px 16px 16px 16px;

  background-color: white;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.77,0,.175,1);

  @media (min-width: $tablet) {
    bottom: 0;
    right: 0;

    width: 100%;
    max-width: 1120px;
    height: max-content;
    max-height: 85vh;
    padding: 62px 67px;
  }
}

.epif-modal__close{
  position: absolute;
  top: 20px;
  right: 20px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  border-radius: 50%;
  border: 1.5px solid #000000;

  &:before, &:after{
    content: '';
    position: absolute;
    width: 70%;
    height: 1.5px;
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  &:before{
    transform: rotate(45deg);
  }
  &:after{
    transform: rotate(-45deg);
  }
}

.epif-modal__infoblock{
  width: 100%;
  margin: 0 0 25px 0;
  padding: 18px;

  @include font('m');
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #000000;

  background: #E8C9DB;

  @include respond-min(tablet) {
    margin: 0 0 40px 0;
    padding: 33px;

    font-size: 22px;
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}
.epif-modal .media-obj{
  height: max-content;
}
