.filters {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-bottom: 7.5rem;

  @include respond-min(tablet) {
    margin-bottom: 3rem;
  }

  .page--faq & {
    @include respond-min(tablet) {
      margin-bottom: 5rem;
    }
  }


}

.filters__title {
  margin-bottom: 3.75rem;

  @include respond-min(tablet) {
    margin-bottom: 2.5rem;
  }
}

.filters__inner {
  position: relative;
}

.filters__active {
  display: flex;
  flex-direction: column;

  a {
    position: relative;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    border: 2px solid rgba($d-grey, 0.3);
    border-radius: 2.75rem;
    letter-spacing: 0.08em;

    .filters__inner.active & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;
      box-shadow: 0 16px 16px rgba(17, 31, 38, 0.24);
    }
  }
}

.filters__triangle {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 2rem;
  width: 0.625rem;
  height: 1.25rem;
  transform: translateY(-50%) rotate(90deg);

  .filters__inner.active & {
    transform: translateY(-50%) rotate(270deg);
    pointer-events: none;
  }
}

.filters__list {
  /*
    box-sizing: content-box;
    Нужно, чтобы высота не учитывала границы
    при расчете значения var(--filters-list-height)
  */
  box-sizing: content-box;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  // top: 5.625rem;
  top: var(--filters-active-height);
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  max-height: 0;
  background-color: $l-grey;
  border: 2px solid rgba($d-grey, 0);
  border-radius: 2.75rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 16px 16px rgba(17, 31, 38, 0);
  transition-property: z-index, max-height, border-color, box-shadow;
  pointer-events: none;

@include respond-min(tablet) {
    box-sizing: border-box;
    position: static;
    display: grid;
    grid-gap: 1.5rem 1.875rem;
    grid-template-columns: repeat(6, 1fr);
    max-height: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    pointer-events: all;
  }

  .filters__inner.active & {
    z-index: 2;
    max-height: var(--filters-list-height);
    border-color: rgba($d-grey, 0.3);
    box-shadow: 0 16px 16px rgba(17, 31, 38, 0.24);
    pointer-events: all;

    @include respond-min(tablet) {
      max-height: none;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      pointer-events: all;
    }
  }
  .page--reports & {
    @include respond-min(tablet) {
      display: flex;
      flex-direction: column;
      grid-gap: unset;
    }
  }
}

.filters__item {
  .page--reports & {
    @include respond-min(tablet) {
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.filters__link {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 1.75rem;
  padding-left: 3.75rem;
  padding-right: 4.5rem;
  background-color: $l-grey;

  &--active {
    color: $d-grey;
    pointer-events: none;
  }

  &:not(.filters__link--active):hover {
    background-color: $d-grey;
  }

  @include respond-min(tablet) {
    justify-content: center;
    padding: 1.575rem;
    border: 1px solid rgba($d-grey, 0.3);
    border-radius: 2.25rem;
    text-align: center;
    height: 50px;
    line-height: 1rem;

    &--active {
      color: $white;
      background-color: $red;
      border-color: $red;
    }

    &:not(.filters__link--active):hover {
      background-color: $white;
      border-color: $white;
    }
  }

  /* TODO: Переделать в отдельный файл (и все подобные конструкции выше) и переименовать на страницах page-reports
       классы из filters__item в другие т.к. это совершенно другие элементы как по внешнему виду так и по функционалу */
  .page--reports & {
    @include respond-min(tablet) {
      @include font('m');
      position: relative;
      justify-content: flex-start;
      height: auto;
      padding: 0 0 1rem;
      font-weight: 500;
      font-size: .9rem;
      line-height: 1.2;
      letter-spacing: 0;
      text-transform: none;
      text-align: left;
      border: 0;
      border-radius: 0;
      background-color: transparent;

      &:hover {
        background-color: transparent;

        &::before,
        &::after {
          background-color: $red;
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        background-color: transparent;
        transition: $trs;
      }

      &::before {
        bottom: 1px;
        height: 2px;
      }

      &::after {
        bottom: 0;
        height: 1px;
        background-color: $black;
      }

      &--active {
        color: $black;
        pointer-events: all;

        &::before,
        &::after {
          background-color: $red;
        }
      }
    }
  }
}
