.accordion {

  .accordion__item {

    .page--product &:not(.active) .accordion__title:not(.main-nav__link) {
      border-bottom: 1px solid #000;
      @include respond-min(tablet) {
        border-bottom: none;
        span {
          display: block;
          //border-bottom: 1px solid #000;
        }
      }
    }

    .accordion__title {
      cursor: pointer;
      position: relative;
      padding-right: 7rem;

      @include respond-min(tablet) {
        margin-left: 16rem;
        padding-left: 2rem;
        padding-right: 0;
        border-bottom: none;
        .page--product & {
          cursor: auto;
        }
        .page--product &::before,
        .page--product &::after {
          display: none;
          left: 0.15rem;
        }
        .page--analytic-index & {
          cursor: auto;
        }
        .page--analytic-index &::before,
        .page--analytic-index &::after {
          display: none;
          left: 0.15rem;
        }

      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.25rem;
        width: 16px;
        height: 2px;
        background-color: $black;
        transform: translateY(-50%) translateZ(0);
        transition: $trs;
      }

      &::after {
        transform: translateY(-50%) translateZ(0) rotate(90deg);
      }



    }

    &.active .accordion__title {
      &::before {
        transform: translateY(-50%) translateZ(0) rotate(-180deg);
      }

      &::after {
        opacity: 0;
        transform: translateY(-50%) translateZ(0) rotate(0);
      }
    }
    .accordion__title--noml {
      margin-left: 0;
      padding-left: 0;
      @include respond-min(tablet) {
        margin-left: 0;
        padding-left: 0;
      }
    }

    .accordion__content {
      overflow: hidden;
      max-height: 0;

      @include respond-min(tablet) {
        &--visible {
          overflow: visible;
        }
      }

      .page--product & {
        @include respond-min(tablet) {
          max-height: 100%;
        }
      }
      .page--analytic-index & {
        @include respond-min(tablet) {
          max-height: 100%;
        }
      }


    }
    &.active .accordion__content {
      //max-height: var(--faq-content-max-height);
      max-height: 100%;
    }
  }
}
