.amount {
  position: relative;
  top: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-bottom: 2rem;
  border-radius: 50%;
  border: 1px solid $black;

  @include respond-min(tablet) {
    width: 30px;
    height: 30px;
    margin-bottom: 1.5rem;
  }
}

.amount__text {
  margin-left: 1px;
  font-size: inherit;
  line-height: 1;

  @include respond-min(tablet) {
    margin-left: 0;
  }
}
