.infoblock-a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 7.5rem;
  padding: 4.2rem 2.5rem;

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 5rem;
    padding: 2.5rem 3.8rem;
  }

  .page--business-zpf &{
    margin-top: -127px;

    @include respond-min(tablet) {
      margin-top: -100px;
    }
  }

  &--relative {
    position: relative;
  }

  &--mg1{
    margin-top: -76px;
    margin-bottom: 0;

    @include respond-min(tablet) {
      margin-top: -66px;
      margin-bottom: 0;
    }
  }

  &--middle{
    @include respond-min(tablet) {
      padding: 1.5rem 3.8rem;

      @include respond-min(desktop) {
        margin-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop} + 80px);
        margin-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop} + 80px);
      }
    }
  }
}

.infoblock-a__image {
  width: 13rem;
  height: 11rem;
  margin-bottom: 3rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    width: 6.51rem;
    height: 7.5rem;
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
}

.infoblock-a__title {
  text-align: left;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: rgba(#000000, 0.75);

  @include respond-min(tablet) {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    line-height: 170%;
    font-size: 1.25rem;
  }
}

.infoblock-a__btn {
  align-self: center;
  min-width: 20rem;

  @include respond-min(tablet) {
    flex-shrink: 0;
    min-width: 13.75rem;
    margin-left: auto;
  }
}
