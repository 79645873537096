.logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include respond-min(tablet) {
    justify-content: space-between;
  }
}

.logo__link {
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: auto;
  margin-left: 0;
  @include respond-min(tablet) {
    margin: 0;
  }

  svg {
    width: 14rem;
    height: 4rem;

    .logo--footer & {
      width: 27rem;
      height: 7.5rem;
    }
  }

  @include respond-min(tablet) {
    svg {

      .header--title & {
        width: 3.5rem;
        height: 2.75rem;
      }

      width: 13.5rem;
      height: 3.75rem;

      .logo--footer & {
        width: 15.625rem;
        height: 4.375rem;
      }
    }
  }
}

.logo__menu-toggle-btn {
  position: relative;
  padding: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;

  span {
    position: absolute;
    right: 0;
    width: 1em;
    height: 3px;
    background: $black;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-of-type(1) {
      top: calc(50% - 6px);
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      top: 50%;
    }

    &:nth-of-type(4) {
      top: calc(50% + 6px);
    }
  }
  .header.opened & {
    span {
      &:nth-of-type(1),
      &:nth-of-type(4) {
        top: calc(1rem + 6px);
        right: 50%;
        width: 0;
        opacity: 0;
      }

      &:nth-of-type(2) {
        transform: rotate(45deg);
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }
    }
  }

  .logo--footer & {
    display: none;
  }
}
.logo__menu-toggle-btn--full span {
  height: 2px;
  width: .75em;

  &:nth-of-type(4) {
    width: .75em;
  }
}