.side-window-feedback {

    &__btn {
        position: fixed;
        top: 33vh; 
        right: 0;
        background-color: $d-red;
        background: linear-gradient(to top,#4da0c9 0%,#ab0433 100%);
        color: $white;
        font-weight: 600;
        z-index: 3;

        @include respond-max(tablet-sm) {
            position: absolute;
            top: 65px;
            z-index: 1;
        }

        span {
            display: block;
            transform: rotate(180deg);
            writing-mode: vertical-rl;
            cursor: pointer;
            padding: 10px;

            @include respond-max(tablet-sm) {
                padding: 10px 10px 10px 12px;
            }
            &:hover {
                padding-left: 15px;
                transition: all 0.2s ease-out;
            }
        }
    }
    .hidden {
        display: none;
    }
    &__form {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        height: 100vh;
        width: 100vw;

        &--hide-area {
            background-color: rgba($color: #000000, $alpha: 0.75);
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            height: 100vh;
            width: 100vw;
            cursor: pointer;
        }
        .form__side-feedback {
            display: block;
            margin: 25px auto;
            width: 768px;
            z-index: 2;
            max-height: calc(100% - 50px);
            overflow-y: auto;
                
            @include respond-max(tablet-sm) {
                max-height: 100%;
                margin: 0;
                width: 100%;
                height: 100%;
            }
            .modal__close {
                margin: -35px 0 20px auto;
                cursor: pointer;
                
                @include respond-max(tablet-sm) {
                    margin-top: -20px;
                }
            }
            .form__content--title {
                text-align: center;
                margin-bottom: 1.875rem;
                font-weight: 600;
                font-size: 18px;
            }
            .rating-block {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 1.875rem;

                div {
                    text-align: center;

                    @include respond-max(mobile-ml) {
                        width: 20%;
                    }
                }

                img {
                    cursor: pointer;
                    width: 100%;
                    border-radius: 50%;
                    object-fit: none;

                    @include respond-max(mobile-ml) {
                        width: 90%;
                    }
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                .active {
                    transform: scale(1.2);

                    img:hover {
                        transform: unset;
                    }
                }

                .rating-1 {
                    background: #FA8072;
                }
                .rating-2 {
                    background: #FFA07A;
                }
                .rating-3 {
                    background: #F0E68C;
                }
                .rating-4 {
                    background: #9ACD32;
                }
                .rating-5 {
                    background: #32CD32;
                }
            }
            .form-label {
                margin-bottom: 5px;
                font-size: 17px;
            }
            & .input.form__file {
                display: inline-block;
                width: 100%;
                border-radius: 1.875rem;
                box-shadow: none;
                border: 2px dashed #d1d7db;
                line-height: 2.1rem;
                font-size: .860625rem;
                color: #75808f;
                text-align: center;

                @include respond-max(tablet) {
                    line-height: 4rem;
                }
                &.invalid {
                    border-color: $red;

                    @include respond-max(tablet) {
                        margin-bottom: 5rem;
                    }
                    & + .file-info {
                        margin-top: -0.313rem;
                    }
                }

                & label, & .file__del {

                    @include respond-max(tablet) {
                        font-size: 1.625rem;
                    }

                    & input.input__file {
                        display: none;
                    }
                }
                & label:hover {
                    color: #525454;
                }
                & .file-name {
                    color: #75808f;
                    font-size: .8rem;

                    @include respond-max(tablet) {
                        font-size: 1.625rem;
                    }
                }
                & .file-error {
                    color: #ad033b;
                    font-size: .85rem;

                    @include respond-max(tablet) {
                        font-size: 1.4rem;
                    }
                }
                & #progressBar {
                    display: none;
                    width: 100%;
                    max-width: 305px;
                    margin-top: 1rem;
                }
                .file__title:hover,
                .file__del:hover {
                    cursor: pointer;
                }
                .file__list {
                    padding: 0 2rem;
                }
                .file__item {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    padding: 0;
                    font-size: 1rem !important;
                    color: #777;
                }
            }
            .modal__footer {
                justify-content: flex-end;
            }
        }
        .form__success-content-wrapper {
            width: 560px;
            margin: 25px auto;
            position: relative;
                    
            @include respond-max(tablet) {
                width: 100%;
            }
            .form__success--close {
                position: absolute;
                top: 25px;
                right: 25px;
                color: $white;
                font-size: 18px;
                font-weight: 600;
                cursor: pointer;
            }
            .form__success-text {
                font-size: 20px;
            }
        }
    }
}