.flex_table {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.flex_table__row,
.flex_table__head {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 80% 20%;
    width: 100%;
  }
}

.flex_table__head {
  display: none;
  @include respond-min(tablet) {
    display: grid;
  }
}

.flex_table__row {
  &:last-child .flex_table__cell::after {
    height: 0;
  }
}

.flex_table__cell {

  .products__stats-scale {
    height: 0.5rem;
  }

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: $white;
  padding: 1rem 0;

  &--center {
    align-items: center;
  }

  &--right {
    align-items: flex-end;
  }

  .flex_table__row & {
    &:nth-child(even) {
      padding-bottom: 1.5rem;
      padding-top: 0.55rem;
      @include respond-min(tablet) {
        padding: 1.6rem 0;
      }
    }

    &:nth-child(odd) {
      padding-top: 2.25rem;
      padding-bottom: 0.55rem;
      @include respond-min(tablet) {
        padding: 1.6rem 0;
      }
    }
  }

  .flex_table__head & {
    padding: 1.4rem 0 1.25rem;
  }

  &:nth-child(2n)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $d-grey;
  }
  @include respond-min(tablet) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $d-grey;
    }
  }
}

.flex_table--2col {
  .flex_table__row .flex_table__cell {
    padding-top: 2rem;
    padding-bottom: 2rem;
    &:first-child {
      padding-right: 2rem;
    }
    justify-content: flex-start;
    //@include respond-min(tablet) {
    //  justify-content: flex-end;
    //}
  }
  .flex_table__row,
  .flex_table__head {
    display: grid;
    grid-template-columns: minmax(10rem, 40%) minmax(10rem, 60%);
    width: 100%;
    @include respond-min(tablet) {
      grid-template-columns: 40% 60%;
      width: 100%;
    }
  }
  .flex_table__cell {
    background: none;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $d-grey;
    }
    p:not(:last-child) {
      margin-bottom: 1rem;
    }
    .files__item:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.flex_table--pifs {
  .flex_table__row,
  .flex_table__head {
    display: grid;
    grid-template-columns: minmax(8rem, 1fr) minmax(10rem, 40%) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr);
    width: 100%;
    @include respond-min(tablet) {
      grid-template-columns: 4% 28% 10% 11% 11% 8% 8% 8% 8% 4%;
      width: 100%;
    }
  }

  .flex_table__head {
    .flex_table__cell {
      padding: 0.2rem 0 1.25rem;
    }
    .flex_table__item:first-child {
      margin-bottom: 0.5rem;
    }
  }

  .flex_table__row {
    .flex_table__cell {
      &:hover {
        cursor: pointer;
      }

      &:nth-child(even) {
        padding-bottom: 1.5rem;
        padding-top: 0.55rem;
        @include respond-min(tablet) {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
        }
      }

      &:nth-child(odd) {
        padding-top: 2.25rem;
        padding-bottom: 0.55rem;
        @include respond-min(tablet) {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
        }
      }

      .files {
        padding: 0;

        .files__file {
          display: flex;
          align-items: center;

          .files__file-icon {
            width: 1.3rem;
            height: 2.35rem;
          }

          .files__file-link {
            white-space: pre-wrap;
          }
        }
      }

      .question__icon {
        display: inline-flex;
        width: 1.25rem;
        height: 1.25rem;
        vertical-align: sub;
      }
      .table__btn {
        position: relative;
      }
      .car__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        svg {
          width: 80%;
          height: 80%;
          fill: $red;
          z-index: 10;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) translateZ(0);
          border-radius: 50%;
          background-color: transparent;
          transition: $trs;
          transition-duration: 0.15s;
          transition-timing-function: linear;
        }

        &::before {
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%) translateZ(0) scale(0);
        }

        &::after {
          width: 83%;
          height: 83%;
          border: 1px solid $red;
        }
        &:hover,
        &:active {
          &::before {
            background-color: $red;
            transform: translate(-50%, -50%) translateZ(0) scale(1);
          }
          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

.flex_table--bpifs {
  .flex_table__row,
  .flex_table__head {
    display: grid;
    grid-template-columns: minmax(8rem, 1fr) minmax(10rem, 40%) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr) minmax(8rem, 1fr);
    width: 100%;
    @include respond-min(tablet) {
      grid-template-columns: 0 38% 10% 11% 11% 10% 5% 5% 5% 5%;
      width: 100%;
    }
  }

  .flex_table__head {
    .flex_table__cell {
      padding: 0.2rem 0 1.25rem;
    }
    .flex_table__item:first-child {
      margin-bottom: 0.5rem;
    }
  }

  .flex_table__row {
    .flex_table__cell {
      &:nth-child(even) {
        padding-bottom: 1.5rem;
        padding-top: 0.55rem;
        @include respond-min(tablet) {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
        }
      }

      &:nth-child(odd) {
        padding-top: 2.25rem;
        padding-bottom: 0.55rem;
        @include respond-min(tablet) {
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
        }
      }

      .question__icon {
        display: inline-flex;
        width: 1.25rem;
        height: 1.25rem;
        vertical-align: sub;
      }
    }
  }
}