.article-legal-persons {
  display: flex;
  flex-grow: 1;
}

.article-legal-persons__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
}

.article-legal-persons__link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: currentColor;
}

.article-legal-persons__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.25rem;

  @include respond-min(tablet) {
    margin-bottom: 1.125rem;
  }
}

.article-legal-persons__content__text {
  min-height: auto;
  .info-content & {
    h4 {
      margin-top: 3.25rem;
      margin-bottom: 4rem;
    }
  }

  @include respond-min(tablet) {
    min-height: 285px;

    .page--blog & {
      min-height: 150px;
    }

    .info-content & {
      min-height: auto;
      h4 {
        margin-top: 0.75rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.article-legal-persons__content {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .events & {
    border-top: 1px solid #626d7d;
    border-bottom: 1px solid #626d7d;
  }

  .article-legal-persons--single & {
    padding-top: 0;
  }

  @include respond-min(tablet) {
    .article-legal-persons--single & {
      padding-top: 0.625rem;
      padding-bottom: 2.5rem;
    }
  }
}

.article-legal-persons__image {
  img {
    //max-height: 60vh;
    object-fit: cover;
    width: 100%;
  }
}

.article-legal-persons__title {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  border-bottom: 2px solid #AD033B;
  padding-bottom: 6px;

  .events & {
    font-family: 'Roboto Regular',Arial,sans-serif;
    font-weight: 500;
    font-size: rem(35, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(35, $body-size-mobile);
    }
    @include respond-max(mobile-ml) {
      font-size: rem(25, $body-size-mobile);
      word-break: break-word;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;
    letter-spacing: 0.02em;
  }
}

.article-legal-persons__wrapper {
  border: 2px solid #EDF1F2;
  border-top: 0 solid #EDF1F2;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px 32px;
}

.article-legal-persons__text {
  font-size: 14px;
  @include respond-min(tablet) {
    font-size: 16px;
  }
  line-height: 160%;
  letter-spacing: 0.04em;
  background: #FFFFFF;
  .events & {
    font-family: 'Roboto Regular',Arial,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: rem(14, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(14, $body-size-mobile);
    }
  }

  @include respond-min(tablet) {
    font-size: 0.9375rem;
    .info-content & {
      margin-bottom: 0;
      height: 2.5rem;
      overflow: hidden;
    }
  }
}

.article-legal-persons__btn {
  margin-top: 2.5rem;

  @include respond-min(tablet) {
    margin-top: 1.625rem;
  }
}

.article-legal-persons__header-col {
  display: flex;
  flex-direction: column;
  &:last-child {
    align-items: flex-end;
  }
}


.article-legal-persons__location,
.article-legal-persons__important {
  margin: 0.6rem 0 0;
}

.article-legal-persons__date {
  font-family: Roboto Bold,sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.375rem;
  line-height: 1.17;
  letter-spacing: .1em;
  text-transform: uppercase;
  @include respond-min(tablet) {
    font-size: .9rem;
  }
}

.article-legal-persons__location {
  font-size: 1.375rem;
  .events & {
    font-size: rem(14, $body-size-pc);
    @include respond-max(tablet) {
      font-size: rem(14, $body-size-mobile);
    }
  }
  @include respond-min(tablet) {
    font-size: .8rem;
  }
}

.article-legal-persons--big {
  grid-column: 1/4;
  .article-legal-persons__content-wrap {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $vd-grey;
    .events & {
      border-bottom: 0 solid $vd-grey;
    }
  }
  .article-legal-persons__header {
    justify-content: start;
    margin: 0;
    .article__important,
    .article__type {
      margin: 0;
      margin-right: 1.8rem;
      font-size: 0.6rem;
    }
  }
  .article-legal-persons__inner {
    flex-direction: row;
    @include respond-max(mobile-ml) {
      flex-wrap: wrap;
    }
  }
  .article-legal-persons__image {
    margin-top: 0;
    padding-right: 2rem;
    width: 108%;
    .events & {
      width: 40%;
      @include respond-max(mobile-ml) {
        width: 100%;
        padding-right: 0;
        padding-bottom: 2rem;
      }
    }
  }
  .article-legal-persons__btn {
    flex-direction: row;
    position: relative;
    /* display: flex; */
    width: 12rem;
    padding: 1rem 0;
  }
  .article-legal-persons__content {
    border: none;
    padding: 0;
    .events & {
      border-top: 1px solid #626d7d;
      border-bottom: 1px solid #626d7d;
      padding-bottom: 1.875rem;
    }
  }
  .article-legal-persons__text {
    padding-bottom: 0.7rem;
  }
  .article-legal-persons__date {
    text-transform: uppercase;
    padding: .8rem 0 0;
    font-weight: bold;
    font-size: .6rem;
    .events & {
      font-size: rem(14, $body-size-pc);
      @include respond-max(tablet) {
        font-size: rem(14, $body-size-mobile);
      }
    }
  }
}

.events {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background: #E5E5E5;

  .btn__arrow {
    position: relative;
    left: 8%;
    top: initial;
    transform: translate(0%,0%);
  }
}
