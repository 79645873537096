.s-advantage {
  padding: 60px 0;

  @include respond-min(tablet) {
    padding: 92px 0 108px 0;
  }

  &--margin-bottom {
    margin-bottom: 7rem;
  }
}

.s-advantage__title {
  margin-bottom: 35px;

  @include respond-min(tablet) {
    margin-bottom: 60px;
  }
}

.s-advantage__subtitle {
  @include font('b');
  line-height: 140%;
  letter-spacing: 0.571429px;
  color: #000000;
  margin-bottom: 20px;

  @include respond-min(tablet) {
    margin-bottom: 29px;

    font-size: 1.5rem;
  }
}

.s-advantage__content{
  display: flex;
  flex-direction: column;

  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.s-advantage__image{
  margin-bottom: 37px;

  @include respond-min(tablet) {
    margin-right: 49px;
  }
}

.s-advantage__flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.s-advantage ul {
  margin-bottom: 50;
  padding-left: 0;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  li {
    padding-left: 0;

    @include font('r');
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.571429px;

    &::before {
      margin-top: 8px;
    }
  }

  @include respond-min(tablet) {
    width: 100%;
    margin-bottom: 0;
    padding: 0;

    li {
      margin-top: 1.15rem;
      padding: 0;

      @include font('r');
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.571429px;

      &::before {
        top: unset;
        left: unset;

        margin-top: 7px;
      }

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.s-advantage__list {
  &--marker {
    li {
      
        margin-left: 22px;

        &::before {
          content: '';
          position: absolute;
          top: unset;
          left: unset;
          width: 10px;
          height: 10px;
          margin: 10px 14px 0 0;
          background: #ad033b;
          margin-left: -20px;
          border-radius: 50%;
        }
      }
    }
}