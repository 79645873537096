.no-overflow {
    overflow-y: hidden;
}
.service-request {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    
    &--hidden {
        display: none;
    }
    &--window {
        margin-top: 40px;

        .tabs {
            height: auto;
        }
    }
    &__close-area {
        width: 100%;
        height: 100%;
        background: rgba($color: $black, $alpha: 0.8);

        &:hover {
            cursor: pointer;
        }
    }
    &__close-btn {
        margin-left: auto;

        svg {
            width: 25px;
            height: 25px;
            cursor: pointer;
        }
    }
    &__block {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: $white;
        padding: 30px;
        max-width: 580px;
        overflow-y: auto;
        overflow-x: hidden;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #222222;
        
        @media (max-width: 1024px) {
            max-width: 100%;
        }
        &.hidden {
            display: none !important;
        }
        &--back-btn {
            background: #F4F4F4;
            padding: 15px;
            border-radius: 50%;
            border: none;
            outline: none;
            position: absolute;
            left: 30px;
        }
        &--logo {
            margin-top: 15px;

            @media (max-width: 768px) {
                margin-top: 10px;
            }
            @media (max-width: 360px) {
                margin-right: 50px;
            }
        }
        .accordion .accordion__item .accordion__title {
            padding: 15px;
            margin: 0;
            
            @media (min-width: $tablet) {
                padding: 15px;
                margin: 0;
            }
            &::before, &::after {
                right: 2rem;
            }
        }
        .service-h3 {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #1F2000;
            margin-top: 35px;
            display: flex;
            justify-content: center;

            span {
                font-weight: 700;
            }
        }
        .service-h4 {
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 140%;
            margin: 15px 75px 40px 75px;
            display: flex;

            @media (max-width: 665px) {
                margin: 40px 0;
                font-size: 16px;
            }
        }

        .radio-buttons {
            background: #F2F2F2;
            border-radius: 8px;
            font-size: 10px;
            line-height: 100%;
            text-transform: uppercase;
            margin-bottom: 25px;
                
            & > div {
                margin: 0;
                padding: 4px;
                display: flex;
                justify-content: space-between;

                .radio-button {
                    flex: 0 0 50%;
                    color: #222222;
                    font-weight: 500;

                    &:hover {
                        cursor: pointer;
                    }

                    input {
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                        margin: 35px 0 0 0;

                    }
                    input:checked+label {
                        background: $white;
                        border-radius: 4px;
                    }
                    label {
                        display: inline-flex;
                        align-items: center;
                        user-select: none;
                        
                        padding: 10px 0;
                        width: 100%;
                        justify-content: center;
                        cursor: pointer;
                    }
                }
            }
        }
        & > div:not(.btn__circle, .service-request__block--select-action) {
            padding: 0 75px;
                
            @media (max-width: 665px) {
                padding: 10px;
            }

            form {
                padding: 0;
                width: 100%;

                .service-request--accordion {
                    position: relative;
                    background: #F4F4F4;
                    border-radius: 4px;
                    margin-bottom: 20px;

                    .accordion__item.invalid .accordion__title{
                        color: $red;
                        border: 2px solid $red;
                        border-radius: 4px;
                    }
                    .accordion__header {
                        color: #5d6671;
                        padding: 15px 25px;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;

                        &::before {
                            background: url("/assets/src/img/icons/chevron-down.png") no-repeat center center;
                            position: relative;
                            top: 0;
                            right: 0;
                            display: block;
                        }
                    }
                    .purpose-select-radio {
                        border-top: 1px solid #222222;
                        margin: 0 25px;
                        padding: 10px 0;

                        .radio-button {
                            margin-top: 10px;

                            input {
                                position: absolute;
                                z-index: -1;
                                opacity: 0;
                                margin: 35px 0 0 0;
                                width: 100%;

                            }
                            input:checked+label {
                                border-radius: 4px;
                                color: $white;
                                background: $red;

                                &:hover {
                                    background-color: $l-red1;
                                    cursor: pointer;
                                }
                            }
                            label {
                                display: inline-flex;
                                align-items: center;
                                user-select: none;
                                font-size: 15px;
                                padding: 10px 5px;
                                width: calc(100% - 10px);
                                justify-content: center;
                                cursor: pointer;
                                position: relative;
                                z-index: 1;
                            }
                        }
                        .othertext {
                            p /*input*/ {
                                position: relative;
                                display: block;
                                opacity: 1;
                                z-index: 0;
                                margin: 0 auto;
                                background: $white;
                                width: calc(100% - 40px);
                                padding: 0 15px;
                                border: 0;
                                height: 0px;
                                transform: translateY(-10px);
                                transition: all .2s ease-in-out;
                                //временно на подсказку, на инпуте убрать
                                color: transparent;
                                font-size: 15px;
                            }
                        }
                        .othertext.active {
                            p /*input*/ {
                                transform: translateY(0px);
                                height: auto;
                                padding: 15px;
                                //временно на подсказку, на инпуте убрать
                                color: $black;
                            }
                        }
                        .othertext.invalid {
                            input::placeholder {
                                color: #f56c6c;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                input, select {
                    font-family: Roboto, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 100%;
                    background: transparent;
                    color: #222222;
                    width: 100%;
                    border: none;
                    padding: 12px 5px;

                    &::placeholder {
                        color: #222222;
                    }
                    &:focus, &:active, &:-webkit-autofill {
                        background: transparent;
                    }
                    &:disabled {
                        background: rgba($color: #a0a0a0, $alpha: 0.5);
                        border-radius: 8px;
                        padding-left: 15px;
                    }
                    &:disabled::placeholder {
                        color: $white;
                    }
                    
                }
                input[type="date"] {
                    
                    border-bottom: 1px solid rgba($color: $black, $alpha: 0.1);
                    margin-bottom: 22px;
                }
                input[type="date"]:before {
                    color: #222222;
                    content: attr(placeholder);
                    position: absolute;
                    background: $white;
                    font-family: Roboto, sans-serif;
                    font-size: 16px;
                    padding: 3px 5px 0 0;
                }
                input[type="date"]:disabled:before {
                    background: #cfcfcf;
                    color: $white;
                }
                label {
                    display: none;
                }
                .form__checkbox label {
                    display: block;
                }
                .comment-label {
                    font-family: Roboto, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 100%;
                    color: #222222;
                    float: left;
                    text-align: start;
                    width: 100%;
                    margin: 15px 0;
                }
                textarea {
                    width: 100%;
                    background: #F4F4F4;
                    border: none;
                    outline: none;
                    padding: 15px 25px;
                    height: 80px;
                    margin-bottom: 25px;

                }
                .btn-submit {
                    background: $red;
                    border-radius: 4px;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: center;
                    color: $white;
                    width: 100%;
                    padding: 20px 0;
                    margin: 30px 0 50px 0;
                    outline: none;
                    border: none;

                    &:disabled {
                        background: #A0A0A0;
                    }
                    &:hover:not(:disabled) {
                        background-color: $l-red1;
                        cursor: pointer;
                    }
                }
                .output-calendar {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 5px;
                    border-radius: 4px;

                    &.active {
                        margin-bottom: 25px;
                        background:  #F2F2F2;
                    }
                    span {
                        flex: 0 0 100%;
                        margin: 5px 0 10px 0;
                        font-weight: 500;
                    }
                    div {
                        flex: 0 0 20%;
                        position: relative;
                        color: #222222;
                        font-weight: 500;

                    &:hover {
                        cursor: pointer;
                    }

                    input {
                        position: absolute;
                        z-index: -1;
                        opacity: 0;
                        margin: 35px 0 0 0;
                        width: 100%;

                    }
                    input:checked+label {
                        border-radius: 4px;
                        color: $white;
                        background: $red;
                        
                        &:hover {
                            background-color: $l-red1;
                            cursor: pointer;
                        }
                    }
                    label {
                        display: inline-flex;
                        align-items: center;
                        user-select: none;
                        
                        padding: 10px 0;
                        width: 100%;
                        justify-content: center;
                        cursor: pointer;
                    }

                    }
                }
            }
        }
        &--select-action {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 580px;
            width: 100%;
            padding: 0;
            border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
            
            & > div {
                flex: 0 0 50%;
                cursor: pointer;
                text-align: center;

                &.active {
                    background-color: $red;
                    color: $white;
                }
                &:hover {
                    background-color: $grey-2;
                }
            }
            a {
                padding: 10px 20px;
                text-decoration: none;
                color: inherit;
                width: 100%;
                height: 100%;
                display: block;
                
                @include respond-min(tablet) {
                    padding: 15px 30px;
                }
            }
        }
        &--not-modal {
            position: relative;
            margin: 40px auto;

            .service-request__close-btn {
                display: none;
            }
        }
        &--tab {
            display: none;

            &.active {
                display: block;
            }
        }
        .btn-return {
            display: block;
            background: $red;
            border: none;
            border-radius: 4px;
            color: $white;
            font-size: 16px;
            line-height: 100%;
            margin: 30px auto 50px;
            outline: none;
            padding: 20px 0;
            text-align: center;
            width: calc(100% - 150px);
            text-decoration: none;

            &:hover {
                background-color: $l-red1;
                cursor: pointer;
            }
        }
    }

    .mobile-only {
        display: none;
        
        @media (max-width: 665px) {
            display: initial;
        }
    }
    .desktop-only {
        display: initial;
        
        @media (max-width: 665px) {
            display: none;
        }
    }
    &__tabs-container .service-request__block, &__success-window {
        margin-top: 0;
    }
}