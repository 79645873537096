.charts {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 6.6rem;
  padding-bottom: 8rem;

  @include respond-min(tablet) {
    padding-top: 4.6rem;
    padding-bottom: 5rem;
  }
}

.charts__block {
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.charts__block-error-loading {
    background: #0E0F110D;
    color: #0E0F11B2;
    text-align: center;
    padding: 143px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    img {
      width: 72px;
      margin: auto;
    }
  }

  .charts__block-waiting {
    background: #0E0F110D;
    color: #0E0F11B2;
    text-align: center;
    padding: 143px 0;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    img {
      width: 150px;
      margin: auto;
      display: block;
    }
  }
}

.charts__selection {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  @include respond-min(tablet) {
    flex-direction: row;
  }
}

.charts__block-label:not(:last-child) {
  margin-right: 1rem;
}

.charts__title {
  margin-bottom: 5rem;

  @include respond-min(tablet) {
    margin-left: 18rem;
  }
}

.charts__inner {
  display: flex;
  flex-direction: column;
  z-index: 1;

  @include respond-min(tablet) {
    //margin-bottom: 5rem;
    margin-bottom: 0;

    .charts__block--pie & {
      flex-direction: row;
      margin-bottom: 0;
    }
  }
}

.charts__tabs {
  margin-bottom: 3rem;
  margin-top: 5rem;
  @include respond-min(tablet) {
    margin-bottom: 3rem;
    margin-top: 5rem;
  }
}

.charts__chart {
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
  margin-bottom: 7.5rem;
  user-select: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  .charts__block--pie & {
    height: 36rem;
    @include respond-max(mobile-md) {
      margin-bottom: 0;
      margin-top: 0;
    }
    @include respond-max(tablet) {
      height: auto;
      margin-bottom: 4.5rem;
      margin-top: 4.5rem;
    }
  }

  .charts__block--stock & {
    margin-bottom: 4.5rem;
  }

  .highcharts-container,
  .highcharts-root {
    overflow: visible !important; /* stylelint-disable-line declaration-no-important */

    @include respond-max(tablet) {
      margin: 0 auto;
      max-width: 100%;
      padding-top: 15px;
    }
  }

  .highcharts-title {
    left: 50% !important; /* stylelint-disable-line declaration-no-important */
    top: 50% !important; /* stylelint-disable-line declaration-no-important */
    transform: translate(-50%, -50%);
  }

  .highcharts-point-select {
    stroke: currentColor;
    stroke-linejoin: miter;
  }

  .highcharts-yaxis-grid:not(.highcharts-navigator-yaxis) .highcharts-grid-line:last-of-type {
    stroke: $d-grey;
  }

  @include respond-min(tablet) {
    margin-bottom: 5rem;

    .charts__block--pie & {
      height: 100%;
      margin-bottom: 0;
      margin-right: 20px;
    }

    .charts__block--stock & {
      margin-bottom: 5.75rem;
    }

    //.highcharts-title {
    //  display: block;
    //  left: 50% !important; /* stylelint-disable-line declaration-no-important */
    //  top: 50% !important; /* stylelint-disable-line declaration-no-important */
    //  transform: translate(-50%, -50%);
    //}
  }
}

.charts__legend {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .charts__files {

    @include respond-min(tablet) {
      align-items: end;
    }
  }
}

.charts__legend-item {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 4.375rem;
  border-bottom: 1px solid $grey;

  &.active {
    .charts__legend-button {
      &::before {
        transform: translateY(-50%) translateZ(0) rotate(-180deg);
      }

      &::after {
        opacity: 0;
        transform: translateY(-50%) translateZ(0) rotate(0);
      }
    }
    background-color: $l-grey;
    //@include respond-min(tablet) {
    //  .charts__legend-name,
    //  .charts__legend-value {
    //    font-weight: bold;
    //  }
    //}
  }

  &:hover {
    cursor: pointer;
    background-color: $l-grey;
  }

  &.lev2 {
    padding-left: 5.375rem;
    display: none;
  }
  &.lev3 {
    padding-left: 6.375rem;
    display: none;
  }
  &.lev2.show {
    display: flex;
  }
  &.lev3.show {
    display: flex;
  }

  &:not(.lev2):not(.lev3) .chart-pin {
    content: '';
    top: 50%;
    left: 1.5em;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: currentColor;
    display: block;
    z-index: 0;
    position: relative;
    transform: none;
    left: -10px;

    @include respond-min(tablet) {
      left: -20px;
      transform: translateY(-80%);
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .charts__legend-button {
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 1.25rem;
      width: 16px;
      height: 2px;
      background-color: $black;
      transform: translateY(-50%) translateZ(0);
      transition: $trs;
    }

    &::after {
      transform: translateY(-50%) translateZ(0) rotate(90deg);
    }

  }

  .charts__legend-value {
    margin-right: 3.6rem;
  }


  @include respond-min(tablet) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2.125rem;

    &.lev1 {
      padding-left: 3.125rem;
    }
    &.lev2 {
      padding-left: 4.125rem;
    }

    &::before {
      width: 0.625rem;
      height: 0.625rem;
    }
  }
}

.charts__legend-name {
  margin-right: auto;
}

.charts__range-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 7.375rem;

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.75rem;
  }
}

.charts__range-text {
  display: flex;
  //margin-bottom: 1rem;
  margin-right: 4px;
  margin-bottom: 0;

  //@include respond-min(tablet) {
  //  margin-bottom: 0;
  //}
}

.charts__range-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include respond-min(tablet) {
    justify-content: flex-start;
    width: auto;
  }
}

.charts__range-label {
  //position: relative;
  //display: flex;
  //align-items: center;
  //width: 46.875%;
  //
  //@include respond-min(tablet) {
  //  width: auto;
  //  margin-right: 0.75rem;
  //
  //  &:last-of-type {
  //    margin-right: 0;
  //  }
  //}
}

.charts__range-input {
  width: 100%;
  min-height: 5.5rem;
  padding-top: 1.75rem;
  padding-bottom: 1.375rem;
  padding-left: 2.5rem;
  padding-right: 5rem;
  border-radius: 2.75rem;
  border: 1px solid $grey;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  white-space: nowrap;
  -webkit-appearance: none;

  @include respond-min(tablet) {
    width: 12.5rem;
    min-height: 3.375rem;
    margin-left: 1rem;
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 3rem;
  }
}

//.charts__range-icon {
//  position: absolute;
//  top: 50%;
//  right: 2.25rem;
//  width: 2rem;
//  height: 2rem;
//  transform: translateY(-50%);
//  pointer-events: none;
//
//  @include respond-min(tablet) {
//    right: 1.5rem;
//    width: 1rem;
//    height: 1rem;
//  }
//}

.charts__table {
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
  z-index: 1;

  .charts__table-track {
    margin-right: -$offset-mobile;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.25rem;
    margin-right: 0;
  }

  &--analytic-index {
    margin-bottom: 5rem;
    @include respond-min(tablet) {
      margin-bottom: 3rem;
    }
  }

}

.charts__table-cell {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background-color: $white;

  &.text-left {
    justify-content: flex-start;
    &.text-offset {
      padding-left: 2.6em;
      @include respond-min(tablet) {
        padding-left: 1.7em;
      }

    }
  }

  &:first-of-type {
    align-self: stretch;
  }

  &--head {
    padding-top: 0;
  }

  &--profit {
    color: $green;
  }

  &--loss {
    color: $red;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: $black;
  }

  @include respond-min(tablet) {
    padding-top: 1.25rem;
    padding-bottom: 1rem;

    &--head {
      padding-top: 0;
    }

  }

}

.charts__table-track {
  display: grid;
  grid-template-columns: minmax(20rem, 50%) minmax(16rem, 1fr) minmax(16rem, 1fr) minmax(16rem, 1fr);
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 34% 22% 22% 22%;
  }

  .charts__table-cell {
    background-color: $white;


    &:nth-of-type(4n + 1) {
      position: sticky;
      z-index: 1;
      left: 0;
      justify-content: flex-start;
    }



    &:not(:nth-of-type(4n + 1)) {
      white-space: nowrap;
    }

    @include respond-min(tablet) {

      &:nth-of-type(4n) {
        padding-right: 0;

        &::after {
          right: 0;
        }
      }

      //&:nth-of-type(4n) {
      //  padding-right: $offset-mobile;
      //
      //  &::after {
      //    right: $offset-mobile;
      //  }
      //}

    }

    &.text-left {
      justify-content: flex-start;
    }
    &.text-right {
      justify-content: flex-end;
    }
    &.text-center {
      justify-content: center;
    }

  }

}



.charts__table-growth {
  display: grid;
  grid-template-columns: minmax(10rem,40%) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr);
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 40% 10% 10% 10% 10% 10% 10%;
    width: 100%;
    margin-bottom: 5.25rem;
  }

  .charts__table-cell {

    background-color: $l-grey;

    &.bg-white {
      background-color: $white!important;
    }
  }

}
.charts__table-growth-5col {
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(10rem,40%) minmax(9rem,1fr) minmax(9rem,1fr) minmax(9rem,1fr) minmax(9rem,1fr);
  width: 100%;
  @include respond-min(tablet) {
    grid-template-columns: 28% 18% 18% 18% 18%;
    width: 100%;
  }
  .charts__table-cell {
    &:nth-of-type(5n + 1) {
      position: sticky;
      z-index: 9;
      left: 0;
      justify-content: flex-start;
    }

    &:nth-of-type(5n) {
      padding-right: 0;

      &::after {
        right: 0;
      }
    }

    &:not(:nth-of-type(5n + 1)) {
      white-space: nowrap;
    }

    @include respond-min(tablet) {
      &:nth-of-type(5n) {
        padding-right: 0;
      }
    }
  }

}

.charts__table-growth-6col {
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(10rem,40%) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr);
  width: 100%;
  @include respond-min(tablet) {
    grid-template-columns: 20% 16% 16% 16% 16% 16%;
    width: 100%;
  }
  .charts__table-cell {
    &:nth-of-type(6n + 1) {
      position: sticky;
      z-index: 9;
      left: 0;
      justify-content: flex-start;
    }

    &:nth-of-type(6n) {
      padding-right: 0;

      &::after {
        right: 0;
      }
    }

    &:not(:nth-of-type(6n + 1)) {
      white-space: nowrap;
    }

    @include respond-min(tablet) {
      &:nth-of-type(6n) {
        padding-right: 0;
      }
    }
  }

}
.charts__table-growth-9col {
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(10rem,40%) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr) minmax(8rem,1fr);
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 11% 10% 10% 10% 10% 10% 10% 10% 10% 9%;
    width: 100%;
  }

  .charts__table-cell {

    background-color: $l-grey;

    &.bg-white {
      background-color: $white!important;
    }

    &:nth-of-type(10n + 1) {
      position: sticky;
      z-index: 9;
      left: 0;
      justify-content: flex-start;
    }

    &:nth-of-type(6n) {
      padding-right: 0;

      &::after {
        right: 0;
      }
    }

    &:not(:nth-of-type(10n + 1)) {
      white-space: nowrap;
    }

    @include respond-min(tablet) {
      &:nth-of-type(10n) {
        padding-right: 0;
      }
    }
  }

}

.charts__table-change {
  display: grid;
  grid-template-columns: minmax(15rem,40%) minmax(15rem,1fr) minmax(15rem,1fr) minmax(8rem,1fr);
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 50% 20% 20% 10%;
    width: 100%;
    margin-bottom: 5.25rem;
  }

  .charts__table-cell {
    background-color: $l-grey;;

    &:nth-of-type(4n + 1) {
      position: sticky;
      z-index: 1;
      left: 0;
      justify-content: flex-start;
    }

    &:nth-of-type(4n) {
      padding-right: 0;

      &::after {
        right: 0;
      }
    }

    &:not(:nth-of-type(4n + 1)) {
      white-space: nowrap;
    }

    @include respond-min(tablet) {
      &:nth-of-type(4n) {
        padding-right: 0;
      }
    }
  }

}


.charts__table-deviation {
  display: grid;
  grid-template-columns: minmax(15rem,40%) minmax(15rem,1fr) minmax(15rem,1fr);
  width: 100%;

  @include respond-min(tablet) {
    grid-template-columns: 40% 30% 30%;
    width: 100%;
    margin-bottom: 5.25rem;
  }

  &_two-cols {
    grid-template-columns: minmax(15rem,1fr) minmax(15rem,1fr);

    @include respond-min(tablet) {
      grid-template-columns: 50% 50%;
      width: 100%;
      margin-bottom: 5.25rem;
    }

  }

  .charts__table-cell {
    background-color: $l-grey;
  }

}


.charts__table-value {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + .65rem);
    width: 0;
    height: 0;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 0 1rem 1.75rem 1rem;
    border-color: transparent transparent currentColor transparent;
    z-index: 1;

    .charts__table-cell--loss & {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  span {
    font-size: inherit;
  }

  @include respond-min(tablet) {
    &::before {
      right: calc(100% + 0.625rem);
      border-width: 0 0.5rem 0.875rem 0.5rem;
    }
  }
}

.charts__table-scrollbar {
  position: relative;
  display: flex;
  height: 3px;
  margin-top: 2.5rem;
  user-select: none;

  &.hidden {
    display: none;
  }

  @include respond-min(tablet) {
    display: none;
  }
}

.charts__table-thumb {
  position: absolute;
  top: -4px;
  width: 13.75rem;
  height: 11px; // добавил по 4рх сверху и снизу, чтобы было удобно перетаскивать пальцем

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $black;
  }
}
