.slider-main {
  width: 100%;

  @include respond-min(tablet) {
    &--full {
      align-self: flex-start;
      margin-left: 0;
      margin-right: 0;
    }

    &--centered {
      align-self: center;
    }
  }
}

.slider-main__slide {
  display: flex;
  flex-direction: column;
  width: 32.5rem;
  height: auto;
  transform: translateZ(0);

  @include respond-min(tablet) {
    // max-width: 450px;
  }
  .page--mobileapp & {
    width: auto;
    margin-right: 15px;
  }
}
