.bg-black {
  background-color: $black;
}

.bg-white {
  background-color: $white;
}

.bg-blackout {
  filter: brightness(50%);
}

.bg-red {
  background-color: $red;
}

.bg-l-red {
  background-color: $l-red;
}


.bg-l-pink-a {
  background-color: #E8C9DB;
}

.bg-l-gold {
  background-color: #F1E7CC;
}

.bg-l-pink-b {
  background-color: #EEDADA;
}

.bg-pink {
  background-color: $pink;
}

.bg-p-red {
  background-color: $p-red;
}

.bg-l-scarlet {
  background-color: $scarlet;
}

.bg-purple {
  background-color: $purple;
}

.bg-l-purple {
  background-color: $l-purple;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-g-yellow {
  background-color: $g-yellow;
}

.bg-l-yellow {
  background-color: $l-yellow;
}

.bg-w-green {
  background-color: $w-green;
}

.bg-g-green {
  background-color: $g-green;
}

.bg-green {
  background-color: $green;
}

.bg-d-green {
  background-color: $d-green;
}

.bg-m-green {
  background-color: $m-green;
}

.bg-l-green {
  background-color: $l-green;
}

.bg-blue {
  background-color: $blue;
}

.bg-l-blue {
  background-color: $l-blue;
}

.bg-grey {
  background-color: $grey;
}

.bg-l-grey {
  background-color: $l-grey;
}

.bg-d-grey {
  background-color: $d-grey;
}

.bg-vd-grey {
  background-color: $vd-grey;
}

.clr-black {
  color: $black;
}
.clr-black1 {
  color: $black-color1;
}

.clr-white {
  color: $white;
}

.clr-red {
  color: $red;
}

.clr-l-red {
  color: $l-red;
}

.clr-green {
  color: $green;
}

.clr-l-green {
  color: $l-green;
}
.clr-m-green {
  color: $m-green;
}

.clr-d-blue {
  color: $d-blue;
}

.clr-blue {
  color: $blue;
}

.clr-l-blue {
  color: $l-blue;
}

.clr-grey {
  color: $grey;
}

.clr-l-grey {
  color: $l-grey;
}

.clr-d-grey {
  color: $d-grey;
}

.clr-vd-grey {
  color: $vd-grey;
}

.clr-text {
  color: $text-color;
}
.clr-header-color {
  color: rgba($black-color1, 0.65);

  &:hover {
    color: $black-color1;
  }
}