.slider-pages {
  display: flex;
  align-items: center;
}

.slider-pages__page {
  @include font('b');
  font-size: 0.75rem;
  color: currentColor;

  &--current {
    color: $white;
  }
}

.slider-pages__timeline {
  width: 1.875rem;
  height: 1px;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: currentColor;
}
