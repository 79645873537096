.card {
  display: flex;
  flex-direction: column;

  .page--business & {
    text-decoration: none;
    color: #000;
  }
}

.card__title {
  margin-top: 2.5rem;
  margin-bottom: 3.75rem;

  .card--big & {
    margin-bottom: 5rem;
  }



  @include respond-min(tablet) {
    margin-top: 0;
    margin-bottom: 3.125rem;

    .card--big & {
      margin-bottom: 3.125rem;
      margin-left: 18rem;
    }
  }
}

.card__content {
  display: flex;
  flex-direction: column;

  .card--ttb & {
    position: relative;
  }
  .card--ttb1 & {
    position: relative;
  }

  @include respond-min(tablet) {
    flex-direction: row;

    .card--reverse & {
      flex-direction: row-reverse;
    }
  }
}

.card__image {
  flex-shrink: 0;

  img {
    height: 60vw;
    min-height: 27.5rem;
    max-height: 60vh;
    object-fit: cover;
  }

  .card--ttb & {
    position: absolute;
    right: 2.6rem;
    width: 25rem;
    height: 25rem;
    top: 2rem;
  }

  .card--ttb1 & {
    position: absolute;
    right: 2.6rem;
    width: 25rem;
    height: 25rem;
    top: 2rem;
  }

  @include respond-min(tablet) {
    width: 26.25rem;
    height: auto;

    img {
      height: auto;
      min-height: 26.25rem;
      max-height: none;
    }

    .card--big & {
      width: 38rem;

      img {
        min-height: 32rem;
      }
    }

    .card--tb & {
      width: 30.4rem;
    }
    .card--bt & {
      width: 30.4rem;
    }
    .card--ttb & {
      position: absolute;
      right: 5.4rem;
      width: 20rem;
      height: 20rem;
      top: .5rem;
    }
    .card--ttb1 & {
      position: absolute;
      right: 5.4rem;
      width: 20rem;
      height: 20rem;
      top: .5rem;
    }
  }
}

.card__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  .card--big & {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .card--tb & {
    padding: 4.5rem 3.1rem;
  }

  .card--bt & {
    padding: 0 2rem;
    min-height: 5.1rem;
    justify-content: flex-end;
    margin-bottom: 2.5rem;
  }

  .card--ttb & {
    padding: 15rem 2rem 6rem;
    z-index: 1;
    min-height: auto;
  }

  .card--ttb1 & {
    padding: 0 2rem;
    z-index: 1;
    min-height: auto;
  }

  @include respond-min(tablet) {
    min-height: 26.25rem;
    padding-top: 3.125rem;
    padding-bottom: 1.625rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;

    .card--big & {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .card--tb & {
      padding-top: 4.7rem;
      padding-left: 6rem;
      padding-bottom: 4.7rem;
      padding-right: 5rem;
      min-height: auto;
    }

    .card--bt & {
      padding: 6rem 2rem 1rem;
    }

    .card--ttb & {
      padding: 4rem 5rem;
      min-height: auto;
    }
    .card--ttb1 & {
      padding: 0;
    }
  }
}

.card__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-top: 2px solid $black;
  border-bottom: 1px solid $black;

  .card-info__title {
    font-size: 2.64rem;
    font-weight: 700;
    line-height: 3.5rem;
    margin-bottom: 3.1rem;
    .card--tb & {
      font-size: 2.64rem;
      font-weight: 700;
      line-height: 3.5rem;
      margin-bottom: 3.1rem;
    }
    .card--ttb &,
    .card--ttb1 & {
      font-size: 3.2rem;
      margin-bottom: 2.2rem;
      letter-spacing: .04rem;
      min-height: 11rem;
    }
    .card--ttb1 & {
      min-height: auto;
    }
    .card--bt & {
      font-size: 1.8rem;
      margin-top: 1.2rem;
      margin-bottom: 0;
      letter-spacing: 0;
      line-height: 1.1;
    }

    @include respond-min(tablet) {
      font-size: 2.04rem;
      font-weight: 700;
      line-height: 2.7rem;
      margin-bottom: 2.5rem;
      .card--tb & {
        font-size: 2.04rem;
        font-weight: 700;
        line-height: 2.7rem;
        margin-bottom: 2.5rem;
        letter-spacing: .04em;
      }
      .card--ttb & {
        font-size: 2rem;
        font-weight: 700;
        min-height: 2.5rem;
        letter-spacing: .04em;
        line-height: 1.19;
        margin-bottom: 1rem;
      }
      .card--ttb1 & {
        font-size: 1.8rem;
        font-weight: 700;
        min-height: 2.5rem;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        line-height: 1.19;
        margin-bottom: .9rem;
      }
      .card--bt & {
        font-size: 1.45rem;
        font-weight: 700;
        min-height: 4.4rem;
        letter-spacing: .04em;
        line-height: 0.99;
        margin-top: 0.6rem;
        margin-bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }

  .card-info__text {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: 1.55;
    min-height: 13rem;
    .card--ttb1 & {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      line-height: 1.55;
      min-height: auto;
    }
    @include respond-min(tablet) {
      font-size: .9rem;
      margin-bottom: 2rem;
      line-height: 1.35;
      max-width: 32rem;
      min-height: auto;
      .card--ttb1 & {
        font-size: 1rem;
        margin-bottom: 1.5rem;
        line-height: 1.25;
        letter-spacing: 0.02em;
        max-width: 85%;
        min-height: auto;
      }
      .card--ttb,
      .card--bt,
      .card--tb {
        letter-spacing: 0.02em;
      }
    }
  }

  .card-info__btn {
    flex-shrink: 0;
    min-width: 22rem;
    height: 3.6rem;

    .card--ttb & {
      min-width: 20.75rem;
      height: 3.6rem;
    }
    .card--ttb1 & {
      min-width: 20.75rem;
      height: 3.6rem;
    }

    & .btn__text {
      font-size: 1.25rem;
    }
    @include respond-min(tablet) {
      flex-shrink: 0;
      min-width: 18rem;
      height: 3.6rem;

      & .btn__text {
        font-size: 0.85rem;
      }
    }
  }


  .card--big &,
  .card--ttb &,
  .card--ttb1 &,
  .card--bt &,
  .card--tb & {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
    border-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-grow: 1;
    margin-bottom: 1.625rem;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  p {
    @include text('t-normal');
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  li {
    @include text('t-normal');
    position: relative;
    margin-bottom: 1.5rem;
    padding-left: 2.5rem;

    .card--big & {
      margin-bottom: 2.5rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.625rem;
      left: 0;
      width: 0.75rem;
      height: 0.75rem;
      background-color: $black;
      border-radius: 50%;

      .card--red & {
        background-color: $red;
      }
    }

    @include respond-min(tablet) {
      margin-bottom: 0.125rem;
      padding-left: 1.75rem;

      .card--big & {
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &::before {
        top: 0.5rem;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}

.card__caption {
  margin-bottom: 2.5rem;

  .page--personals & {
    @include font('m');
    font-size: 2.25rem;
    line-height: 1.2;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.875rem;

    .page--personals & {
      font-size: 1.375rem;
      line-height: 1.4;
      letter-spacing: 0.04em;
    }
  }
}

.card__btn {
  margin-top: auto;
}
