.index-stat {
  display: flex;
  flex-direction: column;

  @include respond-min(tablet){
    flex-direction: row;
  }
}

.index-stat__header {
  flex-shrink: 0;
  width: 100%;
  padding: 15px;

  @include font('m');
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.571429px;
  color: #000000;

  @include respond-min(tablet){
    width: 167px;
    padding: 15px 20px;
  }
}

.index-stat__content {
  width: 100%;

  border: 0.5px solid rgba(130, 141, 143, 0.4);
}

.index-stat__line {
  display: flex;
  align-items: center;
  padding: 9px 15px;

  &:nth-child(odd) {
    background: #F2F2F2;
  }

  @include respond-min(tablet){
    padding: 9px 26px;
  }
}

.index-stat__year, .index-stat__month {
  @include font('r');
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.571429px;
  color: #000000;
}

.index-stat__year {
  width: 20%;

  @include respond-min(tablet){
    width: 25%;
  }
}

.index-stat__month {
  width: 25%;

  @include respond-min(tablet){
    width: 32%;
  }
}

.index-stat__date {
  display: flex;
  flex-direction: column;
  width: 40%;

  @include respond-min(tablet){
    width: 33%;
  }
}

.index-stat__date-title {
  @include font('m');
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.571429px;
  color: #000000;
}

.index-stat__date-text {
  @include font('r');
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.571429px;
  color: #828D8F;
}

.index-stat__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: auto;

  border: 1px solid #000000;
  border-radius: 50%;
}

.index-stat__button span {
  width: 10px;
}
