.articles {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  @include respond-min(tablet) {
    margin-bottom: 7.5rem;
  }
}

.articles__inner {
  display: flex;
  flex-direction: column;
  margin-bottom: 7.5rem;

  @include respond-min(tablet) {
    display: grid;
    grid-column-gap: 3.125rem;
    grid-template-columns: repeat(3, 1fr);
    .articles__blog & {
      grid-template-columns: repeat(3,minmax(190px,1fr)) !important;
    }
    margin-bottom: 2.8125rem;
    &--even {
      grid-template-columns: repeat(2, 1fr);
    }
    &--one {
      grid-template-columns: repeat(1, 1fr);
    }
    .articles__blog & {
      margin-bottom: 0;
    }
  }
}
.info-content__block {
  .articles__blog & {
    margin-left: 0;
  }
}
.articles__article {
  padding-bottom: 0;

  .events & {
    background: $white;
  }

  .info-content & {
    padding-bottom: 2rem;
  }

  &.article_more {
    padding-bottom: 2rem !important;
  }

  @include respond-min(tablet) {
    padding-bottom: 5.25rem;
    .articles__blog & {
      padding-bottom: 2.5rem;
    }

    .events & {
      padding-bottom: 0;
    }

    & .article__content {
      max-height: 480px;
      .article_more & {
        justify-content: space-between;
      }
    }

    .info-content & {
      padding-bottom: 1.25rem;
      .article__content {
        max-height: none;
      }
    }

  }

  &--mc {
    @include respond-min(tablet) {
      & .article__content {
        max-height: max-content;
      }
    }
  }

}

.articles__btn {
  min-width: 20rem;

  &__blog {
    width: 100%;
    margin-left: 0 !important;

    &.btn--fill {
      border-radius: 10px;
      border: 1px solid rgba(41, 45, 50, 0.3);
    }
  }
  @include respond-min(tablet) {
    min-width: 13.75rem;
    margin-left: 15rem;
  }

}
