.tabs {
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: calc(2.5rem + 3px); // высота tabs__item
  &__row {
    flex-direction: row;
    justify-content: space-between;
  }
  &--btn {
    height: auto;
    margin-bottom: 1.6rem;
  }

  @include respond-min(tablet) {
    height: auto;
    &--btn {
      margin-bottom: 1rem;
    }
  }

  .page--reports > :not(.modal) &,.page--news > :not(.modal) &,.page--blog > :not(.modal) & {
    margin-bottom: 7rem;

    @include respond-min(tablet) {
      grid-area: tabs;
      margin-bottom: 3rem;
    }
  }

  .active .tabs-warning {
    position: absolute;
    top: 25px;
    display: block;
    left: 0;
    z-index: 0;
  }
}

.tabs__list {
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-shrink: 0;

  &::after {
    content: '';
    flex-shrink: 0;
    width: $offset-mobile;
  }

  @include respond-min(tablet) {
    flex-wrap: wrap;

    &::after {
      display: none;
    }
  }

}

.tabs__item {
  position: relative;
  display: flex;
  flex-shrink: 0;
  margin-bottom: 1.25rem;
  margin-right: 3.75rem;
  z-index: 2;
  @include respond-max(tablet) {
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: $grey;
    transition: $trs;
  }

  &.active,
  &:hover {
    .tabs__icon {
      svg {
        fill: $red;
      }
    }
    &::after {
      background-color: currentColor;
    }
  }

  .tabs--btn & {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .tabs--btn &::after {
    height: 0;
  }

  .tabs--btn &.active .btn  {
    color: $red;
    border: 1px solid $red;
    .btn__text {
      color: $white;
    }
  }

  .tabs--btn & .btn:hover  {
    color: $l-red1;
    border: 1px solid $l-red1;
    .btn__text {
      color: $white;
    }
  }

  .tabs--btn & .btn  {
    color: $white;
    border: 1px solid $d-grey;
    .btn__text {
      color: $black;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1rem;
    margin-right: 2.5rem;

    .tabs--btn &.active .btn,
    .tabs--btn & .btn:hover,
    .tabs--btn & .btn  {
      border-width: 2px;
    }
  }
}

.tabs__link {
  @include font('m');
  padding-bottom: calc(0.75rem + 3px); // 3px толщина линии .tabs__item
  font-size: 1.75rem;
  line-height: 1;
  cursor: pointer;

  @include respond-min(tablet) {
    padding-bottom: calc(0.625rem + 3px); // 3px толщина линии .tabs__item
    font-size: .9rem;
  }

  &--legal-persons {
    font-size: 18px !important;
    line-height: 110%;
    letter-spacing: 0.04em;
  }
  &--no-cursor {
    cursor: initial;
  }
}

.tabs__icon {
  display: flex;
  width: 2rem;
  height: 2rem;
  svg {
    fill: $d-grey;
  }
}

.tabs__block {
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  &--no-padding {
    padding: 0;
  }
  &--with-warning {
    min-height: 50px;
    position: relative;
  }
  &.active {
    display: flex;
  }

  &.active:not(.not-animations) {
    animation: fadeIn 1s forwards;
  }
  &.onload {
    display: flex;
    visibility: hidden;
  }

  @include respond-min(tablet) {
    padding-left: 0;
    padding-right: 0;
  }
}

/***********************************TABS TITLE BIG******************************************/

.tabs--tb {
  margin-bottom: 1.875rem;
}
.tabs__block--tb {
  display: none;

  &.active {
    display: block;
  }
}
.tabs__list--tb {
  display: flex;
}
.tabs__item--tb {
  margin-right: 1rem;

  a {
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-decoration: none;
    text-transform: uppercase;
    color: #BABFC7;
  }

  &.active a {
    color: $black;
  }
}

/* табы без текста с иконками - tm */
.tabs--icon {
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 0;
  position: unset;
}

/* табы title-middle - tm */
.tabs--tm {
  flex-direction: row;
  justify-content: flex-start;
  .tabs__item--tm {
    .tabs__link--tm {
      font-size: 1.1rem;
    }
    &:after {
      content: none;
      height: 0;
    }
    margin-bottom: 0;
    &.active,
    &:hover {
      .tabs__link--tm {
        color: $black;
      }
    }
  }
}

.tabs--chatrs {
  padding-bottom: 8rem;
  @include respond-min(tablet) {
    padding-bottom: 2rem;
  }
}

/***********************************TABS TITLE BIG******************************************/
.tabs--tb {
  margin-bottom: 1.875rem;
}
.tabs__block--tb {
  display: none;

  &.active {
    display: block;
  }
}
.tabs__list--tb {
  display: flex;
}
.tabs__item--tb {
  margin-right: 1rem;

  a {
    font-weight: bold;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: 0.02em;
    text-decoration: none;
    text-transform: uppercase;
    color: #BABFC7;
  }

  &.active a {
    color: $black;
  }
}

.tabs__checkbox {
  display: block;
  border: 1px solid $red;
  border-radius: 11px;
  height: 22px;
  &__link {
    display: block;
    text-align: center;
    line-height: 18px;
    padding: 1px;
    font-size: 12px;
    &::before {
      content: '₽';
      display: inline-block;
      width: 18px;
      border-radius: 50%;
    }
    &::after {
      content: '$';
      display: inline-block;
      width: 18px;
      border-radius: 50%;
    }
  }
  &.active {
    .tabs__checkbox__link {
      &::before {
        color: $vd-grey;
      }
      &::after {
        background-color: $red;
        color: $white;
      }
    }
  }
  &:not(.active) {
    .tabs__checkbox__link {
      &::before {
        background-color: $red;
        color: $white;
      }
      &::after {
        color: $vd-grey;
      }
    }
  }
}